import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody, { tableBodyClasses } from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import TicketSingle from './TicketSingle';
import ContactMailIcon from '@mui/icons-material/ContactMail';

// TABLE CSS **************************************************************************************************************
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "var(--headings-text)",
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 3,
    paddingBottom: 3,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 4,
    paddingBottom: 4,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// DIALOG TRANSITION FOR TICKET OPENING
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// TABLE TABLE FUNCTION **************************************************************************************************************
export default function TicketTable({ apiUrl, agentId, selectedDept, reSize}) {
  
  // STATE FOR OPEN & CLOSE OF DIALOG FOR SINGLE TICKET
  const [open, setOpen] = React.useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const handleClickOpen = (ticket) => {
    setSelectedTicket(ticket);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // GET JSON FROM API URL
  const [tickets, setTickets] = useState([]);
  useEffect(() => {
    const callRestApi = async () => {
      try {
        const response = await fetch(apiUrl);
        const jsonResponse = await response.json();
        setTickets(jsonResponse.data);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    callRestApi();
  }, []);

  // IF DEPT VAR EXISTS THEN FILTER BY IT
  let filteredTickets = selectedDept
    ? tickets.filter((ticket) => ticket.department === selectedDept && ticket.status === 'pending_agent')
    : tickets.filter((ticket) => ticket.agent_id === agentId && ticket.status === 'pending_agent');

  useEffect(() => {
    reSize("TT");
}, [tickets]);

  return (
    <TableContainer sx={{ borderRadius: 'var(--box-border-radius)', boxShadow: 4 }} component={Paper}>
      <Table sx={{ "& .MuiTableRow-root:hover": { backgroundColor: "var(--table-hover)" } }} aria-label="customized table">
        <TableHead>
          <TableRow sx={{backgroundColor: "var(--headings-fill)"}}>
            {/* TOP ROW COLUMNS */}
            <StyledTableCell align="left">Ticket #</StyledTableCell>
            <StyledTableCell align="left">Subject</StyledTableCell>
            <StyledTableCell align="left">Requester</StyledTableCell>
            <StyledTableCell align="left">Department</StyledTableCell>
            <StyledTableCell align="left">Workflow</StyledTableCell>
            <StyledTableCell align="left">Date</StyledTableCell>
            <StyledTableCell align="left" colSpan={2}>Assignee</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* TICKET LOOP */}
          {filteredTickets.map((ticket) => (
            <React.Fragment key={ticket.id}>
              <StyledTableRow onClick={() => handleClickOpen(ticket)}>
                {/* CLICKABLE DATA COLUMNS */}
                <StyledTableCell component="th" scope="row">{ticket.id}</StyledTableCell>
                <StyledTableCell align="left">{ticket.subject}</StyledTableCell>
                <StyledTableCell align="left">{ticket.client_first_name} {ticket.last_name}</StyledTableCell>
                <StyledTableCell align="left">{ticket.department}</StyledTableCell>
                <StyledTableCell align="left">{ticket.workflow}</StyledTableCell>
                <StyledTableCell align="left">{ticket.date_updated}</StyledTableCell>
                <StyledTableCell align="left">{ticket.agent_display_name}</StyledTableCell>
                <StyledTableCell align="left">
                  <Avatar src={process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_FRONTEND + "/" + process.env.REACT_APP_AVATARS + "/avatars/avatar" + String(ticket.agent_id) + ".png"} sx={{ width: 24, height: 24 }} />
                </StyledTableCell>
              </StyledTableRow>
            </React.Fragment>
          ))}
          {/* END TICKET LOOP */}

          {/* DIALOG BOX */}
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: 'relative', backgroundColor: 'var(--headings-fill)' }}>
            <Toolbar>
              <Typography sx={{ display: 'flex', alignItems: 'center', ml: 0, mt: 0, flex: 1, fontSize: 20 }} component="span">
                <ContactMailIcon sx={{ fontSize: 25, color: "var(--icon-action)", marginRight: '0.5rem' }} />
                 {/* {selectedTicket && selectedTicket.id}&nbsp;&nbsp;&nbsp;&nbsp;{selectedTicket && selectedTicket.subject} */}
                 &nbsp;{selectedTicket && selectedTicket.id}
              </Typography>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

            <List>
              {/* SINGLE TICKET DATA DIALOG POPUP */}
              <TicketSingle ticketId={selectedTicket && selectedTicket.id} />

              {/* <Divider /><ListItem button>Stuff here</ListItem> */}

            </List>
          </Dialog>
          {/* END DIALOG BOX */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
