import React, { useState, useEffect, useRef } from "react";
import { Autocomplete, Box, Chip, FormControl, FormControlLabel, Switch, InputLabel, Select, MenuItem, TextField, Button, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Zoom from '@mui/material/Zoom';

//Cards
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
// Icons
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

// Components
import AlertCustom from '../AlertCustom.js';

// CRUD HAPPINESS
import { getStuff } from '../../services/get';
import { putPortal } from "../../services/put";

// theme stuff ************************************************************************************************************************

// STYLE FOR PRIORITY
const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

// LABELS FOR PRIORITY
const labels = {
  1: 'Low',
  2: 'Low+',
  3: 'Medium',
  4: 'Medium+',
  5: 'High',
  6: 'High+',
  7: 'Escalate!',
};
function getLabelPriority(value) {
  return `${value}, ${labels[value]}`;
}

// grid things ************************************************************************************************************************

const GridItemWrapperChild = styled.div`
  color: var(--headings-text);
  border-radius: var(--box-border-radius);
  box-shadow: 4;
  overflow: hidden;
`;
const GridItemContentChild = styled.div`
  padding: 0px;
`;
const ResponsiveGridLayoutChild = WidthProvider(Responsive);


// DEFAULT FUNCTION ************************************************************************************************************************
export default function AdminPortal({dpts, reSize}) {

// Alerts
const [alertCustom, setAlertCustom] = useState(false);
const [alertInfo, setAlertInfo] = useState(["","info","",""]);
const handleCloseAlert = () => { setAlertCustom(false); };

// Loading save button
const [isLoading, setIsLoading] = useState(false);

// onClick save
function saveConfig() {
  setIsLoading(true);
  putPortal(selectedDepartments, unselectedDepartments, enableIssues, selectedIssues, unselectedIssues, enablePriority, portalText, onSaveSuccess, onSaveError);
}
function onSaveSuccess(what) {
  if (what == "portal") {
    setIsLoading(false);
    setAlertInfo(["saveComplete","success","Complete!", "The config has been saved"]);
    setAlertCustom(true);
  }
}
function onSaveError(error) {
  setIsLoading(false);
  setAlertInfo(["GeneralError","error","Error",error]);
  setAlertCustom(true);
}

// COMPONENT RESIZE STATES *********************************************************************************

// get size of boxes
const refSizePreview = useRef();
const refSizeCustomize = useRef();
const [dimensionsPreview, setDimensionsPreview] = useState({ width: 0, height: 0, });
const [dimensionsCustomize, setDimensionsCustomize] = useState({ width: 0, height: 0, });

// grid calcs for content
var hPreview = dimensionsPreview.height/11;
var hCustomize = dimensionsCustomize.height/11;
// 2.3 added for drop shadow display
if (hPreview < 11) { hPreview = 12;} else { hPreview = hPreview+2.3;}
if (hCustomize < 11) { hCustomize = 12;} else { hCustomize = hCustomize+2.3;}
 
const layoutChild = [
  { i: "preview", x: 0, y: 0, w: 8, minW: 2, h: hPreview, minH: 12},
  { i: "customize", x: 8, y: 0, w: 4, minW: 2, h: hCustomize, minH: 12 }
];
const layoutChildmd = [
  { i: "preview", x: 0, y: 0, w: 5, minW: 2, h: hPreview, minH: 12},
  { i: "customize", x: 5, y: 0, w: 5, minW: 2, h: hCustomize, minH: 12 }
];
const layoutChildsm = [
  { i: "preview", x: 0, y: 0, w: 6, minW: 2, h: hPreview, minH: 12},
  { i: "customize", x: 0, y: 1, w: 6, minW: 2, h: hCustomize, minH: 12 }
];

//if (dimensionsPreview.height > dimensionsTT.height) {reSize("TT");}

  // constants for form (only used for dropdown functionality preview)
  const [department, setDepartment] = useState('');
  const [account, setAccount] = useState('');
  const [issue, setIssue] = useState('');
  const [priority, setPriority] = useState(1);
  const [hover, setHover] = React.useState(-1);
  
  // enable switches states
  const [enableIssues, setEnableIssues] = useState(false);
  const [enablePriority, setEnablePriority] = useState(false);

  // all issues to choose from
  const [issueArray, setIssueArray] = useState([]);

  // new customized options for saving (filtered to chosen)
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [unselectedDepartments, setUnselectedDepartments] = useState([]);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [unselectedIssues, setUnselectedIssues] = useState([]);
  const [portalText, setPortalText] = useState(""); 

  // resize if all data loaded
  let [doneConfigs, setDoneConfigs] = useState(false);
  let [doneIssues, setDoneIssues] = useState(false);
  let [doneDepts, setDoneDepts] = useState(false);
  useEffect(() => {
    if (doneConfigs && doneIssues && doneDepts){ 
      setTimeout(function(){
        reSize("TT");
      },1000);
    }
  }, [doneConfigs, doneIssues, doneDepts]);

  function refreshConfigs() {
    getStuff("/api/admin/general")
      .then((data) => { 
        // Filter out option & value from config table, [0] is first and only in the array, then grab enabled field and convert to Boolean
        setEnableIssues(Boolean(data[0].filter((config) => config.option === 'portal' && config.value === 'issues')[0].enabled));
        setEnablePriority(Boolean(data[0].filter((config) => config.option === 'portal' && config.value === 'priority')[0].enabled));
        setPortalText(data[0].filter((config) => config.option === 'portal' && config.value === 'text')[0].extra);
        setDoneConfigs(true);
      })
      .catch((error) => { console.error("Error fetching configs:", error); });
  }

  function refreshIssues() {
    getStuff("/api/v1/issues")
      .then((data) => { 
        // set array of available issues
        setIssueArray(data[0].filter((issue) => Boolean(issue.archive) == false));
        // Filter out option & value from config table, [0] is first and only in the array, then grab enabled field and convert to Boolean
        setSelectedIssues(data[0].filter((issue) => issue.client_view === 1 && Boolean(issue.archive) == false));
        setDoneIssues(true);
      })
      .catch((error) => { console.error("Error fetching configs:", error); });
  }

  useEffect(() => {
  // get window sizes
  if (refSizePreview.current) {
    if ( refSizePreview.current.offsetWidth != dimensionsPreview.width || refSizePreview.current.offsetHeight != dimensionsPreview.height ) {
      setDimensionsPreview({ width: refSizePreview.current.offsetWidth, height: refSizePreview.current.offsetHeight, });
      reSize("TT");
    }
  }
  if (refSizeCustomize.current) {
    if ( refSizeCustomize.current.offsetWidth != dimensionsCustomize.width || refSizeCustomize.current.offsetHeight != dimensionsCustomize.height ) {
      setDimensionsCustomize({ width: refSizeCustomize.current.offsetWidth, height: refSizeCustomize.current.offsetHeight, });
      reSize("TT");
    }
  }

  }, [enableIssues, enablePriority, selectedDepartments]);

useEffect(() => {
  // get configs and issues
  refreshConfigs();
  refreshIssues();
}, []);

useEffect(() => {
  // filter selected dpt options
  setSelectedDepartments(dpts.filter((dpt) => dpt.client_view === 1));
  setDoneDepts(true);
}, [dpts]);



  // RETURN  **********************************************************************************************************************************
  
  return ( 

    <ResponsiveGridLayoutChild
    layouts={{ lg: layoutChild, md: layoutChildmd, sm: layoutChildsm }}
    autoSize={true}
    isResizable={true}
    draggableHandle={'.DragByThisP'}
    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
    cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
    rowHeight={1}
    isDraggable={true}
    onLayoutChange={() => reSize("TT")}
    onWidthChange={() => reSize("TT")}
    onDragStop={() => reSize("TT")}
    onResizeStop={() => reSize("TT")}
    onBreakpointChange={() => reSize("TT")}
    margin={[20, 10]}
    useCSSTransforms={true}
    >

    <GridItemWrapperChild key="preview" >
      <GridItemContentChild >
        {/* Portal Preview ********************************************************************************************************************* */}
        <Card ref={refSizePreview} sx={{ mb: 0, width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisP" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <VpnKeyIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                  <Typography sx={{ marginLeft: '8px' }}>Portal OpenTicket Preview</Typography>
                </div>
              }
          />
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

            {/* PREVIEW CONTENT */}
            <form>
              <Typography variant="h6">Open a New Ticket</Typography>
              
              {/* PREVIEW DEPARTMENT SELECTION */}
              <FormControl fullWidth margin="normal">
                <InputLabel>Department</InputLabel>
                <Select
                    value={department}
                    label="Department"
                    onChange={(event) => {
                      setDepartment(event.target.value);
                    }}
                    required
                    >
                    {selectedDepartments.map((dept) => (
                    <MenuItem key={dept.id} value={dept.id}>
                        {dept.name}
                    </MenuItem>
                    ))}
                </Select>
              </FormControl>

              {/* PREVIEW ACCOUNT SELECTION */}
              <FormControl fullWidth margin="normal">
                <InputLabel>Account</InputLabel>
                <Select
                    value={account}
                    label="Account"
                    onChange={(event) => {
                      setAccount(event.target.value);
                    }}
                    required>
                    <MenuItem value="Account1">Account1</MenuItem>
                    <MenuItem value="Account2">Account2</MenuItem>
                    <MenuItem value="Account3">Account3</MenuItem>
                </Select>
              </FormControl>

              {/* PREVIEW ISSUE SELECTION */}
              { enableIssues && (
                <>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Issue Type</InputLabel>
                    <Select
                      value={issue}
                      label="Issue Type"
                      onChange={(event) => {
                        setIssue(event.target.value);
                      }}
                      required>
                    {selectedIssues.map((issue) => (
                    <MenuItem key={issue.id} value={issue.id}>
                        {issue.name}
                    </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                </>
              )}
               <br />

            {/* PREVIEW SUBJECT TEXT */} 
            <TextField
                fullWidth
                label="Subject"
                margin="normal"
                rows={1}
                value=""
                required
            />

              {/* PREVIEW TEXT */}
              {portalText}

              {/* PREVIEW BODY (does nothing) */}
              <TextField
                fullWidth
                label="Message"
                margin="normal"
                multiline
                rows={4}
                value=""
                required
              />

              <p style={{ lineHeight: ".5" }}>&nbsp;</p>

              {/* PREVIEW PRIORITY SELECTION */}
              { enablePriority && (
                <>
                  <Typography component="legend">Priority</Typography>
                  <p style={{ lineHeight: ".3" }}>&nbsp;</p>
                  <Box
                    sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                    }}>
                    <StyledRating
                      name="priority"
                      defaultValue={2}
                      max={7}
                      getLabelText={getLabelPriority}
                      onChange={(event, newValue) => {
                      setPriority(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    precision={1}
                    icon={<CrisisAlertIcon fontSize="inherit" />}
                    emptyIcon={<CrisisAlertIcon fontSize="inherit" />}
                    />
                    {priority !== null && (
                      <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : priority]}</Box>
                    )}
                  </Box>
                </>
              )}
            </form>
            
            <br />

          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>

    <GridItemWrapperChild key="customize" >
      <GridItemContentChild>
      <Card ref={refSizeCustomize} sx={{ mb: 0, width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisP" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <VpnKeyIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                  <Typography sx={{ marginLeft: '8px' }}>Customize</Typography>
                </div>
              }
          />
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
            {/* CUSTOMIZE CONTENT */}
            <Typography variant="h7">The following options can be configured for client view:</Typography>
            <br /><br />

            {/* DEPARTMENT SELECTION */}  
            <FormControl fullWidth margin="normal">
                <Autocomplete
                  multiple
                  id="departments"
                  options={dpts}
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        sx={{ backgroundColor: '#ccf5ff' }}
                      />
                    ))
                  }
                  onChange={(event, value) => {
                    setSelectedDepartments(value);
                    setUnselectedDepartments(dpts.filter(dept => !value.map(dept => dept.id).includes(dept.id)));
                   }}
                  value={selectedDepartments}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Departments available to clients"
                    />
                  )}
                />
            </FormControl>

            {/* ISSUES ENABLE SWITCH */}
            <FormControlLabel control={
              <Switch 
                checked={enableIssues} 
                color="warning" 
                onChange={(event, newValue) => {
                  setEnableIssues(newValue);
                }} 
              />} 
              label="Enable Issue selection?" />

            {/* ISSUE SELECTION IF ENABLED*/}
            { enableIssues && (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  multiple
                  id="issues"
                  options={issueArray}
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        sx={{ backgroundColor: '#ffe6cc' }}
                      />
                    ))
                  }
                  onChange={(event, value) => {
                    setSelectedIssues(value);
                    setUnselectedIssues(issueArray.filter(iss => !value.map(iss => iss.id).includes(iss.id)));
                  }}
                  value={selectedIssues}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Issue tags available to clients"
                    />
                  )}
                />
              </FormControl>
            )}

            {/* PRIORITY ENABLE SWITCH */}
            <FormControlLabel control={
              <Switch 
                checked={enablePriority} 
                color="warning" 
                onChange={(event, newValue) => {
                  setEnablePriority(newValue);
                }} 
              />} 
              label="Enable Priority selection?" />

            {/* EDIT PORTAL TEXT */}
            <TextField
                fullWidth
                label="Portal Text"
                margin="normal"
                multiline
                rows={4}
                value={portalText}
                onChange={(event) => {
                  setPortalText(event.target.value);
                }}
            />
            <br /><br />

            {/* ALERT */}
            <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} TransitionComponent={Zoom} />

            {/* SAVE BUTTON */}
            <Box sx={{ textAlign: 'right' }}>
            {isLoading && (
              <LoadingButton
                loading
                sx={{
                  backgroundColor: "white",
                  color: "#051e34",
                }}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined">
                 Save
              </LoadingButton>
            )}
            {!isLoading && (
              <LoadingButton
                sx={{
                  backgroundColor: "#051e34",
                  color: "white",
                  marginLeft: 'auto',
                  "&:hover": {
                    backgroundColor: "#24478f",
                  },
                }}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                onClick={() => saveConfig()}
                variant="outlined">
                Save
              </LoadingButton>
            )}
          </Box>
          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>
      
  </ResponsiveGridLayoutChild>
   

  );

}
