import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Icons
import MailIcon from '@mui/icons-material/Mail';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../../AlertCustom.js';
// Crud Happiness
import { deleteId } from '../../../services/delete';
import { putEmails } from '../../../services/put';
// HELPERS
import { validateEmail, validateTextEmail} from '../../../helpers/validate';


// RR ROW LOOP ************************************************************************************************************************************************

// array of rr
const RRRow = ({ rrrow, onSave, handleUpdateFromComp, isLastItem}) => {
  const [open, setOpen] = useState(false);
  // Vars for RR schedules add
  const [rrAgent, setRRAgent] = useState(rrrow.human_id);
  const [rrQueue, setRRQueue] = useState(rrrow.filter_id);
  const [rrSticky, setRRSticky] = useState(Boolean(rrrow.sticky));
  const [rrStartDay, setRRStartDay] = useState(rrrow.start_day);
  const [rrStartTime, setRRStartTime] = useState(rrrow.start_time);
  const [rrLength, setRRLength] = useState(rrrow.length);
  const [rrEnable, setRREnable] = useState(Boolean(rrrow.enabled));
  

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  
  // ACTIONS ************************************************************************************************************************************************

  //  When reset of states is needed
  const reset = (switches) => {
    setRRAgent(rrrow.human_id);
    setRRQueue(rrrow.filter_id);
    setRRStartDay(rrrow.start_day);
    setRRStartTime(rrrow.start_time);
    setRRLength(rrrow.length);
    setRREnable(rrrow.enabled);
    setRRSticky(rrrow.sticky);
    handleClose();
  };

  // open and close state of dialog popup
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  
  // RR: agent changes: 'round_robin.human_id' field (rrAgent)
  const handleRRAgentChange = (event) => { setRRAgent(event.target.value); };
  // RR: queue changes: 'round_robin.filter_id' field (rrQueue)
  const handlesetRRQueueChange = (event) => { setRRQueue(event.target.value); };
  // RR: start day changes: 'round_robin.start_day' field (rrStartDay)
  const handleRRStartDayChange = (event) => { setRRStartDay(event.target.value); };
  // RR: start time changes: 'round_robin.start_time' field (rrStartTime)
  const handleRRStartTimeChange = (event) => { setRRStartTime(event.target.value); };
  // RR: length: 'round_robin.length' field (rrLength)
  const handleRRLengthChange = (event) => { setRRLength(event.target.value); };
  // RR: Toggle switch changes: 'round_robin.enabled' field, (rrEnable)
  const handleSwitchRR = (event) => { 
    setRREnable(event.target.checked); 
    if (event.target.checked === false){ setRREnable(false); }
    if (event.target.checked === true){ setRREnable(true); setRRSticky(false); }
  };
  // RR: Sticky Toggle switch changes: 'round_robin.sticky' field, (rrSticky)
  const handleSwitchSticky = (event) => { 
    setRRSticky(event.target.checked); 
    if (event.target.checked === false){ setRRSticky(false);}
    if (event.target.checked === true){ setRREnable(false); setRRSticky(true);}
  };



  // SAVE FUNCTION: SENDS TO BASE SAVE FUNCTION **********************************************************************************************************************************

  const handleSave = () => {

      onSave(rrrow.id, rrAgent, rrQueue, rrSticky, rrStartDay, rrStartTime, rrLength, rrEnable);
      handleClose();

  };


  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>
      <ListItemButton sx={{width: '100%', mt:-2, mb:-2 }}>
        <ListItem >
          <Switch checked={rrEnable} color="warning" onChange={handleSwitchRR} />
          <ListItemText sx={{width: '100%'}} onClick={handleClickOpen}
            primary={rrrow.agent_name}
            secondary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                {rrrow.id} {rrStartDay} {rrStartTime} {rrLength}
              </Typography>
            }
          />
          <Switch checked={rrSticky} color="warning" onChange={handleSwitchSticky} />
        </ListItem>
      </ListItemButton>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

      {/* DIALOG BOX */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Round_Robin Schedule</DialogTitle>
        <DialogContent>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={reset}>Cancel</Button>
          <Button onClick={() => deleteId(rrrow.id, "rr", "/api/rr", setAlertCustom, setAlertInfo, handleClose, reset, handleUpdateFromComp)}>Delete</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Divider */}
      {!isLastItem && <Divider variant="inset" />}
    </>
  );
};


// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function AgentsRR({ rrArray, handleUpdateFromComp }) {
  
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };


  // BASE SAVE FUNCTION  **********************************************************************************************************************************

  
  const handleSave = (id, value, enabled, extra, setAlertCustom, setAlertInfo, handleUpdateFromComp ) => {
    putEmails(id, value, enabled, extra, setAlertCustom, setAlertInfo);
  };


  // RETURN  **********************************************************************************************************************************
 
  return (
    <>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {/* START Agent LOOP */}
        {rrArray &&
          rrArray.map((rrrow, index) => (
          <RRRow
            key={rrrow.id} 
            rrrow={rrrow} 
            onSave={handleSave}
            handleUpdateFromComp={handleUpdateFromComp}
            isLastItem={index === rrArray.length - 1} // Check if it's the last item
          />
        ))}
        {!rrArray &&
          <Typography variant="body">
            No RR schedule yet
          </Typography>
        }
        

        {/* END Agent LOOP */}
      </List>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

    
    </>
  );
}