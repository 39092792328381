import { useState, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { Route, Routes, Navigate, useLocation, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import HomePage from "./pages/Home";
import ClientPortal from "./pages/ClientPortal";
import Login from "./pages/Login";

function App() {
  const [auth, setAuth] = useState(false);
  const [userRole, setUserRole] = useState(null); 
  const [sessionChecked, setSessionChecked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); 

  // BUILD URL FOR LOGIN (SESSION ACTIVE CHECK)
  const urllogin = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API + "/api/v1/session";
  
  useEffect(() => {
    const checkSession = async () => {

      // Check if AGENT cookies exist
      const agentEmailCookie = Cookies.get("agent_email");
      const agentSessionCookie = Cookies.get("agent_session");

      // Check if CLIENT cookies exist
      const rikrEmailCookie = Cookies.get("rikr_email");
      const rikrSessionCookie = Cookies.get("rikr_session");

   
      // COOKIES FOUND SO LETS CHECK FOR A SESSION
      if ((agentEmailCookie && agentSessionCookie) || (rikrEmailCookie && rikrSessionCookie)) {
        if (agentEmailCookie) { 
          var sendThisEmail = agentEmailCookie;
          var sendThisSession = agentSessionCookie;
        } else if (rikrEmailCookie) {
          var sendThisEmail = rikrEmailCookie;
          var sendThisSession = rikrSessionCookie;
        }
        
        try {
          const response = await fetch(urllogin, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "agentEmailCookie": sendThisEmail,
              "agentSessionCookie": sendThisSession,
            }),
          });

          if (response.ok) {
            // If session is valid, set auth to true
            const responseData = await response.json();
            const sessCheck = responseData.sessCheck;
            const role = responseData.role;

           
            if (sessCheck === true) { 
              setAuth(true);
              setUserRole(role);
            } else { 
              setAuth(false);
              navigate("/login", { replace: true });
            }
            
          } else {
            // Session is invalid, navigate to login page
            navigate("/login", { replace: true });
          }
        } catch (error) {
          console.error("Error during API request:", error);
        }
      } else {
        // Session cookies not found, navigate to login page
        navigate("/login", { replace: true });
      }

     // Set the session check completion state to true
     setSessionChecked(true);
  };

    checkSession();
  }, [auth]);


  // Render the Routes component only when the session check is completed
  return (
    <>
      <CssBaseline />
      {sessionChecked && (
        <Routes>
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          <Route
            path="/"
            element={
              auth ? (
                [1, 2, 3, 4, 5].includes(userRole) ? <HomePage setAuth={setAuth} /> : <ClientPortal setAuth={setAuth} />
              ) : (
                <Navigate to="/login" state={{ from: location }} replace />
              )
            }
          />
        </Routes>
      )}
    </>
  );
}

export default App;