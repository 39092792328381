import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody, { tableBodyClasses } from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import IconButton from '@mui/material/IconButton';
//Cards
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Icons
import KeyIcon from '@mui/icons-material/Key';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
// Alert Component
import AlertCustom from '../AlertCustom.js';
// CRUD HAPPINESS
import { getStuff } from '../../services/get';
import { postAccountAuth } from '../../services/post';
import { removeAuth } from '../../services/delete';
import { validateEmail } from '../../helpers/validate';

// TABLE CSS **************************************************************************************************************
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "var(--headings-text)",
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 3,
    paddingBottom: 3,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 4,
    paddingBottom: 4,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


// ACCOUNTS OWNED & AUTHED LOOP ***********************************************************************************************************************
const ActRow = ({ clientId, actrow, authType, isLastItem}) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [authed, setAuthed] = useState([]);
  const [decision, setDecision] = useState(null);
  const [newAuthEmail, setNewAuthEmail] = useState(null);
  const [currentAction, setCurrentAction] = useState({ account_id: null, human_id: null, human_info: null });

  const getAccountDetails = () => {
    if (authType === "owned") {
      getStuff("/api/v1/accounts/" + actrow.id + "/authed")
        .then((data) => {
          if (data[0]) {setAuthed(data); }
        })
        .catch((error) => {
          console.error("Error fetching acts:", error);
        });
    }
  }

  const onSaveSuccess = (what) => {
    if (what === "authed") {
      setAlertInfo(["saveComplete", "success", "Success!", "Access Removed!"]);
    }
    if (what === "newAuth") {
      setAlertInfo(["saveComplete", "success", "Success!", "Access Saved!"]);
    }
    setAlertCustom(true);
    getAccountDetails(); //refresh data
  }
  const onSaveError = (error) => {
    setAlertInfo(["Error removing authorization", "Error", error, ""]);
    setAlertCustom(true);
  };

  useEffect(() => {
    getAccountDetails();
  }, [actrow, authType]);

  useEffect(() => {
    if (decision) {
      removeAuth(currentAction.account_id, currentAction.human_id, onSaveSuccess, onSaveError);
    }
  
    // Reset the decision to null after
    if (decision !== null) {
      setDecision(null);
    }
  }, [decision, currentAction]);
  

  const handleDelete = (account_id, human_id, human_info) => {
      setCurrentAction({ account_id, human_id, human_info });
      setOpenDel(true);
  };

  function addNewAuth() {
    postAccountAuth(actrow.id, newAuthEmail, clientId, onSaveSuccess, onSaveError);
  }
    

    // Alerts
    const [alertCustom, setAlertCustom] = useState(false);
    const [alertInfo, setAlertInfo] = useState(["","info","",""]);
    const handleCloseAlert = () => { setAlertCustom(false); };

  // RETURN IN LOOP  **********************************************************************************************************************************
             
  return (
    <>
     {authType === "owned" ? (
       <>
        <ListItem component={'span'} sx={{ mt: -1, mb: -1 }}>
            <ListItemAvatar component={'span'}>      
              <Avatar component={'span'} sx={{ bgcolor: "var(--success)", width: 32, height: 32 }}>
                <KeyIcon sx={{ width: 30, height: 30 }} />
              </Avatar>
            </ListItemAvatar>
            {authed.length !== 0 ? (
              <ListItemText component={'span'}
                  primary={
                    <>
                      <strong>{actrow.name}</strong>&nbsp;
                      <Tooltip title={`Add Authorized user to ${actrow.name}`} placement="right">
                        <Button onClick={() => setOpenAdd(true)}
                          sx={{
                          color: '#fff',
                          backgroundColor: 'darkorange',
                          minWidth: '10px',
                          lineHeight: '1',
                          '&:hover': {
                            backgroundColor: '#DD9400', 
                          },
                          padding: '2px 3px', 
                          fontSize: '12px'
                          }} variant="contained">
                            Add
                        </Button>
                      </Tooltip>

                    </>
                }
                  secondary={
                      <>
                      {authed[0].map((authedHuman, indexa) => (
                        <span key={authedHuman.human_id} style={{ display: 'flex', alignItems: 'center', marginTop: -1, marginBottom: -1 }}>
                          <Avatar component={'span'}
                            src={`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_FRONTEND}/${process.env.REACT_APP_AVATARS}/avatars/avatar${authedHuman.human_id}.png`} 
                            style={{ width: 25, height: 25, marginRight: 8 }} 
                          />
                          {`${authedHuman.first_name} ${authedHuman.last_name} : ${authedHuman.email}`}
                          <Button onClick={() => setOpenInfo(true)}>
                          <Tooltip title="Info" placement="top">
                            <InfoIcon color="normal" sx={{ width: 25, height: 25 }} />
                          </Tooltip>  
                          <Tooltip title="Remove" placement="top">
                            <DeleteForeverIcon color="error" sx={{ width: 25, height: 25 }} />
                          </Tooltip>
                          </Button>
                        </span>
                      ))}
                      </>
                  }
              />
            ) : (
              <ListItemText component={'span'}
                  primary={actrow.name}
              />
            )}
        </ListItem>

        {/* DIALOG INFO */}
        <Dialog open={openInfo} onClose={()=>setOpenInfo(false)} TransitionComponent={Zoom} sx={{ '& .MuiDialog-paper': { minWidth: 400 } }}>
            <DialogTitle>{actrow.name} info</DialogTitle>
            <IconButton aria-label="close" onClick={()=>setOpenInfo(false)} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500],}}>
                <CloseIcon />
            </IconButton>
            <DialogContent component={'span'}>
 
info
             
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpenInfo(false)} onMouseDown={e => e.stopPropagation()}>Close</Button>
            </DialogActions>
        </Dialog>

        {/* DIALOG DEL */}
        <Dialog open={openDel} onClose={()=>setOpenDel(false)} TransitionComponent={Zoom} sx={{ '& .MuiDialog-paper': { minWidth: 300 } }}>
            <DialogTitle>Confirm Access Removal</DialogTitle>
            <IconButton aria-label="close" onClick={()=>setOpenDel(false)} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500],}}>
                <CloseIcon />
            </IconButton>
            <DialogContent component={'span'}>
 
                Please confirm that you wish to remove access to the account, {actrow.name} by the user {currentAction.human_info}
           
             
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setOpenDel(false); setDecision(false);}} onMouseDown={e => e.stopPropagation()}>Cancel</Button>
                <Button onClick={( )=> {setOpenDel(false); setDecision(true);}} onMouseDown={e => e.stopPropagation()} autoFocus>Confirm</Button>
            </DialogActions>
        </Dialog>

        {/* DIALOG ADD */}
        <Dialog open={openAdd} onClose={() => setOpenAdd(false)} TransitionComponent={Zoom} sx={{ '& .MuiDialog-paper': { minWidth: 400 } }}>
          <DialogTitle>Add User Access</DialogTitle>
          <DialogContent>

              <TextField
                name="newAuthEmail"
                onChange={(event) => {
                  setNewAuthEmail(event.target.value);
                }}
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                autoFocus
              />


            Note: If you add a user that does not currently have an account, they will be sent an invite.

          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAdd(false)}>Cancel</Button>
            <Button onClick={() => {setOpenAdd(false); addNewAuth(actrow.id); }}>Add</Button>
          </DialogActions>
        </Dialog>

       </>
 
    ) : (
        <ListItem sx={{ display: 'flex', alignItems: 'center', mt: -1, mb: -1 }}>
          <ListItemAvatar>      
              <Avatar sx={{ bgcolor: "var(--icon-avatar)", width: 28, height: 28 }}>
                <KeyIcon sx={{ width: 20, height: 20 }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText component={'span'}
                  primary={actrow.name}
          />
        </ListItem>
    )}

    {/* Divider */}
    {!isLastItem && (
      <>
        <Divider variant="inset" />
      </>
    )}

    {/* ALERT */}
    <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

    </>
  );

};

// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function Authorization({
    clientId,
    clientActsOwned,
    clientActsAuthed,
    deptOptions,
    issueOptions,
    }) {

    const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;

    // Alerts
    const [alertCustom, setAlertCustom] = useState(false);
    const [alertInfo, setAlertInfo] = useState(["","info","",""]);
    const handleCloseAlert = () => { setAlertCustom(false); };


    // RETURN  **********************************************************************************************************************************
 
    return (
      <>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}>

          {/* ACCOUNTS OWNED */}
          <Card sx={{ mb: 0, width: '58%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
            <CardHeader sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <KeyIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                        <Box sx={{ marginLeft: '8px' }}>Accounts owned</Box>
                    </div>
                }
            />
            {clientActsOwned.length !== 0 ? (
                <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
                    {/* START LOOP */}
                    {clientActsOwned.map((actrow, index) => (
                      <ActRow
                        key={actrow.id}
                        clientId={clientId}
                        actrow={actrow} 
                        authType="owned"
                        isLastItem={index === clientActsOwned.length - 1}
                      />
                    ))}
                    {/* END LOOP */}
                </CardContent>
              ) : (
                <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
                  No accounts owned
                </CardContent>
              )}
                     
          </Card>
          <br />

          {/* ACCOUNTS AUTHED FOR */}
          <Card sx={{ ml: 3, width: '38%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
            <CardHeader sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <KeyIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                        <Box sx={{ marginLeft: '8px' }}>Accounts with authorization</Box>
                    </div>
                }
            />
            {clientActsAuthed.length !== 0 ? (
                <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
                  {/* START LOOP */}
                  {clientActsAuthed.map((actrow, index) => (
                    <ActRow
                      key={actrow.id}
                      clientId={clientId}
                      actrow={actrow} 
                      authType="authed"
                      isLastItem={index === clientActsAuthed.length - 1}
                    />
                  ))}
                  {/* END LOOP */}
                </CardContent>
              ) : (
                <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
                  No other Accounts authed
                </CardContent>
              )}
                     
          </Card>
        
        </div>

        {/* ALERT */}
        <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />
      </>
    );
    
}
