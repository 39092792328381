import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
// icons
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';


//For CC email clicks
const cchandleClick = () => {
  console.info('You clicked a CC email');
};

//For CC email delete
const cchandleDelete = () => {
  console.info('You clicked the delete icon on CC.');
};

//For CC ADD link linked
const ccaddhandleClick = () => {
  console.info('You clicked the ADD button for CC.');
};

//For Label delete
const lhandleDelete = () => {
  console.info('You clicked the delete icon on a label.');
};

//For Label add
const lhandleAdd = () => {
  console.info('You clicked the add icon for a label.');
};

//Handle bookmark clicked
const bhandleClick = () => {
  console.info('You clicked the bookmark button.');
};

//Handle Subject edit clicked
const subhandleClick = () => {
  console.info('You clicked the subject edit button.');
};


// DEFAULT FUNCTION **********************************************************************************************
export default function TicketSingleCardLeft(ticketinfo) {
  //console.log(ticketinfo);
  const ticket = ticketinfo.ticketinfo; 

  // Vars for editable items
  const [dept, setDept] = React.useState('Support');
  const [wkflow, setWkflow] = React.useState('Support');
  
  //Handle Department change
  const deptChange = (event) => {
    setDept(event.target.value);
  };

  //Handle Workflow change
  const wkflowChange = (event) => {
    setWkflow(event.target.value);
  };

  return (
    <>
      <Card sx={{ minWidth: 275, borderRadius: 'var(--box-border-radius)', boxShadow: 4 }} key={ticket.id}>
        <CardHeader sx={{background: "var(--headings-fill)", color: "var(--headings-text)"}}
          action={
            <Tooltip title="Bookmark" placement="bottom-start">
              <IconButton onClick={bhandleClick}>
                <BookmarkIcon sx={{ fontSize: 35, color: "var(--icon-action)" }} />
              </IconButton>
            </Tooltip>
            }
      title={ticket.first_name + " " + ticket.last_name}
      subheader={ticket.email} 
      subheaderTypographyProps={{ component: 'div', color: 'var(--menu-subtext-visible)' }} 
      />

    <CardContent >

      {/* CC EMAILS */}
      <Chip key="1" label="CC: dad@fred.component" sx={{fontSize: 14}} variant="outlined" onDelete={cchandleDelete} onClick={cchandleClick} icon={<AccountCircleIcon />} />
      <Typography sx={{fontSize: ".3em"}}><br /></Typography>
      <Chip key="2" label="CC: ma@fred.com" sx={{fontSize: 14}} variant="outlined" onDelete={cchandleDelete} onClick={cchandleClick} icon={<AccountCircleIcon />} />
      
      {/* ADD CC LINK */}
      <CardActions>
        <Button onClick={ccaddhandleClick} size="small" sx={{ ml: -2 }}>&nbsp;&nbsp;ADD CC</Button>
      </CardActions>
      
      <Divider />
      
      {/* SUBJECT */}
      <Stack direction="row" spacing={1}>
      <Typography sx={{ mb: 1, mt: 1, fontSize: 18 }} component="span">
        {ticket.subject} 
        <Tooltip title="Edit" placement="bottom-start">
          <IconButton onClick={subhandleClick}>
            <EditIcon sx={{ fontSize: 22}} />
          </IconButton>
        </Tooltip>
      </Typography>
      </Stack>
      

      {/* DEPARTMENT DROPDOWN */}      
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-medium-label">Department</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={dept}
          label="Department"
          onChange={deptChange}
          >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"Support"}>Support</MenuItem>
          <MenuItem value={"Sales"}>Sales</MenuItem>
          <MenuItem value={"Engineering"}>Engineering</MenuItem>
          <MenuItem value={"Billing"}>Billing</MenuItem>
        </Select>
      </FormControl>

      <Typography sx={{fontSize: ".3em"}}><br /></Typography>

      {/* WORKFLOW DROPDOWN */}      
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-medium-label">Workflow</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={wkflow}
          label="Workflow"
          onChange={wkflowChange}
          >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"Support"}>Support</MenuItem>
          <MenuItem value={"Sales"}>Sales</MenuItem>
          <MenuItem value={"Billing"}>Billing</MenuItem>
        </Select>
      </FormControl>
        <Divider />
        <br />
 
      {/* LABEL TAGS */}     
      <Chip
        label="Critical" color="error" size="small"
        onDelete={lhandleDelete}
      />&nbsp;
      <Chip
        label="Partner" color="warning" size="small"
        onDelete={lhandleDelete}
      />
      <IconButton onClick={lhandleAdd}><AddIcon /></IconButton>
           <br />
    

      </CardContent>

    </Card>
    </>  
    
  );
}