import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Icons
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../AlertCustom.js';



// TICKET ROW LOOP ************************************************************************************************************************************************

// array of emails
const TicketRow = ({ ticketrow, isLastItem}) => {
  const [open, setOpen] = useState(false);

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  
  // ACTIONS ************************************************************************************************************************************************

  //  When reset of states is needed
  const reset = () => {
    handleClose();
  };

  // open and close state of dialog popup
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  

  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>
      <ListItemButton onClick={handleClickOpen} sx={{mt:-1, mb:-1}}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "var(--icon-avatar)", width: 28, height: 28 }}>
              <ForwardToInboxIcon sx={{ width: 20, height: 20 }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={ticketrow.subject}
            secondary={
              <>
                {ticketrow.status=="pending_user" && <Typography variant="button" display="block" sx={{color: "green"}}>Reply waiting {ticketrow.date_updated}</Typography>}
                {ticketrow.status=="pending_agent" && <Typography variant="button" display="block" sx={{color: "black"}}>Awaiting Agent Reply {ticketrow.date_updated}</Typography>}
                
              </>
            }
          />
        </ListItem>
      </ListItemButton>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

      {/* Divider */}
      {!isLastItem && <Divider variant="inset" />}
    </>
  );
};


// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function TicketsArchived({clientId}) {
  //console.log(clientId);
  const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
  const ticketUrl = url+'/api/v1/tickets?client_id='+ clientId;

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

// get client tickets
const [tickets, setTickets] = useState([]);
useEffect(() => {
  const callRestApi = async () => {
    try {
      const response = await fetch(ticketUrl);
      const jsonResponse = await response.json();
      setTickets(jsonResponse.data);
    } catch (error) {
      console.log('Error fetching data:', error);
   }
  };
  callRestApi();
}, [clientId]);


  // RETURN  **********************************************************************************************************************************
 
  return (
    <>
    {tickets.length !== 0 ? (
      <List sx={{ width: '100%', maxWidth: 350, bgcolor: 'background.paper' }}>
        {/* START TICKET LOOP */}
        {tickets.map((ticketrow, index) => (
          <TicketRow
            key={ticketrow.id} 
            ticketrow={ticketrow} 
            isLastItem={index === tickets.length - 1} // Check if it's the last item
          />
        ))}
        {/* END TICKET LOOP */}
      </List>
    ) : (
      <List sx={{ width: '100%', maxWidth: 350, bgcolor: 'background.paper' }}>
        <ListItemText primary="No archived tickets yet" />
      </List>
    )}
    
    {/* ALERT */}
    <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />
    </>
  );
}