import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

// icons
import CloseIcon from '@mui/icons-material/Close';
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../../AlertCustom.js';
// Crud Happiness
import { deleteAsked } from '../../../services/delete';
import { putDepts } from '../../../services/put';
// HELPERS
import {validateClean} from '../../../helpers/validate';
import {getCountTickets} from '../../../helpers/getCount';


// ICON PICKER DATA *****************************************************************************************************************************************
import AssignmentIcon from '@mui/icons-material/Assignment';
import People from '@mui/icons-material/People';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PaymentsIcon from '@mui/icons-material/Payments';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DeckIcon from '@mui/icons-material/Deck';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function GeneralDepts({ dpts, onSaveSuccess, onSaveError }) {

  const [openDialogId, setOpenDialogId] = useState(null); // null means no dialog open
  const [dptId, setDptId] = useState("");
  const [dpt, setDpt] = useState("");
  const [dpticon, setDptIcon] = useState("");
  const [dptEmail, setDptEmail] = useState("");
  const [client_view, setClient_view] = useState(false); 

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  // Delete a dept constants
  const [openDelConfirm, setOpenDelConfirm] = useState(false);
  const handleCloseDelConfirm = () => { setOpenDelConfirm(false); };
  const [countIdForDel, setCountIdForDel] = useState("");
  const [goWhereDept, setGoWhereDept] = useState("");
  
  

  // ACTIONS ************************************************************************************************************************************************

  // set states of particular dept when opened
  const handleClickOpen = (deptrow) => { 
    setDptId(deptrow.id);
    setDpt(deptrow.name);
    setDptIcon(deptrow.icon);
    setClient_view(Boolean(deptrow.client_view));
    setOpenDialogId(deptrow.id); 
  };
  const handleClose = () => { setOpenDialogId(null); };
    
  // when an icon is clicked in dept edit screen 'department.icon' field (state = dpticon)
  function handleIconClick(icon) { setDptIcon(icon); };

  function isLastItem(index,length) {
    if (index === length - 1) { 
      return true;
    } else {
      return false;
    }
  }


  // SAVE FUNCTION **********************************************************************************************************************************

  const handleSave = () => {
    if (
      validateClean(dpt)
    ) {
      putDepts(dptId, dpt, dpticon, client_view, onSaveSuccess, onSaveError);
      handleClose();
    } else {
      setAlertInfo(["invalidText","error","Error","Enter only letters or numbers"]);
      setAlertCustom(true);
    }
  };


  // DEL FUNCTION ********************************************************

  const handleDelete = (id) => {
    // ask what dept to change these tickets to
    getCountTickets(dptId,"dept")
    .then((ticketCount) => {
      setCountIdForDel(ticketCount);
    })
    .catch((error) => { console.error("Error fetching count:", error); });
    setOpenDialogId(null);
    setOpenDelConfirm(true);
  }

  const handleConfirmDelClick = () => {
    if (countIdForDel == 0) {
      deleteAsked(dptId, "dept", "/api/admin/depts", "notickets", "", onSaveSuccess, onSaveError);
      setOpenDelConfirm(false);
    } else if (countIdForDel > 0 && goWhereDept) {
      deleteAsked(dptId, "dept", "/api/admin/depts", goWhereDept, "dept",  onSaveSuccess, onSaveError);
      setOpenDelConfirm(false);
    } else if (countIdForDel > 0 && !goWhereDept) {
      setOpenDelConfirm(false);
      setOpenDialogId(dptId);
      setAlertInfo(["invalidText","error","Error","Department replacment required"]);
      setAlertCustom(true);
    }
  }


  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>
 
    {/* START DEPT LOOP */}
    {dpts.map((deptrow, index) => (
      
      <React.Fragment key={deptrow.id}>
 
      <ListItemButton onClick={() => handleClickOpen(deptrow)} onMouseDown={e => e.stopPropagation()} sx={{mt:0, mb:0}} >
        <ListItem key={deptrow.id}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "var(--headings-fill)", width: 36, height: 36 }}>
              {deptrow.icon === "AssignmentIcon" && <AssignmentIcon alt={dpt} sx={{ width: 24, height: 24 }} /> }
              {deptrow.icon === "People" && <People alt={dpt} sx={{ width: 24, height: 24 }} /> }
              {deptrow.icon === "EngineeringIcon" && <EngineeringIcon alt={dpt} sx={{ width: 24, height: 24 }} /> }
              {deptrow.icon === "PaymentsIcon" && <PaymentsIcon alt={dpt} sx={{ width: 24, height: 24 }} /> }
              {deptrow.icon === "TrendingUpRoundedIcon" && <TrendingUpRoundedIcon alt={dpt} sx={{ width: 24, height: 24 }} /> }
              {deptrow.icon === "MilitaryTechIcon" && <MilitaryTechIcon alt={dpt} sx={{ width: 24, height: 24 }} /> }
              {deptrow.icon === "AccountTreeIcon" && <AccountTreeIcon alt={dpt} sx={{ width: 24, height: 24 }} /> }
              {deptrow.icon === "DeckIcon" && <DeckIcon alt={dpt} sx={{ width: 24, height: 24 }} /> }
              {deptrow.icon === "DesignServicesIcon" && <DesignServicesIcon alt={dpt} sx={{ width: 24, height: 24 }} /> }
            </Avatar> 
          </ListItemAvatar>
          <ListItemText primary={deptrow.name} />
        </ListItem>
      </ListItemButton>

      {/* DIALOG BOX FOR DEPT EDIT */}
      <Dialog open={openDialogId === deptrow.id} onClose={handleClose} TransitionComponent={Zoom} sx={{ '& .MuiDialog-paper': { minWidth: 400 } }}>
        <DialogTitle>Edit Department</DialogTitle>

        {/* ALERT */}
        <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} /> 
        
        <IconButton
          aria-label="close"
          onClick={()=>handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ ml: "50px", mr: "20px"}}>

          <TextField
            autoFocus
            margin="dense"
            id="editdpt"
            onChange={(event) => {
              setDpt(event.target.value);
            }}
            helperText="Text: Numbers or letters only"
            value={dpt} 
            fullWidth
            variant="standard"
          />
          <br /><br />


          {/* Email DROPDOWN */}      
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-medium-label">Associated Email</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={dptEmail}
              label="Email"
              sx={{width: "200px"}}
              onChange={(event) => {
                setDptEmail(event.target.value);
              }}
            >
            <MenuItem value={""}><em>None</em></MenuItem>
            <MenuItem value={"Email1"}>Email1</MenuItem>
            <MenuItem value={"Email2"}>Email2</MenuItem>
          </Select>
          </FormControl>
          <br />

          <Typography variant="caption" sx={{ fontSize: 15}}>
            Edit icon
          </Typography>
          <br />

          <ButtonGroup variant="outlined">
            <Button onClick={() => handleIconClick("AssignmentIcon")} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
              {dpticon === "AssignmentIcon" 
              ? <AssignmentIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
              : <AssignmentIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
            </Button>
            <Button onClick={() => handleIconClick("People")} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
              {dpticon === "People" 
              ? <People sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
              : <People sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
            </Button>
            <Button onClick={() => handleIconClick("EngineeringIcon")} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
              {dpticon === "EngineeringIcon" 
              ? <EngineeringIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
              : <EngineeringIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
            </Button>
          </ButtonGroup>
          <br />

          <ButtonGroup variant="outlined">
            <Button onClick={() => handleIconClick("PaymentsIcon")} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
              {dpticon === "PaymentsIcon" 
              ? <PaymentsIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
              : <PaymentsIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
            </Button>
            <Button onClick={() => handleIconClick("TrendingUpRoundedIcon")} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
              {dpticon === "TrendingUpRoundedIcon" 
              ? <TrendingUpRoundedIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
              : <TrendingUpRoundedIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
            </Button>
            <Button onClick={() => handleIconClick("MilitaryTechIcon")} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
              {dpticon === "MilitaryTechIcon" 
              ? <MilitaryTechIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
              : <MilitaryTechIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
            </Button>
          </ButtonGroup>
          <br />

          <ButtonGroup variant="outlined">
            <Button onClick={() => handleIconClick("AccountTreeIcon")} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
              {dpticon === "AccountTreeIcon" 
              ? <AccountTreeIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
              : <AccountTreeIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
            </Button>
            <Button onClick={() => handleIconClick("DeckIcon")} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
              {dpticon === "DeckIcon" 
              ? <DeckIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
              : <DeckIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
            </Button>
            <Button onClick={() => handleIconClick("DesignServicesIcon")} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
              {dpticon === "DesignServicesIcon" 
              ? <DesignServicesIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
              : <DesignServicesIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
            </Button>
          </ButtonGroup>
          <br /><br />

          {/* DPT CLIENT_VIEW */}
          <FormControlLabel control={
            <Switch 
              checked={client_view}
              color="warning" 
              onChange={(event, newValue) => {
                setClient_view(newValue);
              }} 
          />} 
          label="Visible by client?" />  

        </DialogContent>
        <DialogActions>
              <Button onClick={() => handleClose()}>Cancel</Button>
              <Button onClick={() => handleDelete(deptrow.id,false)}>Delete</Button>
              <Button onClick={() => handleSave()}>Save</Button>
            </DialogActions>
      </Dialog>

      {/* Divider */}
      {!isLastItem(index,dpts.length) && <Divider variant="inset" />}
      </React.Fragment>

    ))}
    
    {/* END DEPT LOOP */}

    {/* DIALOG BOX FOR DEPT DELETE */}
    <Dialog open={openDelConfirm} onClose={handleCloseDelConfirm} TransitionComponent={Zoom} sx={{ '& .MuiDialog-paper': { minWidth: 500 } }}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={()=>handleCloseDelConfirm()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ ml: "50px", mr: "20px"}}>

          {countIdForDel > 0 && (
            <>
            <Typography variant="caption" sx={{ fontSize: 15, color: 'black'}}>
              There are currently {countIdForDel} ticket(s) assigned to the {dpt} department. Which department would you like these tickets assigned to?
            </Typography>
          
            <br /><br />
            <FormControl fullWidth>
              <InputLabel id="dept-select-label">Department</InputLabel>
              <Select
                labelId="dept-select-label"
                id="dept-select"
                value={goWhereDept}
                onChange={(event) => {
                  setGoWhereDept(event.target.value);
                }}
                >
                {dpts.map((dept) => (
                  dept.id !== dptId && (
                  <MenuItem key={dept.id} value={dept.id}>{dept.name}</MenuItem>
                  )
                ))}
              </Select>
              <br />
              <Typography variant="caption" sx={{ fontSize: 15, color: 'red'}}>
                WARNING: THIS CAN NOT BE UNDONE!
              </Typography>
            </FormControl>
            </>
          )}
          {countIdForDel == 0 && (
            <>
            <Typography variant="caption" sx={{ fontSize: 15, color: 'black'}}>
              There are currently 0 tickets assigned to the {dpt} department. Please confirm you would like to delete this department. 
            </Typography>
            <br /><br />
            <Typography variant="caption" sx={{ fontSize: 15, color: 'red'}}>
                WARNING: THIS CAN NOT BE UNDONE!
            </Typography>      
            <br /><br />
            </>
          )} 
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDelConfirm()}>Cancel</Button>
          <Button onClick={() => handleConfirmDelClick()}>Confirm</Button>
        </DialogActions>
    </Dialog>

    </>
  );
}