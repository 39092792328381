import { validateEmail, validateTextEmail, validateClean, validateNumbers, validatePass, removeHashtag} from '../helpers/validate';
// Base API url for Admin -> General
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
// Set vars
var newUrl = "";

// PUT TICKETS ****************************************************************************************************************************
export async function putTicket(id, what, oldThing, newThing, by, onSaveSuccess, onSaveError) {

  var apiPath = "/api/v1/tickets";
  if (id == null) { 
    newUrl = url + `${apiPath}?what=${what}&oldThing=${oldThing}&newThing=${newThing}&by=${by}`;
  } else {
    newUrl = url + `${apiPath}?id=${id}&what=${what}&oldThing=${oldThing}&newThing=${newThing}&by=${by}`;
  }
  
  //console.log(newUrl);

  try {
    fetch(newUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          onSaveSuccess("dept");
        } else {
          // Request failed
          onSaveError("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        onSaveError(error);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    onSaveError(error);
  }
};

// PUT EMAILS ****************************************************************************************************************************
export async function putEmails(id, value, enabled, extra, setAlertCustom, setAlertInfo) {
    var apiPath = "/api/admin/general";
    newUrl = url + `${apiPath}?id=${id}&value='${value}'&enabled=${enabled}&extra='${extra}'`;

    try {
      fetch(newUrl, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.ok) {
            // Request was successful
          } else {
            // Request failed
            throw new Error("Request failed");
          }
        })
        .catch((error) => {
          // Request error
          console.error("Error during API request:", error);
          setAlertInfo(["GeneralError","error","Error",error]);
          setAlertCustom(true);
        });
    } catch (error) {
      // Request error
      console.error("Error during API request:", error);
      setAlertInfo(["GeneralError","error","Error",error]);
      setAlertCustom(true);
    }
 };

// PUT EMAILS ****************************************************************************************************************************
export async function putSmtp(id, value, enabled, extra, setAlertCustom, setAlertInfo) {
  var apiPath = "/api/admin/general";
  newUrl = url + `${apiPath}?id=${id}&value='${value}'&enabled=${enabled}&extra='${extra}'`;

  try {
    fetch(newUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          // Request was successful
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        setAlertCustom(true);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    setAlertInfo(["GeneralError","error","Error",error]);
    setAlertCustom(true);
  }
};

// PUT TAGS ****************************************************************************************************************************
export async function putTags(id, tag, color, txtcolor, setAlertCustom, setAlertInfo) {
  var apiPath = "/api/admin/tags";
  newUrl = url + `${apiPath}?id=${id}&tag='${tag}'&color='${color}'&txtcolor='${txtcolor}'`;

  try {
    fetch(newUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          // Request was successful
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        setAlertCustom(true);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    setAlertInfo(["GeneralError","error","Error",error]);
    setAlertCustom(true);
  }
};

// PUT DEPTS ****************************************************************************************************************************
export async function putDepts(id, name, icon, client_view, onSaveSuccess, onSaveError) {
  var apiPath = "/api/admin/depts";
  newUrl = url + `${apiPath}?id=${id}&name='${name}'&icon='${icon}'&client_view=${client_view}`;

  try {
    fetch(newUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          onSaveSuccess("dept");
        } else {
          // Request failed
          onSaveError("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        onSaveError(error);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    onSaveError(error);
  }
};

// PUT Workflows ****************************************************************************************************************************
export async function putWorkflows(id, name, isDefault, onSaveSuccess, onSaveError) {

  newUrl = url + "/api/v1/workflows/" + id;

  if (validateClean(name)) {

    try {
      fetch(newUrl, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
          name: name,
          is_default: isDefault
        }),
      })
      .then((response) => {
        if (response.ok) {
          // Request was successful
          onSaveSuccess("workflow");
        } else {
          // Request failed
          onSaveError("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        onSaveError(error);
      });
    } catch (error) {
      // Request error
      console.error("Error during API request:", error);
      onSaveError(error);
    }
  }
};

// PUT Issues ****************************************************************************************************************************
export async function putIssues(id, name, client_view, archive, onSaveSuccess, onSaveError) {
  var apiPath = "/api/v1/issues/" + id;

  newUrl = url + apiPath;
  
  try {
    fetch(newUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        client_view: client_view,
        archive: archive,
      }),
    })
      .then((response) => {
        if (response.ok) {
          // Request was successful
          onSaveSuccess("issue");
        } else {
          // Request failed
          onSaveError("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        onSaveError(error);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    onSaveError(error);
  }

};

// PUT Portal Config ****************************************************************************************************************************
export async function putPortal(selectedDepartments, unselectedDepartments, enableIssues, selectedIssues, unselectedIssues, enablePriority, portalText, onSaveSuccess, onSaveError) {
  var i;

  // declare put function for config
  function doConfigPut(thisUrl,lastcall) {

    try {
      fetch(thisUrl, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.ok) {
            // Request was successful
            if (lastcall === true) { onSaveSuccess("portal"); }
          } else {
            // Request failed
            onSaveError("Request failed");
          }
        })
        .catch((error) => {
          // Request error
          console.error("Error during API request:", error);
          onSaveError(error);
        });
    } catch (error) {
      // Request error
      console.error("Error during API request:", error);
      onSaveError(error);
    }
  }

  var apiPath = "/api/admin/general";

  // update selected departments
  i = 0;
  while (i < selectedDepartments.length) {
    var d = (selectedDepartments[i]);
    putDepts(d.id, d.name, d.icon, true, onSaveSuccess, onSaveError);
    i++;
  }

  // update UNselected departments
  i = 0;
  while (i < unselectedDepartments.length) {
    var du = (unselectedDepartments[i]);
    putDepts(du.id, du.name, du.icon, false, onSaveSuccess, onSaveError);
    i++;
  }

  // update selected issues
  i = 0;
  while (i < selectedIssues.length) {
    var iss = (selectedIssues[i]);
    putIssues(iss.id, iss.name, true, false, onSaveSuccess, onSaveError);
    i++;
  }
  
  // update UNselected issues
  i = 0;
  while (i < unselectedIssues.length) {
    var issu = (unselectedIssues[i]);
    putIssues(issu.id, issu.name, false, false, onSaveSuccess, onSaveError);
    i++;
  }

  // update issues enabled field
  doConfigPut(url + `${apiPath}?option='portal'&value='issues'&enabled=${enableIssues}`,false);

  // update priority enabled field
  doConfigPut(url + `${apiPath}?option='portal'&value='priority'&enabled=${enablePriority}`,false);

  // update portal text
  doConfigPut(url + `${apiPath}?option='portal'&value='text'&extra='${portalText}'`,true);
 
};

// PUT AGENTS ****************************************************************************************************************************
export async function putAgents(id, email, password, firstName, lastName, displayName, role, phone, timezone, profilePic, profilePicData, setAlertCustom, setAlertInfo) {
  var apiPath = "/api/v1/agents";
  newUrl = url + `${apiPath}?id=${id}&email=${email}&first_name=${firstName}&last_name=${lastName}&display_name=${displayName}&role=${role}&tz=${timezone}`;
  

  if ( phone ) { 
    newUrl = newUrl + `&phone=${phone}`;
  }
  if ( password ) { 
    newUrl = newUrl + `&password=${password}`;
  }
  if ( profilePic ) { 
    newUrl = newUrl + `&icon=${profilePic}`;
  }

 
  //console.log("id=" + id + ", em=" + email + ", pass=" + password + ", fN=" + firstName + ", lN=" + lastName + ", dN=" + displayName + ", ph=" + phone + ", tz=" + timezone + ", icon=" + profilePic);


  try {
    fetch(newUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          // Request was successful
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        setAlertCustom(true);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    setAlertInfo(["GeneralError","error","Error",error]);
    setAlertCustom(true);
  }


  // if profilepic uploaded then save it
  if ( profilePicData) { 

    const apiAvPath = "/api/human/avatar";
    const newAvUrl = url + `${apiAvPath}`;

    const formData = new FormData();
      formData.append('file', profilePicData, 'avatar' + String(id) + '.png');

      try {
        const avResponse = await fetch(newAvUrl, {
          method: 'POST',
          body: formData
        });

        if (!avResponse.ok) {
          //console.log(formData);
          throw new Error('Request failed');
        }

      } catch (error) {
        console.error('Error during image upload request:', error);
         //show an alert
      }

  }

};

// PUT CLIENTS ****************************************************************************************************************************
export async function putClients(id, email, password, firstName, lastName, timezone, profilePic, profilePicData, setAlertCustom, setAlertInfo) {
  // agent url is same
  var apiPath = "/api/v1/agents";
  newUrl = url + `${apiPath}?id=${id}&email=${email}&first_name=${firstName}&last_name=${lastName}&tz=${timezone}`;
  
  if ( password ) { 
    newUrl = newUrl + `&password=${password}`;
  }
  if ( profilePic ) { 
    newUrl = newUrl + `&icon=${profilePic}`;
  }

 
  //console.log("id=" + id + ", em=" + email + ", pass=" + password + ", fN=" + firstName + ", lN=" + lastName + ", dN=" + displayName + ", ph=" + phone + ", tz=" + timezone + ", icon=" + profilePic);
  //console.log("newUrl=" + newUrl);

  try {
    fetch(newUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          // Request was successful
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        setAlertCustom(true);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    setAlertInfo(["GeneralError","error","Error",error]);
    setAlertCustom(true);
  }


  // if profilepic uploaded then save it
  if ( profilePicData) { 

    //console.log(profilePicData);

    const apiAvPath = "/api/human/avatar";
    const newAvUrl = url + `${apiAvPath}`;

    const formData = new FormData();
      formData.append('file', profilePicData, 'avatar' + String(id) + '.png');

      try {
        const avResponse = await fetch(newAvUrl, {
          method: 'POST',
          body: formData
        });

        if (!avResponse.ok) {
          //console.log(formData);
          throw new Error('Request failed');
        }

      } catch (error) {
        console.error('Error during image upload request:', error);
         //show an alert
      }

  }

};

// ENABLE ARCHIVED AGENT AGENTS ****************************************************************************************************************************
export async function enableAgent(email, role, setAlertCustom, setAlertInfo, handleClose, handleUpdateFromComp) {
  var apiPath = "/api/agent/enable";
  newUrl = url + `${apiPath}?email=${email}&role=${role}`;
  //console.log(email, role);

  try {
    fetch(newUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
            // Request was successful. Set alert info array
            setAlertInfo(["deleteThing","success","Alert","Agent has been enabled"]);
            // tell user we deleted it and close window
            setAlertCustom(true);
            // give it time to update before we refresh all data
            handleClose("askathing");
            handleClose("agent");
            setTimeout(function(){ 
              handleUpdateFromComp("agents");
            }, 1000);
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        handleClose("askathing");
        setAlertCustom(true);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    setAlertInfo(["GeneralError","error","Error",error]);
    handleClose("askathing");
    setAlertCustom(true);
  }

};