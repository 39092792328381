// NEEDED THINGS
import React, { useState, useEffect, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";
import Cookies from "js-cookie";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
// CRUD HAPPINESS
import { getStuff } from '../services/get';
// HELPERS
import { getId } from '../helpers/getId';

// COMPONENTS
import HeaderAppBarClient from "../components/ClientPanel/HeaderAppBarClient";
import TicketsActive from "../components/ClientPanel/TicketsActive";
import TicketsArchived from "../components/ClientPanel/TicketsArchived";
import TicketsOpenNew from "../components/ClientPanel/TicketsOpenNew";
import Authorization from "../components/ClientPanel/Authorization";

// FOR THEME
import {Container} from '@mui/material';
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import Typography from '@mui/material/Typography';
//Cards
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
// ICONS
import Home from '@mui/icons-material/Home';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AssignmentIcon from '@mui/icons-material/Assignment';
import KeyIcon from '@mui/icons-material/Key';

const appName = process.env.REACT_APP_ACT_NAME;

// grid things
const GridItemWrapper = styled.div`
  color: var(--headings-text);
  border-radius: var(--box-border-radius);
  box-shadow: 4;
  overflow: hidden;
  `;
const GridItemContent = styled.div`
  padding: 0px;
`;
const ResponsiveGridLayout = WidthProvider(Responsive);

export default function ClientPortal({ setAuth}) {

  // declare screen size vars
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // get content size of menu (left side)
  const refSizeMenu = useRef();
  const [dimensionsMenu, setDimensionsMenu] = useState({ width: 0, height: 0, });

  // grid calc for content
  var hMenu = dimensionsMenu.height/10;
  if (hMenu < 7) { hMenu = 7;}

  const layout = [
    { i: "menu", x: 0, y: 0, w: 3, minW: 2, h: hMenu, minH: 7},
    { i: "right", x: 3, y: 0, w: 9, h: windowSize.height, minH: windowSize.height}
  ];

    // Get email
    const rikrEmail = Cookies.get("rikr_email");

    // constants
    const [clientId, setClientId] = useState(0);
    const [clientProfile, setClientProfile] = useState([]);
    const [clientActsOwned, setClientActsOwned] = useState([]);
    const [clientActsAuthed, setClientActsAuthed] = useState([]);
    const [activeLink, setActiveLink] = useState("");
    const [portalConfigs, setPortalConfigs] = useState([]);
    const [deptOptions, setDeptOptions] = useState([]);
    const [issueOptions, setIssueOptions] = useState([]);
    const [doneLoading, setDoneLoading] = useState(false);

    function refreshDepts() {
      getStuff("/api/admin/depts")
        .then((data) => { 
          setDeptOptions(data[0].filter((dpt) => dpt.client_view === 1)); 
        })
        .catch((error) => { console.error("Error fetching depts:", error); });
     }

    function refreshConfigs() {
      getStuff("/api/admin/general")
        .then((data) => { 
          setPortalConfigs(data); 
        })
        .catch((error) => { console.error("Error fetching configs:", error); });
    }

    function refreshIssues() {
      getStuff("/api/v1/issues")
        .then((data) => { 
          setIssueOptions(data[0].filter((issue) => issue.client_view === 1 && Boolean(issue.archive) == false));
        })
        .catch((error) => { console.error("Error fetching issues:", error); });
    }

    function refreshActs() {
      // ID
      getId(rikrEmail,0)
        .then((dataid) => {
          setClientId(dataid);
          // PROFILE
          getStuff("/api/v1/humans/" + dataid)
            .then((data) => {
              setClientProfile(data[0][0]);
            })
            // OWNER ACTS
            getStuff("/api/v1/accounts?owner_id=" + dataid)
              .then((data) => {
                setClientActsOwned(data[0]);
                console.log(data[0]);
              })
              // AUTHED ACTS
              getStuff("/api/v1/humans/" + dataid + "/authed")
                .then((data) => {
                  setClientActsAuthed(data[0]);
                  setDoneLoading(true);
                  console.log(data[0]);
                })
                .catch((error) => { console.error("Error fetching id:", error); });
              })
              .catch((error) => { console.error("Error fetching id:", error); });
    }

    // GET once on pageload
    useEffect(() => { 
      refreshDepts();
      refreshConfigs();
      refreshIssues();
      refreshActs();
       // SET default menu item
      setActiveLink("Active");
    }, []);

    // do sizes
    useEffect(() => {
      if (refSizeMenu.current) {
        if ( refSizeMenu.current.offsetWidth != dimensionsMenu.width || refSizeMenu.current.offsetHeight != dimensionsMenu.height ) {
          setDimensionsMenu({
            width: refSizeMenu.current.offsetWidth,
            height: refSizeMenu.current.offsetHeight,
          });
        }
      }
    }, []);

    // FUNCTION for link click in menu  
    const handleLinkClickMenu = (page, dept) => {
      setActiveLink(page);
    };
 
    // RETURN HERE ********************************************************************
    return(
      <Container maxWidth={false} disableGutters sx={{backgroundColor: "var(--bg-fill)", height: "100vh", marginRight: '10px'}}>

        {/* TOP BAR */}
        { clientProfile && (
          <HeaderAppBarClient clientProfile={clientProfile} />
        )}

          <ResponsiveGridLayout
            layouts={{ lg: layout }}
            isResizable={true}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
            rowHeight={1}
            isDraggable={false}
            width={windowSize.width}
            >

              {/* Left Menu */}
              <GridItemWrapper key="menu" style={{backgroundColor: "var(--headings-fill)"}}>
              <GridItemContent ref={refSizeMenu} sx={{marginRight: '0px'}}>
                  <ListItemButton component="a" sx={{ height: 70, py: 0,}}>
                      {/* TICKET ICON */}
                      <ListItemIcon>
                          <ConfirmationNumberIcon sx={{ fontSize: 20, color: "var(--ticket-icon)" }}/>
                      </ListItemIcon>
                      <ListItemText sx={{ my: 0, ml: -3 }}
                        primary = {appName}
                        primaryTypographyProps={{ fontSize: 19, fontWeight: 'medium', variant: 'body2' }}/>
                  </ListItemButton>

                  <Divider sx={{ backgroundColor: "var(--menu-divider)" }} />

                  {/* MENU TICKETS *********************************************************************************************************************************** */}
                  <Box sx={{ bgcolor: 'var(--menu-open)' }}>
                    <ListItemText primary="Tickets" primaryTypographyProps={{ mt: -.5, px: 2.5, pt: 2, pb: 1, fontSize: 18, fontWeight: 'medium', lineHeight: '18px', mb: '2px'}} />
                    <a key='Active' href="#" onClick={() => handleLinkClickMenu('Active')} style={{ textDecoration: 'none' }}> 
                      <ListItemButton key='Open' 
                          sx={{ py: 0, minHeight: 32, color: activeLink === 'Active' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>
                          <ListItemIcon sx={{ color: 'inherit' }}><ConfirmationNumberIcon /></ListItemIcon>
                          <ListItemText primary='Active' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                      </ListItemButton>
                    </a> 
                    <a key='Archived' href="#" onClick={() => handleLinkClickMenu('Archived')} style={{ textDecoration: 'none' }}> 
                      <ListItemButton key='Archived' 
                          sx={{ py: 0, minHeight: 32, color: activeLink === 'Archived' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>
                          <ListItemIcon sx={{ color: 'inherit' }}><ConfirmationNumberIcon /></ListItemIcon>
                          <ListItemText primary='Archived' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                      </ListItemButton>
                    </a> 
                    <a key='OpenNew' href="#" onClick={() => handleLinkClickMenu('OpenNew')} style={{ textDecoration: 'none' }}> 
                      <ListItemButton key='OpenNew' 
                          sx={{ py: 0, minHeight: 32, color: activeLink === 'OpenNew' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>
                          <ListItemIcon sx={{ color: 'inherit' }}><ConfirmationNumberIcon /></ListItemIcon>
                          <ListItemText primary='Open a New Ticket' primaryTypographyProps={{ pb: 0, fontSize: 16, fontWeight: 'medium' }} />
                      </ListItemButton>
                    </a>
                    <br />
                  </Box>

              <Divider sx={{ backgroundColor: "var(--menu-divider)" }} />

              {/* MENU Authorizaton *********************************************************************************************************************************** */}
              <Box>
                <a key='Authorizaton' href="#" onClick={() => handleLinkClickMenu('Authorizaton')} style={{ textDecoration: 'none' }}> 
                <ListItemButton key='Authorizaton' 
                  sx={{ py: 0, mt: 2,minHeight: 32, color: activeLink === 'Authorizaton' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>
                  <ListItemIcon sx={{ color: 'inherit' }}><KeyIcon /></ListItemIcon>
                  <ListItemText primary='Authorizaton' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                </ListItemButton>
                </a> 

              {/* MENU KB *********************************************************************************************************************************** */}
              <a key='KB' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('KB')} style={{ textDecoration: 'none' }}> 
                <ListItemButton key='KB' 
                  sx={{ py: 0, minHeight: 32, color: activeLink === 'KB' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>
                  <ListItemIcon sx={{ color: 'inherit' }}> <AssignmentIcon /></ListItemIcon>
                  <ListItemText primary='KB' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                </ListItemButton>
              </a> 

            </Box>
              </GridItemContent>
              <div className="dragarrow">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
                  <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
                </svg>
              </div>
              </GridItemWrapper>


              {/* Right Side */}
              <GridItemWrapper key="right" >
              <GridItemContent sx={{marginRight: '0px'}}>
                <Card sx={{ mb: 0, width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
                  <CardHeader sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
                    title={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Home sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                        <Typography sx={{ marginLeft: '8px' }}>Welcome, {clientProfile.first_name} {clientProfile.last_name}</Typography>
                      </div>
                      }
                  />
                  <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
                  { clientId && activeLink === "Active" && doneLoading && (
                    <TicketsActive
                    clientId={clientId}
                    clientActsOwned={clientActsOwned}
                    clientActsAuthed={clientActsAuthed}
                    deptOptions={deptOptions}
                    issueOptions={issueOptions}
                    />
                  )}
                  { clientId && activeLink === "Archived" && doneLoading && (
                    <TicketsArchived
                    clientId={clientId}
                    clientActsOwned={clientActsOwned}
                    clientActsAuthed={clientActsAuthed}
                    deptOptions={deptOptions}
                    issueOptions={issueOptions}
                    />
                  )}
                  { clientId && activeLink === "OpenNew" && doneLoading && (
                    <TicketsOpenNew
                    clientId={clientId}
                    portalConfigs={portalConfigs}
                    clientActsOwned={clientActsOwned}
                    clientActsAuthed={clientActsAuthed}
                    deptOptions={deptOptions}
                    issueOptions={issueOptions}
                    setActiveLink={setActiveLink}
                    />
                  )}
                  { clientId && activeLink === 'Authorizaton' && doneLoading && (
                    <Typography sx={{fontSize: '15px', fontColor: 'var(--menu-subtext-visible)'}}>
                      <strong>Accounts Owned:</strong><br /> 
                      The accounts you own and the authorized users you have allowed access.
                      You may add or delete authorized users on these accounts. 
                      If you add a user that does not currently have an account, they will be sent an invite.<br /><br />
                      <strong>Accounts with Authorization:</strong><br /> 
                      Accounts that you do not own, but have been given access to. 
                      You can not edit authorized users on these accounts.
                    </Typography>
                  )}
                  </CardContent>
                </Card>
              
                {/* Render Authorization without the parent card */}
                { clientId && activeLink === 'Authorizaton' && doneLoading && (
                    <Authorization
                    clientId={clientId}
                    clientActsOwned={clientActsOwned}
                    clientActsAuthed={clientActsAuthed}
                    deptOptions={deptOptions}
                    issueOptions={issueOptions}
                    refreshActs={refreshActs}
                    />
                  )}
              </GridItemContent>
              <div className="dragarrow">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
                  <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
                </svg>
              </div>
              </GridItemWrapper>

          </ResponsiveGridLayout>
      </Container>
    );

}