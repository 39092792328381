import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Button, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import styled from "styled-components";
import Zoom from '@mui/material/Zoom';
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";
import AlertCustom from '../AlertCustom.js';

//CRUD
import {postTickets} from "../../services/post.js"

// ICONS
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import SendIcon from '@mui/icons-material/Send';

// STYLE FOR PRIORITY
const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47',
    },
  });

  // LABELS FOR PRIORITY
  const labels = {
    1: 'Low',
    2: 'Low+',
    3: 'Medium',
    4: 'Medium+',
    5: 'High',
    6: 'High+',
    7: 'Escalate!',
  };
  function getLabelPriority(value) {
    return `${value}, ${labels[value]}`;
  }

// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function TicketsOpenNew({ 
  clientId,
  portalConfigs,
  clientActsOwned,
  clientActsAuthed,
  deptOptions,
  issueOptions,
  setActiveLink
}) {

    // constants for configs
    const enableIssues = Boolean(portalConfigs[0].filter((config) => config.option === 'portal' && config.value === 'issues')[0].enabled);
    const enablePriority = Boolean(portalConfigs[0].filter((config) => config.option === 'portal' && config.value === 'priority')[0].enabled);
    const portalText =portalConfigs[0].filter((config) => config.option === 'portal' && config.value === 'text')[0].extra; 

    function reset() {
      setDepartment('');
      setAccount('');
      setIssue('');
      setSubject('');
      setPriority(1);
      setHover(-1);
      setMessage(EditorState.createEmpty());
    }

    function onSaveSuccess(what) {
      setAlertInfo(["saveComplete","success","Sent!", "Your ticket has been submitted!"]);
      setAlertCustom(true);
      setIsLoading(false);
      reset(what);
      setTimeout(() => {
        setActiveLink("Active");
      }, 1000);
    }

    function onSaveError(err) {
      setAlertInfo(["GeneralError","error","Error",err]);
      setAlertCustom(true);
      setIsLoading(false);
    }

    // Alerts
    const [alertCustom, setAlertCustom] = useState(false);
    const [alertInfo, setAlertInfo] = useState(["","info","",""]);
    const handleCloseAlert = () => { setAlertCustom(false); };

    // Loading send button
    const [isLoading, setIsLoading] = useState(false);

    // constants for form
    const [department, setDepartment] = useState('');
    const [account, setAccount] = useState('');
    const [issue, setIssue] = useState('');
    const [subject, setSubject] = useState('');
    const [priority, setPriority] = useState(1);
    const [hover, setHover] = React.useState(-1);
    const [message, setMessage] = useState(EditorState.createEmpty());
    
 
    const handleSubmit = (event) => {
        event.preventDefault(); //stops dumb page reload
        setIsLoading(true);
        const htmlContent = draftToHtml(convertToRaw(message.getCurrentContent()));

        postTickets(
          clientId, //human_id
          account, //account_id 
          null, //agent_id
          null, //workflow_id 
          department, //department_id 
          issue, //issue_id 
          false, //internal
          subject, //subject
          JSON.stringify({ content: htmlContent }), //message
          priority, //priority
          null, //sla
          2, //status pending_agent
          onSaveSuccess, 
          onSaveError)
    };

    return (
      <>
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />
   
        <form onSubmit={handleSubmit}>
            <Typography variant="h6">Open a New Ticket</Typography>

            {/* DEPARTMENT SELECTION */}
            <FormControl fullWidth margin="normal">
                <InputLabel>Department</InputLabel>
                <Select
                    value={department}
                    label="Department"
                    onChange={(event) => {
                        setDepartment(event.target.value);
                    }}
                    required
                    >
                   {deptOptions.map((dept) => (
                    <MenuItem key={dept.id} value={dept.id}>
                        {dept.name}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* ACCOUNT SELECTION */}
            <FormControl fullWidth margin="normal">
                <InputLabel>Account</InputLabel>
                <Select
                    value={account}
                    label="Account"
                    onChange={(event) => {
                        setAccount(event.target.value);
                      }}
                    required
                >
                   {clientActsOwned.map((act) => (
                    <MenuItem key={act.id} value={act.id}>
                        {act.name}
                    </MenuItem>
                    ))}
                    {clientActsAuthed.map((act) => (
                    <MenuItem key={act.id} value={act.id}>
                        {act.name}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* ISSUE SELECTION */}
            { enableIssues && (
                <>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Issue Type</InputLabel>
                    <Select
                      value={issue}
                      label="Issue Type"
                      onChange={(event) => {
                        setIssue(event.target.value);
                      }}
                      required>
                    {issueOptions.map((issue) => (
                    <MenuItem key={issue.id} value={issue.id}>
                        {issue.name}
                    </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                </>
              )}
              <br />

            {/* SUBJECT TEXT */} 
            <TextField
                fullWidth
                label="Subject"
                margin="normal"
                rows={1}
                value={subject}
                onChange={(event) => {
                  setSubject(event.target.value);
                }}
                required
            /><br /><br />

              {/* TEXT */}
              {portalText}

            {/* BODY - TYPE TICKET MESSAGE */} 
            {/* <TextField
                fullWidth
                label="Message"
                margin="normal"
                multiline
                rows={4}
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
                required
            /> */} 
            <span className="App" >
              <Editor
                label="Message"
                defaultEditorState={message}
                onEditorStateChange={setMessage}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
              <span className="code-view">
                <textarea
                  className="text-area"
                  disabled
                  hidden
                  value={draftToHtml(convertToRaw(message.getCurrentContent()))}
                />
              </span>
            </span>

            <p style={{ lineHeight: ".5" }}>&nbsp;</p>

            {/* PRIORITY SELECTION */}
            { enablePriority && (
                <>
                  <Typography component="legend">Priority</Typography>
                  <p style={{ lineHeight: ".3" }}>&nbsp;</p>
                  <Box
                    sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                    }}>
                    <StyledRating
                      name="priority"
                      defaultValue={2}
                      max={7}
                      getLabelText={getLabelPriority}
                      onChange={(event, newValue) => {
                      setPriority(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    precision={1}
                    icon={<CrisisAlertIcon fontSize="inherit" />}
                    emptyIcon={<CrisisAlertIcon fontSize="inherit" />}
                    />
                    {priority !== null && (
                      <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : priority]}</Box>
                    )}
                  </Box>
                </>
              )}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" 
                    sx={{
                        color: "var(--headings-text)", 
                        backgroundColor: "var(--headings-fill)",
                        '&:hover': {
                            backgroundColor: "var(--headings-fill)",
                            opacity: "85%",
                        }
                    }} 
                    type="submit">
                    Send
                </Button>
            </Box>
  
           
        </form>
        </>
        
    );
}
