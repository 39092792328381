// Base API url
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
var apiPath;

// Count of tickets by deptartment = dept_id
export function getCountTickets(id, by) {
    if ( by == 'dept') { apiPath = "/api/v1/tickets/count?department=" + id; }
    if ( by == 'issue') { apiPath = "/api/v1/issues/count?id=" + id; }
    if ( by == 'workflow') { apiPath = "/api/v1/tickets/count?workflow_id=" + id; }

    return new Promise((resolve, reject) => {
        var newUrl = url + apiPath;
        fetch(newUrl)
          .then((response) => response.json())
          .then((data) => {
            console.log(data.count);
            if (data && 'count' in data) {
              resolve(data.count);
            } else {
              console.error('Unexpected response format:', data);
              reject(new Error('Unexpected response format'));
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            reject(error);
          });
      });
}
