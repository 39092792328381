import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../../AlertCustom.js';
// Crud Happiness
import { deleteId } from '../../../services/delete';
import { putTags } from '../../../services/put';
// HELPERS
import { removeHashtag, validateClean} from '../../../helpers/validate';


// COLOR PICKER DATA *****************************************************************************************************************************************

// Checkmark and Colors imported
import DoneIcon from '@mui/icons-material/Done';
import { 
  red, pink, purple, deepPurple, 
  indigo, blue, lightBlue, cyan, 
  teal, green, lightGreen, lime, 
  yellow, amber, orange, deepOrange 
} from '@mui/material/colors';

// Color Vars
const redc = red[400];
const pinkc = pink[400];
const purplec = purple[400];
const deepPurplec = deepPurple[400];
const indigoc = indigo[400];
const bluec = blue[400];
const lightBluec = lightBlue[400];
const cyanc = cyan[400];
const tealc = teal[400];
const greenc = green[400];
const lightGreenc = lightGreen[400];
const limec = lime[400];
const yellowc = yellow[400];
const amberc = amber[400];
const orangec = orange[400];
const deepOrangec = deepOrange[400];


// TAG ROW LOOP ************************************************************************************************************************************************

// array of tags
const TagRow = ({ tagrow, onSave, handleUpdateFromComp}) => {
  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState(tagrow.tag);
  const [tagcolor, setTagColor] = useState("#" + String(tagrow.color));
  const [tagtxtcolor, setTagTxtColor] = useState(tagrow.txtcolor);

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  
  // ACTIONS ************************************************************************************************************************************************

  //  When reset of states is needed
  const reset = () => {
    setTag(tagrow.tag);
    setTagColor("#" + String(tagrow.color));
    setTagTxtColor(tagrow.txtcolor);
    handleClose();
  };

  // open and close state of dialog popup
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  
  // when custom email changes: 'tags.tag' field (state = tag)
  const handleTagChange = (event) => { setTag(event.target.value); };

  // when a color is clicked in tag edit screen 'tags.color' field (state = tagcolor)
  function handleColorClick(color) { setTagColor(color); };

  // when a color is clicked in tag edit screen 'tags.txtcolor' field (state = tagtxtcolor)
  function handleTxtColorClick(color) { setTagTxtColor(color); };

  // SAVE FUNCTION: SENDS TO BASE SAVE FUNCTION **********************************************************************************************************************************

  const handleSave = () => {
    //console.log("tagcolor92 = " + tagcolor);
    if (
      validateClean(tag)
    ) {
      var tagcolorfinal = removeHashtag(tagcolor);
      //console.log("tagcolorfinal98 = " + tagcolorfinal);
      onSave(tagrow.id, tag, tagcolorfinal, tagtxtcolor);
      handleClose();
    } else {
      setAlertInfo(["invalidText","error","Error","Enter only letters or numbers"]);
      setAlertCustom(true);
    }
  };


  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>
        
      <Chip label={tag} color="error" size="small" onClick={handleClickOpen} 
      sx={{ backgroundColor: tagcolor, color: tagtxtcolor, ":hover":{ backgroundColor:tagcolor, opacity:0.8 } }} />
              
       {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

      {/* DIALOG BOX */}
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Edit Tag</DialogTitle>
        <DialogContent sx={{ ml: "50px", mr: "20px"}}>
          <Chip label={tag} size="small" sx={{ backgroundColor: tagcolor, color: tagtxtcolor }}/>
          <br /><br />
          <TextField
            autoFocus
            margin="dense"
            id="edittag"
            onChange={handleTagChange}
            helperText="Text: Numbers or letters only"
            value={tag} // Set the value to the portion before '@'
            fullWidth
            variant="standard"
          />
          <br /><br />
          <Typography variant="caption" sx={{ fontSize: 15}}>
            Edit color
          </Typography>
          <br />
          <>
    <ButtonGroup variant="outlined" sx={{backgroundColor:"black"}}>
      <Button onClick={() => handleColorClick(redc)} sx={{backgroundColor:redc, ":hover":{ backgroundColor:redc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {tagcolor === redc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(pinkc)} sx={{backgroundColor:pinkc, ":hover":{ backgroundColor:pinkc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === pinkc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(purplec)} sx={{backgroundColor:purplec, ":hover":{ backgroundColor:purplec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === purplec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(deepPurplec)} sx={{backgroundColor:deepPurplec, ":hover":{ backgroundColor:deepPurplec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === deepPurplec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined" sx={{backgroundColor:"black"}}>
      <Button onClick={() => handleColorClick(indigoc)} sx={{backgroundColor:indigoc, ":hover":{ backgroundColor:indigoc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === indigoc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(bluec)} sx={{backgroundColor:bluec, ":hover":{ backgroundColor:bluec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === bluec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(lightBluec)} sx={{backgroundColor:lightBluec, ":hover":{ backgroundColor:lightBluec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === lightBluec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(cyanc)} sx={{backgroundColor:cyanc, ":hover":{ backgroundColor:cyanc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === cyanc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined" sx={{backgroundColor:"black"}}>
      <Button onClick={() => handleColorClick(tealc)} sx={{backgroundColor:tealc, ":hover":{ backgroundColor:tealc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === tealc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(greenc)} sx={{backgroundColor:greenc, ":hover":{ backgroundColor:greenc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === greenc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(lightGreenc)} sx={{backgroundColor:lightGreenc, ":hover":{ backgroundColor:lightGreenc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === lightGreenc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(limec)} sx={{backgroundColor:limec, ":hover":{ backgroundColor:limec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === limec && ( <DoneIcon sx={{ color: "black", fontSize: 20 }}/> )} &nbsp;
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined" sx={{backgroundColor:"black"}}>
      <Button onClick={() => handleColorClick(yellowc)} sx={{backgroundColor:yellowc, ":hover":{ backgroundColor:yellowc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === yellowc && ( <DoneIcon sx={{ color: "black", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(amberc)} sx={{backgroundColor:amberc, ":hover":{ backgroundColor:amberc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === amberc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(orangec)} sx={{backgroundColor:orangec, ":hover":{ backgroundColor:orangec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === orangec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(deepOrangec)} sx={{backgroundColor:deepOrangec, ":hover":{ backgroundColor:deepOrangec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === deepOrangec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
    </ButtonGroup>

    <br /><br />
    <Typography variant="caption" sx={{ fontSize: 15}}>
      Edit text color
    </Typography>
    <br />

    <ButtonGroup variant="outlined" sx={{backgroundColor:"black"}}>
      <Button onClick={() => handleTxtColorClick("white")} sx={{backgroundColor:"white", ":hover":{ backgroundColor:"white", opacity:0.8 }, borderRadius:0, border:"1px solid black", width:"25px" }}>
        {tagtxtcolor === "white" && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleTxtColorClick("black")} sx={{backgroundColor:"black", ":hover":{ backgroundColor:"black", opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagtxtcolor === "black" && ( <DoneIcon sx={{ color: "black", fontSize: 20 }}/> )} &nbsp;
      </Button>
    </ButtonGroup>

    </>
        </DialogContent>
        <DialogActions>
          <Button onClick={reset}>Cancel</Button>
          <Button onClick={() => deleteId(tagrow.id, "tag", "/api/admin/tags", setAlertCustom, setAlertInfo, handleClose, reset, handleUpdateFromComp)}>Delete</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    
    &nbsp; &nbsp;
    </>
  );
};


// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function GeneralTags({ tags, handleUpdateFromComp }) {

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };


  // BASE SAVE FUNCTION  **********************************************************************************************************************************

  const handleSave = (id, tag, tagcolor, tagtxtcolor, setAlertCustom, setAlertInfo, handleUpdateFromComp) => {
    //console.log(id, tag, tagcolor, tagtxtcolor);
    putTags(id, tag, tagcolor, tagtxtcolor, setAlertCustom, setAlertInfo);
  
  };

   
  // RETURN  **********************************************************************************************************************************
 
  return (
    <>
      
        {/* START EMAIL LOOP */}
        {tags.map((tagrow) => (
          <TagRow
            key={tagrow.id} 
            tagrow={tagrow} 
            onSave={handleSave}
            handleUpdateFromComp={handleUpdateFromComp}
          />
        ))}
        {/* END EMAIL LOOP */}

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

    </>
  );
}