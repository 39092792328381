import React, { useState } from "react";
import { Container, Typography, Link, Box, Divider, Snackbar, Alert, AlertTitle } from "@mui/material";
import styled from "@emotion/styled";
import LoginForm from "../components/LoginForm";
import Logo from "../components/Logo";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { red } from "@mui/material/colors";


// BUILD URL FOR LOGIN
const urllogin = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API + "/api/v1/login";

// some css stuff
const CenterLogin = styled("div")({
  height: "100vh",
  display: "grid",
  placeItems: "center",
  backgroundColor: "var(--bg-fill)",
});

const HeadingStyle = styled(Box)({
  textAlign: "center",
});

const ContentStyle = styled("div")({
  maxWidth: 480,
  padding: 25,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  color: "var(--headings-text)",
  backgroundColor: "var(--headings-fill)",
  accentColor: "var(--headings-text)",


});

const easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};


const Login = ({ setAuth }) => {
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate(); 
  const currentLocation = useLocation();
    
    // when form is submitted
    const handleSubmit = async (formData) => {
        const { email, password } = formData;
        
        try {
          const response = await fetch(urllogin, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ 
                "email": email,
                "password": password 
            }),
          });

          if (response.ok) {
            // Request was successful
            const responseData = await response.json();
            
            //get sessID back from response
            const sessID = responseData.sessID;
            const isAgent = [1, 2, 3, 4, 5].includes(responseData.role);
           
            setAuth(true);
            if (isAgent) {
              Cookies.set("agent_session", sessID, { expires: 7 });
              Cookies.set("agent_email", email, { expires: 7 });
              
            } else {
              Cookies.set("rikr_session", sessID, { expires: 7 });
              Cookies.set("rikr_email", email, { expires: 7 });
            }
            //console.log("isAgent",isAgent);
            //console.log("sessID",sessID);
    
            navigate("/", { state: { from: currentLocation }, replace: true });


          } else {
              // Request failed
              const errorData = await response.json();
              setShowAlert(true);
              //console.error(errorData);
          }
        } catch (error) {
          // Request error
          console.error("Error during API request:", error);
        }
      };

      const handleCloseAlert = () => {
        setShowAlert(false);
      };

  return (
      <CenterLogin >
      <Container maxWidth="sm">
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUp}>

            <Logo />
            <Divider />
            <Typography sx={{ mb: 5 }}>
              Login to your account
            </Typography>
          </HeadingStyle>

          {/*
           </ContentStyle>Box component={motion.div} {...fadeInUp}>
            <SocialAuth />
          </Box>

          <Divider sx={{ my: 3 }} component={motion.div} {...fadeInUp}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              OR
            </Typography>
          </Divider>
          */}

        <LoginForm handleSubmit={handleSubmit} />

          {/*<Typography
            component={motion.p}
            {...fadeInUp}
            variant="body2"
            align="center"
            sx={{ mt: 3 }}
          >
            Don’t have an account?{" "}
            <Link variant="subtitle2" component={RouterLink} to="/signup">
              Sign up
            </Link>
          </Typography>*/}

           {/* Display the alert when showAlert is true */}
           <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}

          >
            <Alert 
             onClose={handleCloseAlert} 
             severity="error" 
             sx={{ width: '100%' }}
             >
            <AlertTitle>Error</AlertTitle>
              Invalid email or password.
            </Alert>
          </Snackbar>
          
        </ContentStyle>
      </Container>
      </CenterLogin>
  );
};

export default Login;