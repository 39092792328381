// Base API url for Admin -> General
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
// Set vars
var newUrl = "";

// PATCH TicketIssues ****************************************************************************************************************************
export async function patchTicketIssues(oldIssue, newIssue, onSaveSuccess, onSaveError) {
    var apiPath = "/api/v1/issues/" + oldIssue;
    newUrl = url + apiPath;
    try {
      fetch(newUrl, {
        method: "PATCH",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({
          update_id: newIssue,
        }),
      })
        .then((response) => {
          if (response.ok) {
            onSaveSuccess("tickets");
          } else {
            // Request failed
            onSaveError("Request failed");
          }
        })
        .catch((error) => {
          // Request error
          console.error("Error during API request:", error);
          onSaveError(error);
        });
    } catch (error) {
      // Request error
      console.error("Error during API request:", error);
      onSaveError(error);
    }
  };

  // PATCH TicketWorkflows ****************************************************************************************************************************
export async function patchTicketWorkflows(oldWF, newWF, onSaveSuccess, onSaveError) {
  var apiPath = "/api/v1/workflows/" + oldWF;
  newUrl = url + apiPath;
  try {
    fetch(newUrl, {
      method: "PATCH",
      headers: {
      "Content-Type": "application/json",
      },
      body: JSON.stringify({
        update_id: newWF,
      }),
    })
      .then((response) => {
        if (response.ok) {
          onSaveSuccess("tickets");
        } else {
          // Request failed
          onSaveError("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        onSaveError(error);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    onSaveError(error);
  }
};