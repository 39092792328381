import React, { useState } from "react";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";

const TicketSingleReply = (ticketInfo) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  return (
    <span className="App" >
      <Editor
        defaultEditorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
      <span className="code-view">
        <textarea
          className="text-area"
          disabled
          hidden
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />
      </span>
    </span>
  );
};

export default TicketSingleReply;