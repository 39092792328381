import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Icons
import InsertCommentIcon from '@mui/icons-material/InsertComment';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../../AlertCustom.js';
// Crud Happiness
import { deleteId } from '../../../services/delete';
import { putEmails } from '../../../services/put';
// HELPERS
import { validateClean } from '../../../helpers/validate';
import { getDisplayName } from '../../../helpers/getDisplayName';


// CANNED REPLIES ROW LOOP ************************************************************************************************************************************************

// array of canned replies
const ReplyRow = ({ replyrow, onSave, agentId, handleUpdateFromComp, isLastItem}) => {
  const [open, setOpen] = useState(false);
  const [rname, setRName] = useState(replyrow.name);
  const [rmessage, setRMessage] = useState(replyrow.message);
  const [ragent, setRAgent] = useState(replyrow.human_last_updated);
  const [ragentdisplay, setRAgentDisplay] = useState(null);

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  // get display name of last editor
  getDisplayName(null,replyrow.human_last_updated)
  .then((dataid) => {
    setRAgentDisplay(dataid);
  })
  
  // ACTIONS ************************************************************************************************************************************************

   //  When reset of states is needed
  const reset = () => {
    setRName(replyrow.name);
    setRMessage(replyrow.message);
    setRAgent(replyrow.human_last_updated);
    handleClose();
  };

  // open and close state of dialog popup
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  
  // When 'name' field (rname) changes
  const handleNameChange = (event) => { setRName(event.target.value); setRAgent(agentId); };
  // When message changes: 'message' field 
  const handleMessageChange = (event) => { setRMessage(event.target.value); setRAgent(agentId); };


  // SAVE FUNCTION: SENDS TO BASE SAVE FUNCTION **********************************************************************************************************************************

  const handleSave = () => {

    if (validateClean(rname)) {

      onSave(replyrow.id, rname, rmessage, ragent);
      handleClose();

    } else { 
      setAlertInfo(["invalidText","error","Error","Enter only letters and numbers for the canned reply name"]);
      setAlertCustom(true); 
    }

  };
  

  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>
      <ListItemButton onClick={handleClickOpen} sx={{mt:-1, mb:-1}}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "var(--icon-avatar)", width: 28, height: 28 }}>
              <InsertCommentIcon sx={{ width: 20, height: 20 }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={rname}
            secondary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                Last Edited by {ragentdisplay}
              </Typography>
            }
          />
        </ListItem>
      </ListItemButton>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

      {/* DIALOG BOX */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Canned Reply</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="rname"
            label="Name"
            onChange={handleNameChange}
            helperText="Only letters and numbers"
            value={rname} 
            fullWidth
            variant="standard"
          />
          <br /><br />
          <TextField
              margin="dense"
              id="rmessage"
              label="Canned Message"
              helperText=""
              value={rmessage}
              onChange={handleMessageChange}
              fullWidth variant="outlined"
              multiline
              rows={4}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={reset}>Cancel</Button>
          <Button onClick={() => deleteId(replyrow.id, "email", "/api/admin/general", setAlertCustom, setAlertInfo, handleClose, reset, handleUpdateFromComp)}>Delete</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Divider */}
      {!isLastItem && <Divider variant="inset" />}
    </>
  );
};


// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function GeneralReplies({ replies, agentId, handleUpdateFromComp }) {
  
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };


  // BASE SAVE FUNCTION  **********************************************************************************************************************************

  
  const handleSave = (id, rname, rmessage, agentId, setAlertCustom, setAlertInfo, handleUpdateFromComp ) => {
    putEmails(id, rname, rmessage, agentId, setAlertCustom, setAlertInfo);
  };


  // RETURN  **********************************************************************************************************************************
 
  return (
    <>
      <List sx={{ width: '100%', maxWidth: 350, bgcolor: 'background.paper' }}>
        {/* START EMAIL LOOP */}
        {replies.map((replyrow, index) => (
          <ReplyRow
            key={replyrow.id} 
            replyrow={replyrow} 
            onSave={handleSave}
            agentId={agentId}
            handleUpdateFromComp={handleUpdateFromComp}
            isLastItem={index === replies.length - 1} // Check if it's the last item
          />
        ))}
        {/* END EMAIL LOOP */}
      </List>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

    
    </>
  );
}