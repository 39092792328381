import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from "styled-components";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';

// FOR THEME
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

//Cards
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

//Icons
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { relativeTimeRounding } from 'moment';

// Helpers
import { formatDistanceToNow } from 'date-fns';

// DIALOG TRANSITION FOR TICKET OPENING
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// TABLE BOARD FUNCTION **************************************************************************************************************
export default function TicketBoard({ apiUrl, agentId, selectedDept, reSize }) {

  
  // STATE FOR OPEN & CLOSE OF DIALOG FOR SINGLE TICKET
  const [open, setOpen] = React.useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const handleClickOpen = (ticket) => {
    setSelectedTicket(ticket);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // GET TICKETS
  const [tickets, setTickets] = useState([]);
  useEffect(() => {
    const callRestApi = async () => {
      try {
        const response = await fetch(apiUrl);
        const jsonResponse = await response.json();
        setTickets(jsonResponse.data);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    callRestApi();
  }, [apiUrl]);

  const filteredTickets = selectedDept
    ? tickets.filter((ticket) => ticket.department === selectedDept)
    : tickets;

  //callback flipflop to resize window
  useEffect(() => {
    reSize("TB");
  }, [filteredTickets]);

  const getDraggableStyle = (isDragging) => ({
      // styles for when the item is not being dragged
      ...(!isDragging && {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '6px',
      }),
      // styles for when the item is being dragged
      ...(isDragging && {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        left: '0px',
        borderRadius: 6,
      }),
    });

  const droppableStyle = {
      backgroundColor: 'grey', 
      padding: 1,
      backgroundColor: 'lightblue',
      borderRadius: '6px',
      width: '100%', 
  };

  const onDragEnd = (result) => {
      const { source, destination } = result;
    
      // If there is no destination (item was dropped outside the list), return
      if (!destination) {
        return;
      }
    
      // If the item is dropped in the same place, do nothing
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }
    
      // Create a new array with the reordered items
      const reorderedTickets = Array.from(filteredTickets);
      const [removed] = reorderedTickets.splice(source.index, 1);
      reorderedTickets.splice(destination.index, 0, removed);
    
      // Update the tickets state
      setTickets(reorderedTickets);
  };

  return (
    <>
    <Card sx={{ 
      width: '100%',
      borderRadius: 'var(--box-border-radius)', 
      boxShadow: 4, color: 'var(--headings-text)'}}
    >
    <CardHeader className="DragByThis" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SupportAgentIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ marginLeft: '8px' }}>My tickets</Typography>
              </div>
            }
    />
    <DragDropContext onDragEnd={onDragEnd}>
      <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
        <Droppable droppableId="ticketCards">
          {(provided, snapshot) => (
          <ul className="ticketCards" {...provided.droppableProps} 
            ref={provided.innerRef} 
            style={droppableStyle}>

            {filteredTickets.map((ticket, index) => {
            return (
              <Draggable key={ticket.id} draggableId={(String(ticket.id))} index={index}>
              {(provided, snapshot) => (
              <li 
                ref={provided.innerRef} 
                {...provided.draggableProps} 
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                  ...getDraggableStyle(snapshot.isDragging)
                }}
              >
              <Card sx={{ margin: '2px', width: '100%'}}>
                <CardHeader
                  avatar={
                   <Avatar>
                      JA
                   </Avatar>
                  }
                  action={
                    <IconButton aria-label="open">
                      <OpenInBrowserIcon sx={{ transform: 'rotate(90deg)', color: 'var(--ticket-tab-fill)', width: 30, height: 30 }}/>
                    </IconButton>
                  }
                  title={ticket.id + " " + ticket.subject}
                   subheader={ticket.client_first_name + " " + ticket.client_last_name}
                />
            
                <Box
                  sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between"
                  }}
                  >
                  <Typography variant="overline" display="block" gutterBottom>
                    {ticket.department}: {ticket.workflow} {formatDistanceToNow(ticket.date_updated, { addSuffix: true })}
                    </Typography>
                  <Tooltip title={ticket.agent_display_name} placement="right-start">
                    <Avatar src={process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_FRONTEND + "/" + process.env.REACT_APP_AVATARS + "/avatars/avatar" + String(ticket.agent_id) + ".png"} sx={{ width: 24, height: 24 }} />
                  </Tooltip>
                </Box>
                
              </Card>
            </li>
            )}
            </Draggable>
          );
        })}
        {provided.placeholder}
      </ul>
      )}
      </Droppable>
      </CardContent>
    </DragDropContext>
    </Card>

    </>
    
    
  );
}
