import React, { useState, useEffect, useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Icons
import AirIcon from '@mui/icons-material/Air';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../../AlertCustom.js';
// Crud Happiness
import { deleteId } from '../../../services/delete';
import { deleteAsked } from '../../../services/delete';
import { putWorkflows } from '../../../services/put';
// HELPERS
import { validateClean} from '../../../helpers/validate';
import {getCountTickets} from '../../../helpers/getCount';


// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function GeneralWorkflows({ workflows, onSaveSuccess, onSaveError  }) {

const [openDialogId, setOpenDialogId] = useState(null); // null means no dialog open
const [workflowId, setWorkflowId] = useState("");
const [workflow, setWorkflow] = useState("");
const [workflowDefault, setWorkflowDefault] = useState(false);

// Alerts
const [alertCustom, setAlertCustom] = useState(false);
const [alertInfo, setAlertInfo] = useState(["","info","",""]);
const handleCloseAlert = () => { setAlertCustom(false); };

// Delete a workflow constants
const [openDelConfirm, setOpenDelConfirm] = useState(false);
const handleCloseDelConfirm = () => { setOpenDelConfirm(false); };
const [countIdForDel, setCountIdForDel] = useState("");
const [goWhereWorkflow, setGoWhereWorkflow] = useState("");

  
  // ACTIONS ************************************************************************************************************************************************

  // set states of particular workflow when opened
  const handleClickOpen = (wfrow) => { 
    setWorkflowId(wfrow.id);
    setWorkflow(wfrow.name);
    setWorkflowDefault(Boolean(wfrow.is_default));
    setOpenDialogId(wfrow.id); 
  };
  const handleClose = () => { setOpenDialogId(null); };

  function isLastItem(index,length) {
    if (index === length - 1) { 
      return true;
    } else {
      return false;
    }
  }
  

  // SAVE FUNCTION:  **********************************************************************************************************************************

  const handleSave = () => {
    if (
      validateClean(workflow)
    ) {
      putWorkflows(workflowId, workflow, workflowDefault, onSaveSuccess, onSaveError);
      handleClose();
    } else {
      setAlertInfo(["invalidText","error","Error","Enter only letters or numbers"]);
      setAlertCustom(true);
    }
  };
  

  // DEL FUNCTION ********************************************************

  const handleDelete = (id) => {
    // ask what workflow to change tickets to
    getCountTickets(workflowId,"workflow")
    .then((ticketCount) => {
      setCountIdForDel(ticketCount);
    })
    .catch((error) => { console.error("Error fetching ticket count:", error); });
    setOpenDialogId(null);
    setOpenDelConfirm(true);
  }
  
  const handleDeleteWhenDefault = () => {
    setOpenDialogId(null);
    setAlertInfo(["This is the default workflow","error","Error","Before you can delete this workflow, you must set a replacment as default."]);
    setAlertCustom(true);
  }

  const handleChangeDefault = () => {
    setOpenDialogId(null);
    setAlertInfo(["This is the default workflow","error","Error","Change the default workflow by enabling the replacement as default"]);
    setAlertCustom(true);
  }

  const handleConfirmDelClick = () => {
    if (countIdForDel == 0) {
      deleteAsked(workflowId, workflow, "workflow", "/api/v1/workflows", "notickets", "", onSaveSuccess, onSaveError);
      setOpenDelConfirm(false);
    } else if (countIdForDel > 0 && goWhereWorkflow) {
      deleteAsked(workflowId, workflow, "workflow", "/api/v1/workflows", goWhereWorkflow, "workflow",  onSaveSuccess, onSaveError);
      setOpenDelConfirm(false);
    } else if (countIdForDel > 0 && !goWhereWorkflow) {
      setOpenDelConfirm(false);
      setOpenDialogId(workflowId);
      setAlertInfo(["invalidText","error","Error","Workflow replacment required"]);
      setAlertCustom(true);
    }
  }

  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>

    {/* START WORKFLOW LOOP */}
    {workflows.map((wfrow, index) => (

      <React.Fragment key={wfrow.id}>

      <ListItemButton onClick={() => handleClickOpen(wfrow)} onMouseDown={e => e.stopPropagation()} sx={{mt:0, mb:0}}>
        <ListItem key={wfrow.id}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "var(--icon-avatar)", width: 28, height: 28 }}>
              <AirIcon alt={wfrow.name} sx={{ width: 20, height: 20 }} />
            </Avatar>
          </ListItemAvatar>
          
          {wfrow.is_default == true ? (
              <ListItemText>
              <>
                <div className="space-between">
                  <span>{wfrow.name}</span>
                  <div className="icon-text-group">
                    <CheckCircleIcon sx={{ fontSize: 20, color: "green"}} />
                    <span className="dang-space"></span>
                    <Typography sx={{ color: "green" }}>Default</Typography>
                  </div>
                </div>
              </>
            </ListItemText>
            
           ) : (
             <ListItemText
               primary={wfrow.name}>
             </ListItemText>
           )}

        </ListItem>
      </ListItemButton>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

      {/* DIALOG BOX */}
      <Dialog open={openDialogId === wfrow.id} onClose={handleClose} TransitionComponent={Zoom} sx={{ '& .MuiDialog-paper': { minWidth: 400 } }}>
        <DialogTitle>Edit Workflow</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="workflow"
            label="Workflow"
            onChange={(event) => {
              setWorkflow(event.target.value);
            }}
            helperText="Text: Numbers or letters only"
            value={workflow}
            fullWidth
            variant="standard"
          />
          <br />
          {wfrow.is_default == true ? (
            <FormControlLabel
              control={
                  <Switch
                    checked={workflowDefault}
                    color="warning"
                    disabled={true}
                    onClick={()=>handleChangeDefault}
                  />
              }
              label="Default"
            />
          ) : (
            <FormControlLabel
              control={
                  <Switch
                    checked={workflowDefault}
                    color="warning"
                    onChange={(event, newValue) => {
                      setWorkflowDefault(newValue);
                    }} 
                  />
              }
              label="Default"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          {wfrow.is_default == true ? (
            <Button onClick={() => handleDeleteWhenDefault()}>Delete</Button>
          ) : (
            <Button onClick={() => handleDelete(wfrow.id,false)}>Delete</Button>
          )}
          <Button onClick={() => handleSave()}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Divider */}
      {!isLastItem(index,workflows.length) && <Divider variant="inset" />}
      </React.Fragment>

    ))}
    
    {/* END WORKFLOW LOOP */}

   {/* DIALOG BOX FOR WF DELETE */}
    <Dialog open={openDelConfirm} onClose={handleCloseDelConfirm} TransitionComponent={Zoom} sx={{ '& .MuiDialog-paper': { minWidth: 500 } }}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={()=>handleCloseDelConfirm()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ ml: "50px", mr: "20px"}}>

          {countIdForDel > 0 && (
            <>
            <Typography variant="caption" sx={{ fontSize: 15, color: 'black'}}>
              There are currently {countIdForDel} ticket(s) assigned to the {workflow} workflow. Which workflow would you like these tickets assigned to?
            </Typography>
          
            <br /><br />
            <FormControl fullWidth>
              <InputLabel id="workflow-select-label">Workflow</InputLabel>
              <Select
                labelId="workflow-select-label"
                id="workflow-select"
                value={goWhereWorkflow}
                onChange={(event) => {
                  setGoWhereWorkflow(event.target.value);
                }}
                >
                {workflows.map((workflow) => (
                  workflow.id !== workflowId && (
                  <MenuItem key={workflow.id} value={workflow.id}>{workflow.name}</MenuItem>
                  )
                ))}
              </Select>
              <br />
              <Typography variant="caption" sx={{ fontSize: 15, color: 'red'}}>
                WARNING: THIS CAN NOT BE UNDONE!
              </Typography>
            </FormControl>
            </>
          )}
          {countIdForDel == 0 && (
            <>
            <Typography variant="caption" sx={{ fontSize: 15, color: 'black'}}>
              There are currently 0 tickets assigned to the {workflow} workflow. Please confirm you would like to delete this workflow. 
            </Typography>
            <br /><br />
            <Typography variant="caption" sx={{ fontSize: 15, color: 'red'}}>
                WARNING: THIS CAN NOT BE UNDONE!
            </Typography>      
            <br /><br />
            </>
          )} 
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDelConfirm()}>Cancel</Button>
          <Button onClick={() => handleConfirmDelClick()}>Confirm</Button>
        </DialogActions>
    </Dialog>

    </>
  );
}