import React, { useState } from 'react';
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Avatar from '@mui/material/Avatar';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// ICONS & LOGO
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import logo from '../static/images/rikrlogo.png';
// Crud Happiness
import { putAgents } from '../services/put';
// HELPERS
import { validateClean, validateCleanSpacesOK, validatePass, validateNumbers} from '../helpers/validate';
import moment from 'moment-timezone';
// avatar upload items
import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'


{/* SEARCH */}
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

{/* SEARCH ICON */}
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function HeaderAppBar( agentProfile ) {
  agentProfile = agentProfile.agentProfile;

  //for logout
  const navigate = useNavigate(); 
  const currentLocation = useLocation();

  const timezoneArray = moment.tz.names();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState(agentProfile.display_name);

  // not using if agentrow.icon exists (not null or undefined), then use it, otherwise use an empty string
  const [profilePic, setProfilePic] = useState(agentProfile.icon ? agentProfile.icon : false);

  // for updating image locally after upload
  const [avatarUrl, setAvatarUrl] = useState(null);
  // profile pic data for upload to backend
  const [profilePicData, setProfilePicData] = useState(null);
  // if agentrow.phone exists (not null or undefined), then use it, otherwise use an empty string
  const [phone, setPhone] = useState(agentProfile.phone ? agentProfile.phone : "");
  // if agentrow.tz exists (not null or undefined), then use it, otherwise use UTC
  const [timezone, setTimeZone] = useState(agentProfile.tz ? agentProfile.tz : "");

  //random number for image refresh if profile image is updated
  let rando = (Math.random() + 1).toString(36).substring(7);


  if ( agentProfile.display_name && !displayName) { setDisplayName(agentProfile.display_name); }
  if ( agentProfile.profilePic && !profilePic) { setProfilePic(agentProfile.icon); }
  if ( agentProfile.id && !avatarUrl) { setAvatarUrl(process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_FRONTEND + "/" + process.env.REACT_APP_AVATARS + "/avatars/avatar" + String(agentProfile.id) + ".png"); } 
  if ( agentProfile.phone && !phone) { setPhone(agentProfile.phone); } 
  if ( agentProfile.tz && !timezone) { setTimeZone(agentProfile.tz); } 

  
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  //  When reset of states is needed
    const reset = () => {
      setPassword("");
      setChangePass(false);
      setDisplayName(agentProfile.display_name);
      setProfilePic(agentProfile.icon ? agentProfile.icon : false);
      setPhone(agentProfile.phone ? agentProfile.phone : "");
      setTimeZone(agentProfile.tz ? agentProfile.tz : "");
      handleMenuClose();
    };

    // AGENT: password changes: 'humans_staff.email' (password)
    const handlePasswordChange = (event) => { setPassword(event.target.value); };
    // AGENT: displayName changes: 'humans_staff.email' (displayName)
    const handleDisplayNameChange = (event) => { setDisplayName(event.target.value); };
    // AGENT: phone changes: 'humans_staff.email' (phone)
    const handlePhoneChange = (event) => { setPhone(event.target.value); };
    // AGENT: timezone changes: 'humans_staff.email' (timezone)
    const handleTimeZoneChange = (event) => { setTimeZone(event.target.value); };
    // EMAIL: Change pass: 
    const handleSwitchChangePass = (event) => { setChangePass(event.target.checked); };


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLogOut = () => {
    // unset the cookies
    Cookies.set("agent_session", "", { expires: -1 });
    Cookies.set("agent_email", "", { expires: -1 });
    navigate("/login", { state: { from: currentLocation }, replace: true });
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    setProfileDialogOpen(false);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfileDialogOpen = () => {
    setProfileDialogOpen(true);
  };
  
  const handleProfileDialogClose = () => {
    setProfileDialogOpen(false);
  };

  // AVATAR FUNCTIONS
  const handleAvatarUpload = (collected) => {
      setProfilePic(true);
      setProfilePicData(collected['file']);
      
  }
  const handleAvatarRemove = () => {
    setProfilePicData(null);
    setProfilePic(agentProfile.icon ? agentProfile.icon : false);
  }

  // ***************************************************************************** SAVE *****************************************************************
  const handleSave = () => {

    //console.log(profilePicData);

    // vars for checks
    var passisago = false;
    var displayisago = false;
    var phoneisago = false;

    // validate pass is acceptable if we are changing it
    if ( password) {
        if (validatePass(password)) { passisago = true; 
        } else { setAlertInfo(["invalidPass","error","Error","Password must be at least 9 characters long & may only contain letters, numbers and $!@%.? "]); setAlertCustom(true); passisago = false; }
    } else {
        passisago = true;
    }
 
    // validate display name is acceptable
    if (validateClean(displayName)) { displayisago = true; 
      } else { setAlertInfo(["invalidDisplayName","error","Error","Display Name may only contain letters, numbers, dashes or underscores."]); setAlertCustom(true); displayisago = false; }

    // validate phone has only numbers or dashes
    if (phone) {
      if (validateNumbers(phone)) { phoneisago = true; 
      } else { setAlertInfo(["invalidPhone","error","Error","Phone number should only contain numbers or dashes"]); setAlertCustom(true); phoneisago = false; }
    } else {
      phoneisago = true;
    }

    // Good to save if all is valid
    if (
      passisago && displayisago && phoneisago
    ) {
      putAgents(agentProfile.id, agentProfile.email, password, agentProfile.first_name, agentProfile.last_name, displayName, phone, timezone, profilePic, profilePicData, setAlertCustom, setAlertInfo);
      setAvatarUrl(process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_FRONTEND + "/" + process.env.REACT_APP_AVATARS + "/avatars/avatar" + String(agentProfile.id) + ".png?rando=" + rando);
      handleMenuClose();
    } else {
      console.log("error");
 }
  };

  {/* AVATAR MENU TOP RIGHT */}
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileDialogOpen}>Profile</MenuItem>
      <MenuItem onClick={handleLogOut}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, marginLeft: '1px', marginRight: '1px', }}>
      <AppBar position="static" sx={{ 
        backgroundColor: 'var(--headings-fill)',
        borderRadius: 'var(--box-border-radius)',
        boxShadow: 4,
        }} >
        <Toolbar>

           {/* LOGO TOP LEFT */}
           <img src= {logo} alt="logo" style={{ maxHeight: 40}} />

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search'}}
            />
          </Search>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>*/}

            <IconButton size="large" aria-label="show 17 new notifications" color="inherit" >
              <Badge badgeContent={17} color="warning"><NotificationsIcon /></Badge>
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar alt={displayName} src={avatarUrl} sx={{ width: 25, height: 25 }} 
                style={{ border: '0.5px solid lightgray' }}/>
            </IconButton>

          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>

            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>

          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      
      {/* DIALOG BOX FOR EDITING PROFILE *********************************************** */}
      <Dialog open={profileDialogOpen} onClose={handleProfileDialogClose}>
        <DialogTitle>Profile Information</DialogTitle>
        <DialogContent>

      <Avatar alt={displayName} src={avatarUrl} sx={{ width: 56, height: 56 }} />
      

      <ImageUploader
        withIcon={true}
        buttonText="Choose avatar image"
        onFileAdded={(img) => handleAvatarUpload(img)}
        onFileRemoved={() => handleAvatarRemove()}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}
      />


        <TextField
            margin="dense"
            id="email"
            label="Email Address"
            value={agentProfile.email} 
            fullWidth
            variant="standard"
            helperText = "Email can only be changed in the RikrDesk Admin"
            disabled
          />
            <br />
            <TextField
              margin="dense"
              id="firstName"
              label="First Name"
              type="text"
              value={agentProfile.first_name}
              sx={{width: "50%"}}
              variant="filled"
              helperText = "Name can only be changed in the RikrDesk Admin"
              disabled
            />

            <TextField
              margin="dense"
              id="lastName"
              label="Last Name"
              type="text"
              value={agentProfile.last_name}
              sx={{width: "50%"}}
              variant="filled"
              disabled
            /><br />
            <FormControlLabel
            control={
              <Switch
                checked={changePass}
                color="warning"
                onChange={handleSwitchChangePass}
              />
            }
            label="Change Password"
          />
          {changePass && (
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              variant="standard"
            />
          )}
          
          <br /><br />
            <TextField
              margin="dense"
              id="displayName"
              label="Display Name"
              type="text"
              helperText="Only letters, numbers and hyphens allowed"
              value={displayName}
              onChange={handleDisplayNameChange}
              fullWidth
              variant="outlined"
            />

            <TextField
              margin="dense"
              id="phone"
              label="Phone"
              type="phone"
              value={phone}
              onChange={handlePhoneChange}
              fullWidth
              variant="filled"
            />

            <FormControl sx={{ mt: 3, width: 300 }}>
              <InputLabel id="timezone">Timezone</InputLabel>
              <Select
                labelId="timezone"
                id="timezone"
                value={timezone}
                onChange={handleTimeZoneChange}
                input={<OutlinedInput label="Timezone" />}
                >
                {timezoneArray.map((tz) => (
                  <MenuItem
                    key={tz}
                    value={tz}
                    >
                    {tz}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

        </DialogContent>
        <DialogActions>
           <Button onClick={reset}>Cancel</Button>
           <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>


    </Box>
  );
}
