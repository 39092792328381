import React, { useState, useEffect, useCallback } from 'react';
import { Snackbar, Alert, AlertTitle } from "@mui/material";

export default function AlertCustom({alertCustom, handleCloseAlert, severity, header, alertInfo}) {
    //alertName = alertName + String(Math.floor(Math.random() * 1000));
    var alertName = alertInfo[0];
    var severity = alertInfo[1];
    var header = alertInfo[2];
    var message = alertInfo[3];

    //console.log(alertCustom);
    //console.log(alertName);

    return (
        <Snackbar open={alertCustom} name={alertName} autoHideDuration={5000} onClose={handleCloseAlert} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
                <AlertTitle>{header}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );

}