import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Icons
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../../AlertCustom.js';
// Crud Happiness
import { deleteId } from '../../../services/delete';
import { putSmtp } from '../../../services/put';
// HELPERS
import { validateHost, validateTextEmail} from '../../../helpers/validate';


// EMAIL ROW LOOP ************************************************************************************************************************************************

// array of emails
const SMTPRow = ({ smtprow, onSave, handleUpdateFromComp, isLastItem}) => {
  const [open, setOpen] = useState(false);

  // Vars for SMTP Providers
  const [smtpHost, setSmtpHost] = useState(smtprow.value);
  const [smtpEnabled, setSmtpEnabled] = useState(!!parseInt(smtprow.enabled));
  const [smtpConnectString, setSmtpConnectString] = useState(smtprow.extra);
  const extraArray = smtpConnectString.split(":");
  const [smtpPort, setSmtpPort] = useState(extraArray[0]);
  const [smtpType, setSmtpType] = useState(extraArray[1]);
  const [smtpCertValidation, setSmtpCertValidation] = useState(Boolean(extraArray[2]));
  const [smtpUser, setSmtpUser] = useState(extraArray[3]);
  const [smtpPass, setSmtpPass] = useState(extraArray[4]);

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  
  // ACTIONS ************************************************************************************************************************************************

  //  When reset of states is needed
  const reset = () => {
    setSmtpHost(smtprow.value);
    setSmtpEnabled(!!parseInt(smtprow.enabled));
    setSmtpConnectString(smtprow.extra);
    const extraArray = smtpConnectString.split(":");
    setSmtpPort(extraArray[0]);
    setSmtpType(extraArray[1]);
    setSmtpCertValidation(Boolean(extraArray[2]));
    setSmtpUser(extraArray[3]);
    setSmtpPass(extraArray[4]);
    handleClose();
  };

  // open and close state of dialog popup
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  
  // SMTP: Host changed
  const handleSmtpHostChange = (event) => { setSmtpHost(event.target.value); };
  // SMTP: Port changed
  const handleSmtpPortChange = (event) => { 
    setSmtpPort(event.target.value); 
    setSmtpConnectString( String(event.target.value) + ":" + String(smtpType) + ":" + String(smtpCertValidation) + ":" + String(smtpUser) + ":" + String(smtpPass) ); 
  };
  // SMTP: Enable changed
  const handleSmtpEnabledChange = (event) => { setSmtpEnabled(event.target.checked); };

  // SMTP: Type changed
  const handleSmtpTypeChange = (event) => { 
    setSmtpType(event.target.value);
    setSmtpConnectString( String(smtpPort) + ":" + String(event.target.value) + ":" + String(smtpCertValidation) + ":" + String(smtpUser) + ":" + String(smtpPass) ); 
  };
  // SMTP: Cert Validation changed
  const handleSmtpCertValidationChange = (event) => { 
    setSmtpCertValidation(event.target.checked);
    setSmtpConnectString( String(smtpPort) + ":" + String(smtpType) + ":" + String(event.target.checked) + ":" + String(smtpUser) + ":" + String(smtpPass) );
  };
  // SMTP: User changed
  const handleSmtpUserChange = (event) => { 
    setSmtpUser(event.target.value); 
    setSmtpConnectString( String(smtpPort) + ":" + String(smtpType) + ":" + String(smtpCertValidation) + ":" + String(event.target.value) + ":" + String(smtpPass) );
  };
  // SMTP: Pass changed
  const handleSmtpPassChange = (event) => { 
    setSmtpPass(event.target.value);
    setSmtpConnectString( String(smtpPort) + ":" + String(smtpType) + ":" + String(smtpCertValidation) + ":" + String(smtpUser) + ":" + String(event.target.value) ); 
  };


  // SAVE FUNCTION: SENDS TO BASE SAVE FUNCTION **********************************************************************************************************************************

  const handleSave = () => {
    var hostisago = false;
    // only check custom email if enabled
    
      if (validateHost(smtpHost)) { 
        hostisago = true;
      } else { 
        setAlertInfo(["invalidEmail","error","Error","Host entered is invalid"]);
        setAlertCustom(true);
        hostisago = false; 
      }

    onSave(smtprow.id, smtpHost, smtpEnabled, smtpConnectString);
    handleClose();
    
  };
  

  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>
      <ListItemButton onClick={handleClickOpen} sx={{mt:-1, mb:-1}}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "var(--icon-avatar)", width: 28, height: 28 }}>
              <ForwardToInboxIcon alt={smtpHost} sx={{ width: 20, height: 20 }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={smtpHost}
            secondary={
              <>
                {smtpEnabled && <Typography variant="button" display="block" sx={{color: "green"}}>ENABLED Port: {smtpPort}, {smtpType}</Typography>}
                {!smtpEnabled && <Typography variant="button" display="block" sx={{color: "red"}}>DISABLED Port: {smtpPort}, {smtpType}</Typography>}
                
              </>
            }
          />
        </ListItem>
      </ListItemButton>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

      {/* DIALOG BOX */}
      <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit SMTP Provider</DialogTitle>
            <DialogContent>
              <FormControlLabel control={<Switch checked={smtpEnabled} color="warning" onChange={handleSmtpEnabledChange} />} label="Enabled" />
              <TextField margin="dense" id="smtpHost" label="Host" type="text" value={smtpHost} onChange={handleSmtpHostChange} fullWidth variant="standard" />
              <TextField margin="dense" id="smtpPort" label="Port" type="text" value={smtpPort} onChange={handleSmtpPortChange} fullWidth variant="standard" />
              
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="smtpType">Type</InputLabel>
                  <NativeSelect defaultValue={smtpType} onChange={handleSmtpTypeChange} inputProps={{name: 'smtpType', id: 'smtpType', }}>
                    <option value="TLS">&nbsp;TLS</option>
                    <option value="SSL">&nbsp;SSL</option>
                  </NativeSelect>
              </FormControl>

              <br /><br />
              <FormControlLabel control={<Switch checked={smtpCertValidation} color="warning" onChange={handleSmtpCertValidationChange} />} label="Cert Validation" />
              <TextField margin="dense" id="smtpUser" label="User" type="text" value={smtpUser} onChange={handleSmtpUserChange} fullWidth variant="standard" />
              <TextField margin="dense" id="smtpPass" label="Password" type="password" value={smtpPass} onChange={handleSmtpPassChange} fullWidth variant="standard" />
             </DialogContent>
            <DialogActions>
              <Button onClick={reset}>Cancel</Button>
              <Button onClick={()=>deleteId(smtprow.id, "smtp", "/api/admin/general", setAlertCustom, setAlertInfo, handleClose, reset, handleUpdateFromComp)}>DELETE</Button>
              <Button onClick={handleSave}>Save</Button>
            </DialogActions>
  
      </Dialog>

      {/* Divider */}
      {!isLastItem && <Divider variant="inset" />}
    </>
  );
};


// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function GeneralSMTP({ rdsmtps, handleUpdateFromComp }) {

  
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };


  // BASE SAVE FUNCTION  **********************************************************************************************************************************

  
  const handleSave = (id, smtpHost, smtpEnabled, smtpConnectString, setAlertCustom, setAlertInfo, handleUpdateFromComp ) => {
    putSmtp(id, smtpHost, smtpEnabled, smtpConnectString, setAlertCustom, setAlertInfo);
  };


  // RETURN  **********************************************************************************************************************************
 
  return (
    <>
      <List sx={{ width: '100%', maxWidth: 350, bgcolor: 'background.paper' }}>

        {/* START SMTP LOOP */}
        {rdsmtps.map((smtprow, index) => (
          <SMTPRow
            key={smtprow.id} 
            smtprow={smtprow} 
            onSave={handleSave}
            handleUpdateFromComp={handleUpdateFromComp}
            isLastItem={index === rdsmtps.length - 1} // Check if it's the last item
          />
        ))}
        {/* END EMAIL LOOP */}

      </List>

      {/* ALERT */}

      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

    
    </>
  );
}