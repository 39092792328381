// HELPERS
import { validateEmail, validateTextEmail, validateClean, validateNumbers, validatePass, removeHashtag} from '../helpers/validate';
import passGen from '../helpers/passGen';
import { getId } from '../helpers/getId';

// Base API url for Admin -> General
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
// Set vars
var newUrl = "";

// POST TICKETS ****************************************************************************************************************************
export async function postTickets(human_id, account_id, agent_id, workflow_id, department_id, issue_id, internal, subject, message, priority, sla, status, onSaveSuccess, onSaveError) {

  newUrl = url + "/api/v1/tickets"

    return new Promise((resolve, reject) => {
 
      fetch(newUrl, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({
          human_id: human_id,
          account_id: account_id,
          agent_id: agent_id,
          workflow: workflow_id,
          department: department_id,
          issue: issue_id,
          internal: internal,
          subject: subject,
          message: message,
          priority: priority,
          sla: sla,
          status: status
        }),
      })
      .then((response) => {
        if (response.ok) {
          // Request was successful
          onSaveSuccess("ticket");
  
        } else {
          // Request failed
          onSaveError("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        onSaveError(error);
      });
    });
  
}

// POST SMTP ****************************************************************************************************************************
export async function postSmtp(smtpServer, smtpConnectString, smtpEnabled, setOpenSmtp, refreshConfigs, reset, setAlertInfo, setAlertCustom) {

  newUrl = url + "/api/admin/general"

    return new Promise((resolve, reject) => {
      setOpenSmtp(false);

      fetch(newUrl, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({
          option: "smtp",
          value: smtpServer,
          enabled: smtpEnabled,
          extra: smtpConnectString,
        }),
      })
      .then((response) => {
        if (response.ok) {
          // Request was successful
          refreshConfigs(); // Call refreshConfigs to update the data in components
          reset("smtp");
  
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        setAlertCustom(true);
      });
    });
  
}

// POST EMAILS ****************************************************************************************************************************
export async function postEmails(email, enableCustom, emailcustom, setEmailCustom, setAlertCustom, setAlertInfo, subdomain, setOpenEmail, refreshConfigs, reset) {

  var customisago = false;
  newUrl = url + "/api/admin/general"

  // only check custom email if enabled
    if (enableCustom === true) {
      if (validateEmail(emailcustom)) { customisago = true;} 
      else { setAlertInfo(["invalidEmail","error","Error","Email entered is invalid"]); setAlertCustom(true); customisago = false; }
  } else { customisago = true; setEmailCustom(""); }

  let vtarray = validateTextEmail(email, subdomain);
  var vtbool = vtarray[0], vtfinalvalue = vtarray[1];

  if (!vtbool) { 
    setAlertInfo(["invalidText","error","Error","Enter only letters and numbers for the first part of your rikr email address. Do NOT enter the " + subdomain + " portion."]);
    setAlertCustom(true); 
  }

  if (
    customisago &&
    vtbool
  ) {

    return new Promise((resolve, reject) => {
      setOpenEmail(false);

      fetch(newUrl, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({
          option: "email",
          value: vtfinalvalue,
          enabled: enableCustom,
          extra: emailcustom,
        }),
      })
      .then((response) => {
        if (response.ok) {
          // Request was successful
          refreshConfigs(); // Call refreshConfigs to update the data in components
          reset("email");
  
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        setAlertCustom(true);
      });
    });
  }
}

// POST TAGS ****************************************************************************************************************************
export async function postTags(tag, tagcolor, tagtxtcolor, setAlertCustom, setAlertInfo, setOpenTag, refreshTags, reset) {

  newUrl = url + "/api/admin/tags"
  var tagcolorfinal = removeHashtag(tagcolor);
  //console.log(tag, tagcolorfinal, tagtxtcolor);
  
  if (validateClean(tag)) {
    //console.log(tag);

    return new Promise((resolve, reject) => {
      setOpenTag(false);

      fetch(newUrl, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tag: tag,
          color: tagcolorfinal,
          txtcolor: tagtxtcolor,
        }),
      })
      .then((response) => {
        if (response.ok) {
          // Request was successful
          refreshTags(); // Call refreshTags to update the data in components
          reset("tag");
  
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        setAlertCustom(true);
      });
    });

  } else {
    setAlertInfo(["invalidText","error","Error","You may enter only letters and numbers"]);
    setAlertCustom(true); 
  }
}
  
// POST DEPTS ****************************************************************************************************************************
export async function postDepts(dpt, dpticon, client_view, onSaveSuccess, onSaveError) {

  newUrl = url + "/api/admin/depts"
  //console.log(dpt, dpticon, client_view);
  
  if (validateClean(dpt)) {

    return new Promise((resolve, reject) => {

      fetch(newUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: dpt,
          icon: dpticon,
          client_view: client_view,
        }),
      })
      .then((response) => {
        if (response.ok) {
          // Request was successful
          onSaveSuccess("dept");
  
        } else {
          // Request failed
          onSaveError("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        onSaveError(error);
      });
    });

  } else {
    onSaveError("Invalid Text. You may enter only letters and numbers");
  }

}

// POST Workflows ****************************************************************************************************************************
export async function postWorkflows(workflow, isDefault, onSaveSuccess, onSaveError) {

  newUrl = url + "/api/v1/workflows"
  
  if (validateClean(workflow)) {

    return new Promise((resolve, reject) => {
      

      fetch(newUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: workflow,
          is_default: isDefault
        }),
      })
      .then((response) => {
        if (response.ok) {
          // Request was successful
          onSaveSuccess("workflow");
  
        } else {
          // Request failed
          onSaveError("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        onSaveError(error);
      });
    });

  } else {
    onSaveError("You may enter only letters and numbers");
  }
}

// POST REPLIES ****************************************************************************************************************************
export async function postReplies(creplyName, creplyMessage, agentId, setAlertCustom, setAlertInfo, setOpenReply, refreshReplies, reset) {

  newUrl = url + "/api/admin/replies"
  
  if (validateClean(creplyName)) {

    return new Promise((resolve, reject) => {
      setOpenReply(false);

      fetch(newUrl, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: creplyName,
          message: creplyMessage,
          human_last_updated: agentId,
        }),
      })
      .then((response) => {
        if (response.ok) {
          // Request was successful
          refreshReplies(); // Call refresh to update the data in components
          reset("reply");
  
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        setAlertCustom(true);
      });
    });

  } else {
    setAlertInfo(["invalidText","error","Error","You may enter only letters and numbers for the name"]);
    setAlertCustom(true); 
  }
}

// POST ISSUES ****************************************************************************************************************************
export async function postIssues(issue, client_view, onSaveSuccess, onSaveError) {

  newUrl = url + "/api/admin/issues"
  
  if (validateClean(issue)) {

    return new Promise((resolve, reject) => {
  
      fetch(newUrl, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: issue,
          client_view: client_view,
          archive: false
        }),
      })
      .then((response) => {
        if (response.ok) {
          // Request was successful
          onSaveSuccess("issue");
  
        } else {
          // Request failed
          onSaveError("Request failed");
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        onSaveError(error);
      });
    });

  } else {
    onSaveError("You may enter only letters and numbers");
  }
}

// POST NEW AGENTS ****************************************************************************************************************************
export async function postAgent(email, password, generatedPass, firstName, lastName, displayName, role, phone, timezone, setAlertCustom, setAlertInfo, setOpenAskAThing, setAskAThingInfo, setOpenAgent, refreshAgents, reset) {

      // vars for checks
      var emailisago = false;
      var passisago = false;
      var nameisago = false;
      var displayisago = false;
      var phoneisago = false;

  newUrl = url + "/api/v1/agents"

  // check email legit
  if (validateEmail(email)) { emailisago = true;} 
  else if (!validateEmail(email)) { console.log("emailbad"); setAlertInfo(["invalidEmail","error","Error","Email entered is invalid"]); setAlertCustom(true); emailisago = false; return; }
 
  // check is pass should regenerate, validate pass is acceptable if done manually
  if (generatedPass) { 
    password = passGen(); passisago = true;
  } else if (!generatedPass && !password ) {
    // password was entered blank
    setAlertInfo(["invalidPass","error","Error","Password can not be empty"]);setAlertCustom(true);
    passisago = false; return;
  } else {
    if ( password) {
      if (validatePass(password)) { passisago = true; 
      } else { setAlertInfo(["invalidPass","error","Error","Password must be at least 9 characters long & may only contain letters, numbers and $!@%.? "]); setAlertCustom(true); passisago = false; return; }
    } 
  }

  // validate name is acceptable
  if (validateClean(firstName) & validateClean(lastName)) { nameisago = true; 
  } else { setAlertInfo(["invalidFirstorLastName","error","Error","First Name & Last Name may only contain letters, numbers, dashes or underscores."]); setAlertCustom(true); nameisago = false; return; }

  // validate display name is acceptable
  if (validateClean(displayName)) { displayisago = true; 
  } else { setAlertInfo(["invalidDisplayName","error","Error","Display Name may only contain letters, numbers, dashes or underscores."]); setAlertCustom(true); displayisago = false; return; }

  // validate phone has only numbers or dashes
  if (phone) {
    if (validateNumbers(phone)) { phoneisago = true; 
    } else { setAlertInfo(["invalidPhone","error","Error","Phone number should only contain numbers or dashes"]); setAlertCustom(true); phoneisago = false; return;}
  } else {
    phoneisago = true;
  }

  if (
    emailisago && passisago && nameisago && displayisago && phoneisago
  ) {

    return new Promise((resolve, reject) => {
      setOpenAgent(false);

      fetch(newUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
          display_name: displayName,
          role: role,
          phone: phone,
          tz: timezone,
        }),
      })
      .then((response) => {
        if (response.ok) {
          // all good
          refreshAgents(); 
          reset("agent");
        } else {
          // failed
          return response.json(); 
        }
      })
      .then((data) => {
        if (data) {
          //console.log("Response Data:", data);
          if ( data.role === 6 && data.disabled === 0) { 
            setAskAThingInfo(["customer-convert", null, null, null]);
            setOpenAskAThing(true);
          } else if ( data.role === 6 && data.disabled === 1 ) {
            setAskAThingInfo(["customer-convert-enable", data.date_disabled, data.disabler, null]);
            setOpenAskAThing(true);
          } else if ( data.role !== 6 && data.disabled === 1 ) {
            setAskAThingInfo(["agent-enable", data.date_disabled, data.disabler, data.role]);
            setOpenAskAThing(true);
          } else {
            setAlertInfo(["UserExists", "error", "Error", data.message]);
            setAlertCustom(true);
          }
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError", "error", "Error", error]);
        setAlertCustom(true);
      });
      
    });
  }

}

// POST ACCOUNT INFORMATION ****************************************************************************************************************************
export async function postAccountAuth(account, email, owner_id, onSaveSuccess, onSaveError) {

  // Check if email exists
  await getId(email, 0)
    .then(async (emailid) => {

      // if exists, do things
      if (emailid) {

        // Send auth request to account endpoint
        newUrl = url + "/api/v1/accounts/" + account + "/authed";

        const response = await fetch(newUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            human_id: emailid,
            human_last_updated: owner_id
          })
        });

        if (response.ok) {
          onSaveSuccess("newAuth");
        } else {
          onSaveError("A problem occured");
        }
      } else {
        // Send email
        //sendEmail(email);
        onSaveError("no exist");
      }

    })
    .catch((error) => { console.error("Error fetching id:", error); });




}

