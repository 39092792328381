import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
// Components
import TicketSingleCardLeft from './TicketSingleCardLeft';
import TicketSingleMessages from './TicketSingleMessages';
import TicketSingleReply from './TicketSingleReply';
// ICONS
import Badge from '@mui/icons-material/Badge';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import DescriptionIcon from '@mui/icons-material/Description';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
// CRUD HAPPINESS
import { getStuff } from '../services/get';

// TABS ****************************************************************************************************************
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </span>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


// DEFAULT FUNCTION **************************************************************************************************

export default function TicketSingle({ticketId}) {

  const theme = useTheme();
  var ticketIdPhrase = "Ticket #" + ticketId;
  // Tab chosen (value) 
  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => { setValue(newValue); };

  // GET OR UPDATE DATA ************************************************************************************************

  const[ticketinfo, setTicketInfo] = useState([]);
  const[messages, setMessages] = useState([]);

  function refreshTicket(id) {
    getStuff("/api/v1/tickets/" + id)
      .then((data) => { setTicketInfo(data[0][0]); })
      .catch((error) => { console.error("Error getting ticket:", error); });
  }

  function refreshMessages(id) {
    //getStuff("/api/tickets/messages?id=" + id)
      //.then((data) => { setMessages(data[0]); })
      //.catch((error) => { console.error("Error getting ticket:", error); });
  }

    // GET once on pageload
    useEffect(() => { refreshTicket(ticketId); refreshMessages(ticketId); }, []);

  return (
    <Box sx={{ backgroundColor: 'var(--bg-fill)', width: '100%', height: "100vh" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleTabChange}
          textColor="inherit"
          variant="fullWidth"
          TabIndicatorProps={{
            title: "indicator",
            sx: { backgroundColor: "var(--menu-link-active)", height: 3 } //width: "25% !important"
          }}
          sx = {{ background: "var(--ticket-tab-fill)" }}
        >
          <Tab sx = {{ border: 2}} icon={<ConfirmationNumberIcon sx={{fontSize: 25, color: "var(--ticket-tab-icon)"}}/>} iconPosition="start" label={ticketIdPhrase} {...a11yProps(0)} />
          <Tab sx = {{ border: 2}} icon={<EmojiPeopleIcon sx={{fontSize: 25, color: "var(--ticket-tab-icon)"}}/>} iconPosition="start" label="Requester" {...a11yProps(1)} />
          <Tab sx = {{ border: 2}} icon={<Badge sx={{fontSize: 25, color: "var(--ticket-tab-icon)"}}/>} iconPosition="start" label="Company/Group" {...a11yProps(2)} />
          <Tab sx = {{ border: 2}} icon={<DescriptionIcon sx={{fontSize: 25, color: "var(--ticket-tab-icon)"}}/>} iconPosition="start" label="Log" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <>
        <TabPanel value={value} index={0} dir={theme.direction}>
            <Grid container spacing={3}>
                
                <Grid xs={3}>
                  <TicketSingleCardLeft ticketinfo={ticketinfo} />
                </Grid>

                <Grid xs={9}>
                  <TicketSingleReply/>
                  <span>&nbsp;</span>
                  <TicketSingleMessages messages={messages} />
                </Grid>
                
            </Grid>
        
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          Item Four
        </TabPanel>
      </>
    </Box>
  );
}