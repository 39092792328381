// Base API url
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;

export function getDisplayName(mail,id) {

  if (mail !== null ) { var apiPath = "/api/agent/name?human_email=" + mail; }
  if (id !== null ) { var apiPath = "/api/agent/name?id=" + id; }
    

    return new Promise((resolve, reject) => {
        var newUrl = url + apiPath;
    
        fetch(newUrl)
          .then((response) => response.json())
          .then((data) => {
            if (data && data.data && data.data.length > 0) {
              resolve(data.data[0]['display_name']);
            } else if (data.data.length === 0) {
              resolve(0);
            } else {
              reject(new Error('Invalid response data'));
            }
          })
          .catch((error) => {
            console.log('Error fetching data:', error);
            reject(error);
          });
      });
      
}