import React, { useState, useEffect} from 'react';
import {
  Typography,
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Icons
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../AlertCustom.js';



// TICKET ROW LOOP ************************************************************************************************************************************************

// array of tickets
const TicketRow = ({ ticketrow}) => {
  const [open, setOpen] = useState(false);

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  
  // ACTIONS ************************************************************************************************************************************************

  // open and close state of dialog popup
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  
  // Determine icon color based on the ticket status
  const iconColor = ticketrow.status === "pending_user" ? "lightgreen" : ticketrow.status === "pending_agent" ? "gray" : "default";

  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <TableRow onClick={handleClickOpen} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Avatar sx={{ bgcolor: iconColor }}>
          <ForwardToInboxIcon />
        </Avatar>
      </TableCell>
      <TableCell align="left">
        {ticketrow.status === "pending_user" ? `Reply waiting ${ticketrow.date_updated}` : `Awaiting Agent Reply ${ticketrow.date_updated}`}
      </TableCell>
      <TableCell align="left">{ticketrow.subject}</TableCell>
      {/* Add more TableCell here if you have more columns to show */}
    </TableRow>
  );
};

// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function TicketActive({
  clientId,
  clientActsOwned,
  clientActsAuthed,
  deptOptions,
  issueOptions
}) {
  //console.log(clientId);
  const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
  
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

// get client tickets
const [tickets, setTickets] = useState([]);
useEffect(() => {
  var allActs = [];
  clientActsOwned.map(act => {
    allActs.push(act.id);
  });
  clientActsAuthed.map(act => {
    allActs.push(act.id);
  });
  //console.log(allActs);
  let ticketUrl = `${url}/api/v1/tickets?account_array=${allActs.join(",")}`;
  const callRestApi = async () => {
    try {
      const response = await fetch(ticketUrl);
      const jsonResponse = await response.json();
      setTickets(jsonResponse.data);
    } catch (error) {
      console.log('Error fetching data:', error);
   }
  };
  callRestApi();
}, [clientActsOwned,clientActsAuthed]);


  // RETURN  **********************************************************************************************************************************
 
  return (
    <>
      {tickets.length !== 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {tickets.map((ticketrow) => (
                <TicketRow key={ticketrow.id} ticketrow={ticketrow} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Paper sx={{ width: '100%', maxWidth: 350, padding: 1 }}>
          <Typography variant="body1" gutterBottom>No current open tickets</Typography>
        </Paper>
      )}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />
    </>
  );
}