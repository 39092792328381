import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import NativeSelect from '@mui/material/NativeSelect';
import Cookies from "js-cookie";
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../../AlertCustom.js';
// ICONS
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// Crud Happiness
import { deleteAgent } from '../../../services/delete';
import { putAgents } from '../../../services/put';
// HELPERS
import { validateEmail, validateClean, validateCleanSpacesOK, validatePass, validateNumbers} from '../../../helpers/validate';
import passGen from '../../../helpers/passGen';
import moment from 'moment-timezone';
import ImageUploader from 'react-image-upload'


// Agent ROW LOOP ************************************************************************************************************************************************

// array of emails
const AgentRow = ({ agentrow, agentId, onSave, handleUpdateFromComp, isLastItem}) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(agentrow.email);
  const [generatedPass, setGeneratedPass] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(agentrow.first_name);
  const [lastName, setLastName] = useState(agentrow.last_name);
  const [displayName, setDisplayName] = useState(agentrow.display_name);
  const [role, setRole] = useState(agentrow.role);
  // if agentrow.icon exists (not null or undefined), then use it, otherwise use an empty string
  const [profilePic, setProfilePic] = useState(agentrow.icon ? agentrow.icon : "");
  // if agentrow.phone exists (not null or undefined), then use it, otherwise use an empty string
  const [phone, setPhone] = useState(agentrow.phone ? agentrow.phone : "");
  // if agentrow.tz exists (not null or undefined), then use it, otherwise use UTC
  const [timezone, setTimeZone] = useState(agentrow.tz ? agentrow.tz : "");

  const [avatarUrl, setAvatarUrl] = useState("");
  // stops display of 404 for users that dont have an avatar by checking if one exists first
  useEffect(() => {
    if (profilePic === "true") {
        const newAvatarUrl = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_FRONTEND + "/" + process.env.REACT_APP_AVATARS + "/avatars/avatar" + String(agentrow.id) + ".png?rando=1";
        setAvatarUrl(newAvatarUrl);
    }
  }, [profilePic, agentrow]);

  // profile pic data for upload to backend
  const [profilePicData, setProfilePicData] = useState(null);

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };
  const timezoneArray = moment.tz.names();

  
  // ACTIONS ************************************************************************************************************************************************

  // Get agent email and id
  const agentEmail = Cookies.get("agent_email");

  //  When reset of states is needed
  const reset = () => {
    setEmail(agentrow.email);
    setPassword("");
    setGeneratedPass(false);
    setChangePass(false);
    setFirstName(agentrow.first_name);
    setLastName(agentrow.last_name);
    setDisplayName(agentrow.display_name);
    setProfilePic(agentrow.icon ? agentrow.icon : "");
    setPhone(agentrow.phone ? agentrow.phone : "");
    setTimeZone(agentrow.tz ? agentrow.tz : "");
    handleClose();
  };

  // open and close state of dialog popup
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  
  // AGENT: email changes: 'humans.email' (email)
  const handleEmailChange = (event) => { setEmail(event.target.value); };
  // AGENT: password changes: 'humans.email' (password)
  const handlePasswordChange = (event) => { setPassword(event.target.value); };
  // AGENT: firstName changes: 'humans.email' (firstName)
  const handleFirstNameChange = (event) => { setFirstName(event.target.value); };
  // AGENT: lastName changes: 'humans.email' (lastName)
  const handleLastNameChange = (event) => { setLastName(event.target.value); };
  // AGENT: displayName changes: 'humans.email' (displayName)
  const handleDisplayNameChange = (event) => { setDisplayName(event.target.value); };
  // AGENT: role changes: 'humans.role' (displayName)
  const handleRoleChange = (event) => { setRole(event.target.value); };
  // AGENT: phone changes: 'humans.email' (phone)
  const handlePhoneChange = (event) => { setPhone(event.target.value); };
  // AGENT: timezone changes: 'humans.email' (timezone)
  const handleTimeZoneChange = (event) => { setTimeZone(event.target.value); };
  // EMAIL: Change pass: 
  const handleSwitchChangePass = (event) => { setChangePass(event.target.checked); };
  // EMAIL: Custom pass instead of generated
  const handleSwitchGeneratedPass = (event) => { setGeneratedPass(event.target.checked); };


  // AVATAR FUNCTIONS
  const handleAvatarUpload = (collected) => {
    setProfilePic(true);
    setProfilePicData(collected['file']);
      
  }
  const handleAvatarRemove = () => {
    setProfilePicData(null);
    setProfilePic(agentrow.icon ? agentrow.icon : false);
  }
  

  // SAVE FUNCTION: SENDS TO BASE SAVE FUNCTION **********************************************************************************************************************************

  const handleSave = () => {

    // vars for checks
    var emailisago = false;
    var passisago = false;
    var nameisago = false;
    var displayisago = false;
    var phoneisago = false;

    // validate email is entered as an email
    if (validateEmail(email)) { emailisago = true; 
      } else { setAlertInfo(["invalidEmail","error","Error","Email entered is invalid"]); setAlertCustom(true); emailisago = false; }

    // check is pass should regenerate, validate pass is acceptable if done manually
    if (generatedPass) { 
      password = passGen(); passisago = true;
    } else if (!generatedPass && !password ) {
      //do nuthin here cause we are not updating pass
      passisago = true;
    } else {
      if ( password) {
        if (validatePass(password)) { passisago = true; 
        } else { setAlertInfo(["invalidPass","error","Error","Password must be at least 9 characters long & may only contain letters, numbers and $!@%.? "]); setAlertCustom(true); passisago = false; }
      } else {
        passisago = true;
      }
    }

    // validate name is acceptable
    if (validateClean(firstName) & validateClean(lastName)) { nameisago = true; 
      } else { setAlertInfo(["invalidFirstorLastName","error","Error","First Name & Last Name may only contain letters, numbers, dashes or underscores."]); setAlertCustom(true); nameisago = false; }

    // validate display name is acceptable
    if (validateClean(displayName)) { displayisago = true; 
      } else { setAlertInfo(["invalidDisplayName","error","Error","Display Name may only contain letters, numbers, dashes or underscores."]); setAlertCustom(true); displayisago = false; }

    // validate phone has only numbers or dashes
    if (phone) {
      if (validateNumbers(phone)) { phoneisago = true; 
      } else { setAlertInfo(["invalidPhone","error","Error","Phone number should only contain numbers or dashes"]); setAlertCustom(true); phoneisago = false; }
    } else {
      phoneisago = true;
    }

    // Good to save if all is valid
    if (
      emailisago && passisago && nameisago && displayisago && phoneisago
    ) {
      onSave(agentrow.id, email, password, firstName, lastName, displayName, role, phone, timezone, profilePic, profilePicData);
      handleClose();
    } else {
      console.log("error");
      console.log(emailisago, passisago, nameisago, displayisago, phoneisago);
      console.log(agentrow.id, email, password, firstName, lastName, displayName, role, phone, timezone, profilePic, profilePicData);
    }
  };


  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>
      <ListItemButton onClick={handleClickOpen} sx={{width: '100%', mt:-1, mb:-2}}> 
        <ListItem >
          <ListItemAvatar>
          <Avatar 
            sx={{ bgcolor: "var(--icon-avatar)", width: 36, height: 36 }} 
            alt={agentrow.display_name} 
            src={avatarUrl}
          />
          </ListItemAvatar>
          <ListItemText sx={{width: '100%'}}
            primary={email}
            secondary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                {firstName} {lastName} ({displayName}) {timezone} {phone}
              </Typography>
            }
          />
        </ListItem>
      </ListItemButton>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

      {/* DIALOG BOX FOR EDITING AGENTS *********************************************** */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Agent</DialogTitle>
        <DialogContent>

        <Avatar alt={displayName} src={avatarUrl} sx={{ width: 56, height: 56 }} />
            <br />
        <ImageUploader
          withIcon={true}
          buttonText="Choose avatar image"
          onFileAdded={(img) => handleAvatarUpload(img)}
          onFileRemoved={() => handleAvatarRemove()}
          imgExtension={['.jpg', '.gif', '.png', '.gif']}
          maxFileSize={5242880}
        />

        <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            onChange={handleEmailChange}
            value={email} 
            fullWidth
            variant="standard"
          />
          <FormControlLabel
            control={
              <Switch
                checked={changePass}
                color="warning"
                onChange={handleSwitchChangePass}
              />
            }
            label="Change Password"
          />
          {changePass && !generatedPass && (
            <>
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              variant="standard"
            />
            <FormControlLabel control={<Checkbox color="default" checked={generatedPass} onChange={handleSwitchGeneratedPass} />} label="Send generated password" />
            </>
          )}
          {changePass && generatedPass && (
            <FormControlLabel control={<Checkbox color="success" checked={generatedPass} onChange={handleSwitchGeneratedPass} />} label="Send generated password" />
          )}
            <br />
            <TextField
              margin="dense"
              id="firstName"
              label="First Name"
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
              sx={{width: "50%"}}
              variant="filled"
            />

            <TextField
              margin="dense"
              id="lastName"
              label="Last Name"
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
              sx={{width: "50%"}}
              variant="filled"
            />
            <br /><br />
            
            <TextField
              margin="dense"
              id="displayName"
              label="Display Name"
              type="text"
              helperText="Only letters, numbers and hyphens allowed"
              value={displayName}
              onChange={handleDisplayNameChange}
              fullWidth
              variant="outlined"
            />

            <FormControl sx={{ mt: 3, width: 300 }}>
              <InputLabel id="role">Role</InputLabel>
              <Select
                labelId="role"
                id="role"
                value={role}
                onChange={handleRoleChange}
                input={<OutlinedInput label="Role" />}
                >
                  <MenuItem key={"5"} value={"5"}>Tickets: View Only</MenuItem>
                  <MenuItem key={"4"} value={"4"}>Tickets: Edit</MenuItem>
                  <MenuItem key={"3"} value={"3"}>Admin: View Only</MenuItem>
                  <MenuItem key={"2"} value={"2"}>Admin: Edit</MenuItem>
                  <MenuItem key={"1"} value={"1"}>Full Admin</MenuItem>
                  
              </Select>
            </FormControl>
            <br /><br />
            <TextField
              margin="dense"
              id="phone"
              label="Phone"
              type="phone"
              value={phone}
              onChange={handlePhoneChange}
              fullWidth
              variant="filled"
            />

            <FormControl sx={{ mt: 3, width: 300 }}>
              <InputLabel id="timezone">Timezone</InputLabel>
              <Select
                labelId="timezone"
                id="timezone"
                value={timezone}
                onChange={handleTimeZoneChange}
                input={<OutlinedInput label="Timezone" />}
                >
                {timezoneArray.map((tz) => (
                  <MenuItem
                    key={tz}
                    value={tz}
                    >
                    {tz}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={reset}>Cancel</Button>
          <Button onClick={() => deleteAgent(agentrow.id, agentId, agentrow.email, setAlertCustom, setAlertInfo, handleClose, reset, handleUpdateFromComp)}>Delete</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Divider */}
      {!isLastItem && <Divider variant="inset" />}
    </>
  );
};


// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function Agents({ agents, agentId, handleUpdateFromComp }) {
  
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };


  // BASE SAVE FUNCTION  **********************************************************************************************************************************

    const handleSave = (id, email, password, firstName, lastName, displayName, role, phone, timezone, profilePic, profilePicData, setAlertCustom, setAlertInfo, handleUpdateFromComp ) => {
    putAgents(id, email, password, firstName, lastName, displayName, role, phone, timezone, profilePic, profilePicData, setAlertCustom, setAlertInfo);
  };


  // RETURN  **********************************************************************************************************************************
 
  return (
    <>
      <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
        {/* START Agent LOOP */}
        {agents &&
          agents.map((agentrow, index) => (
          <AgentRow
            key={agentrow.id} 
            agentrow={agentrow}
            agentId={agentId} 
            onSave={handleSave}
            handleUpdateFromComp={handleUpdateFromComp}
            isLastItem={index === agents.length - 1} // Check if it's the last item
          />
        ))}
        {!agents &&
          <Typography variant="body">
            No agents yet?
          </Typography>
        }
        {/* END Agent LOOP */}
      </List>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

    
    </>
  );
}