import React, { useState, useEffect, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";

// FOR THEME
import {Container, responsiveFontSizes} from '@mui/material';
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

// Components
import HeaderAppBar from "../HeaderAppBar";
import AlertCustom from '../AlertCustom.js';
import GeneralEmails from './AdminGeneral/GeneralEmails';
import GeneralSMTP from './AdminGeneral/GeneralSMTP';
import GeneralTags from './AdminGeneral/GeneralTags';
import GeneralDepts from './AdminGeneral/GeneralDepts';
import GeneralWorkflows from './AdminGeneral/GeneralWorkflows';
import GeneralReplies from './AdminGeneral/GeneralReplies';
import GeneralIssues from './AdminGeneral/GeneralIssues';

// ICONS
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AlarmIcon from '@mui/icons-material/Alarm';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import ComputerIcon from '@mui/icons-material/Computer';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Cookies from "js-cookie";
import DeckIcon from '@mui/icons-material/Deck';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Home from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from "@mui/material/IconButton";
import InputLabel from '@mui/material/InputLabel';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LoopIcon from '@mui/icons-material/Loop';
import MailIcon from '@mui/icons-material/Mail';
import MenuItem from '@mui/material/MenuItem';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import NativeSelect from '@mui/material/NativeSelect';
import PaymentsIcon from '@mui/icons-material/Payments';
import People from '@mui/icons-material/People';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import Select from '@mui/material/Select';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import Typography from '@mui/material/Typography';
import VpnKeyIcon from '@mui/icons-material/VpnKey';



//Cards
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// CRUD HAPPINESS
import { getStuff } from '../../services/get';
import { postSmtp, postEmails, postTags, postDepts, postWorkflows, postReplies, postIssues } from '../../services/post';
import {getId} from '../../helpers/getId';


// COLOR PICKER DATA *****************************************************************************************************************************************

// Checkmark and Colors imported
import DoneIcon from '@mui/icons-material/Done';
import { 
  red, pink, purple, deepPurple, 
  indigo, blue, lightBlue, cyan, 
  teal, green, lightGreen, lime, 
  yellow, amber, orange, deepOrange 
} from '@mui/material/colors';

// Color Vars
const redc = red[400];
const pinkc = pink[400];
const purplec = purple[400];
const deepPurplec = deepPurple[400];
const indigoc = indigo[400];
const bluec = blue[400];
const lightBluec = lightBlue[400];
const cyanc = cyan[400];
const tealc = teal[400];
const greenc = green[400];
const lightGreenc = lightGreen[400];
const limec = lime[400];
const yellowc = yellow[400];
const amberc = amber[400];
const orangec = orange[400];
const deepOrangec = deepOrange[400];


// grid things ************************************************************************************************************************

const GridItemWrapperChild = styled.div`
  color: var(--headings-text);
  border-radius: var(--box-border-radius);
  box-shadow: 4;
  overflow: hidden;
`;
const GridItemContentChild = styled.div`
  padding: 0px;
`;
const ResponsiveGridLayoutChild = WidthProvider(Responsive);


// DEFAULT FUNCTION ****************************************************************************************************************************************
export default function AdminGeneral({ reSize }) {

  const [agentId, setAgentId] = useState(null);

  // COMPONENT RESIZE STATES *********************************************************************************

  // declare screen size vars
  const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
  });

  const refSizeReg = useRef();
  const refSizeSmtp = useRef();
  const refSizeEmails = useRef();
  const refSizeTags = useRef();
  const refSizeWorkflows = useRef();
  const refSizeDepartments = useRef();
  const refSizeIssues = useRef();
  const refSizeCanned = useRef();
  const [dimensionsReg, setDimensionsReg] = useState({ width: 0, height: 0, });
  const [dimensionsSmtp, setDimensionsSmtp] = useState({ width: 0, height: 0, });
  const [dimensionsEmails, setDimensionsEmails] = useState({ width: 0, height: 0, });
  const [dimensionsTags, setDimensionsTags] = useState({ width: 0, height: 0, });
  const [dimensionsWorkflows, setDimensionsWorkflows] = useState({ width: 0, height: 0, });
  const [dimensionsDepartments, setDimensionsDepartments] = useState({ width: 0, height: 0, });
  const [dimensionsIssues, setDimensionsIssues] = useState({ width: 0, height: 0, });
  const [dimensionsCanned, setDimensionsCanned] = useState({ width: 0, height: 0, });

  var hReg = dimensionsReg.height/11;
  var hSmtp = dimensionsSmtp.height/11;
  var hEmails = dimensionsEmails.height/11;
  var hTags = dimensionsTags.height/11;
  var hWorkflows = dimensionsWorkflows.height/11;
  var hDepartments = dimensionsDepartments.height/11;
  var hIssues = dimensionsIssues.height/11;
  var hCanned = dimensionsCanned.height/11;
  if (hReg < 11) { hReg = 12;} else { hReg = hReg+2.3;}
  if (hSmtp < 11) { hSmtp = 12;} else { hSmtp = hSmtp+2.3;}
  if (hEmails < 11) { hEmails = 12;} else { hEmails = hEmails+2.3;}
  if (hTags < 11) { hTags = 12;} else { hTags = hTags+2.3;}
  if (hWorkflows < 11) { hWorkflows = 12;} else { hWorkflows = hWorkflows+2.3;}
  if (hDepartments < 11) { hDepartments = 12;} else { hDepartments = hDepartments+2.3;}
  if (hIssues < 11) { hIssues = 12;} else { hIssues = hIssues+2.3;}
  if (hCanned < 11) { hCanned = 12;} else { hCanned = hCanned+2.3;}

  var layoutChild = [
    { i: "reg", x: 0, y: 0, w: 12, minW: 1, h: hReg, minH: 12},

    { i: "emails", x: 0, y: 1, w: 4, h: hEmails, minH: 12 },
    { i: "smtp", x: 4, y: 1, w: 4, h: hSmtp, minH: 12 },
    { i: "departments", x: 8, y: 1, w: 4, minW: 2, h: hDepartments, minH: 12 },

    { i: "tags", x: 0, y: 2, w: 4, h: hTags, minH: 12 },
    { i: "workflows", x: 4, y: 2, w: 4, h: hWorkflows, minH: 12 },
    { i: "canned", x: 8, y: 2, w: 4, h: hCanned, minH: 12 },

    { i: "issues", x: 0, y: 3, w: 4, h: hIssues, minH: 12 }
  ];

  // Get agent email
  const agentEmail = Cookies.get("agent_email");

  // GET DEPARTMENTS
  const [dpts, setDpts] = useState([]);
    function refreshDepts() {
      getStuff("/api/admin/depts")
        .then((data) => { setDpts(data[0]); })
        .catch((error) => { console.error("Error fetching tags:", error); });
  }

  // GET once on pageload
  useEffect(() => { 
    refreshDepts();
    getId(agentEmail,1)
      .then((dataid) => {
        setAgentId(dataid);
      })
      .catch((error) => { console.error("Error fetching id:", error); });
  }, []);


  // Vars for SMTP Providers
  const [smtpHost, setSmtpHost] = useState("");
  const [smtpPort, setSmtpPort] = useState(587);
  const [smtpEnabled, setSmtpEnabled] = useState(false);
  const [smtpConnectString, setSmtpConnectString] = useState("");
  const [smtpType, setSmtpType] = useState("TLS");
  const [smtpCertValidation, setSmtpCertValidation] = useState(false);
  const [smtpUser, setSmtpUser] = useState("");
  const [smtpPass, setSmtpPass] = useState("");
    
  // Vars for email form
  const [enableCustom, setEnableCustom] = useState(false);
  const [email, setEmail] = useState("");
  const [emailcustom, setEmailCustom] = useState("");

  // Vars for Tags
  const [tag, setTag] = useState("tag");
  const [tagcolor, setTagColor] = useState(pinkc);
  const [tagtxtcolor, setTagTxtColor] = useState("white");
 
  // Vars for Departments
  const [dpt, setDpt] = useState("");
  const [dpticon, setDptIcon] = useState("AssignmentIcon");
  const [dptEmail, setDptEmail] = useState("");
  const [client_view_dpt, setClient_view_dpt] = useState(false);

  // Vars for Issues
  const [issue, setIssue] = useState("");
  const [client_view_issue, setClient_view_issue] = useState(false);

  // Vars for Workflows
  const [workflow, setWorkflow] = useState("");
  const [workflowDefault, setWorkflowDefault] = useState(false);

  // Vars for Canned Replies
  const [creplyName, setCreplyName] = useState("");
  const [creplyMessage, setCreplyMessage] = useState("");

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  // Vars for Dialog Popups
  const [openEmail, setOpenEmail] = useState(false);
  const [openTag, setOpenTag] = useState(false);
  const [openDept, setOpenDept] = useState(false);
  const [openIssue, setOpenIssue] = useState(false);
  const [openSmtp, setOpenSmtp] = useState(false);
  const [openWorkflow, setOpenWorkflow] = useState(false);
  const [openReply, setOpenReply] = useState(false);

  // ACTIONS ************************************************************************************************************************************************

  // Handle open in Dialog popup clicked
  function handleClickOpen(what){ 
    if (what === "email") {setOpenEmail(true); }
    if (what === "tag") {setOpenTag(true); }
    if (what === "dept") {setOpenDept(true); }
    if (what === "issue") {setOpenIssue(true); }
    if (what === "workflow") {setOpenWorkflow(true); }
    if (what === "reply") {setOpenReply(true); }
    if (what === "smtp") {setOpenSmtp(true); }
  };
  
  // When form is closed or cancelled
  function handleClose(what){
    if (what === "email") {setOpenEmail(false); }
    if (what === "tag") {setOpenTag(false); }
    if (what === "dept") {setOpenDept(false); }
    if (what === "issue") {setOpenIssue(false); }
    if (what === "workflow") {setOpenWorkflow(false); }
    if (what === "reply") {setOpenReply(false); }
    if (what === "smtp") {setOpenSmtp(false); }
  };
  
  // when a color is clicked in tag edit screen 'tags.color' field (state = tagcolor)
  function handleColorClick(color) { setTagColor(color); };

  // when a color is clicked in tag edit screen 'tags.txtcolor' field (state = tagtxtcolor)
  function handleTxtColorClick(color) { setTagTxtColor(color); };

  // when an icon is clicked in dept edit screen 'department.icon' field (state = dpticon)
  function handleIconClick(icon) { setDptIcon(icon); };

  // GET OR UPDATE DATA  **********************************************************************************************************************************
  
  
 
  const [configs, setConfigs] = useState([]);
  const [tags, setTags] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [replies, setReplies] = useState([]);
  const [issues, setIssues] = useState([]);
   
  function refreshConfigs() {
    getStuff("/api/admin/general")
      .then((data) => { setConfigs(data[0]); })
      .catch((error) => { console.error("Error fetching configs:", error); });
  }

  function refreshTags() {
    getStuff("/api/admin/tags")
      .then((data) => { setTags(data[0]); })
      .catch((error) => { console.error("Error fetching tags:", error); });
  }

  function refreshWorkflows() {
    getStuff("/api/v1/workflows")
      .then((data) => { setWorkflows(data[0]); })
      .catch((error) => { console.error("Error fetching workflows:", error); });
  }

  function refreshReplies() {
    getStuff("/api/admin/replies")
      .then((data) => { setReplies(data[0]); })
      .catch((error) => { console.error("Error fetching canned replies:", error); });
  }

  function refreshIssues() {
    getStuff("/api/v1/issues")
      .then((data) => { setIssues(data[0]); })
      .catch((error) => { console.error("Error fetching issues:", error); });
  }

  // GET once on pageload
  useEffect(() => { 
    refreshConfigs(); refreshTags(); refreshWorkflows(); refreshReplies(); refreshIssues();
    getId(agentEmail,1).then((dataid) => {
      setAgentId(dataid);
      setTimeout(function(){
        reSize("TT");
      },1000);
    })
  }, []);

  // reg, email, issue & smtp box adjust for content size (configs)
  useEffect(() => {
    if (refSizeReg.current) {
      if ( refSizeReg.current.offsetWidth != dimensionsReg.width || refSizeReg.current.offsetHeight != dimensionsReg.height ) {
        setDimensionsReg({ width: refSizeReg.current.offsetWidth, height: refSizeReg.current.offsetHeight, });
        reSize("TT");
      }
    }
    if (refSizeSmtp.current) {
      if ( refSizeSmtp.current.offsetWidth != dimensionsSmtp.width || refSizeSmtp.current.offsetHeight != dimensionsSmtp.height ) {
        setDimensionsSmtp({ width: refSizeSmtp.current.offsetWidth, height: refSizeSmtp.current.offsetHeight, });
        reSize("TT");
      }
    }
    if (refSizeEmails.current) {
      if ( refSizeEmails.current.offsetWidth != dimensionsEmails.width || refSizeEmails.current.offsetHeight != dimensionsEmails.height ) {
        setDimensionsEmails({ width: refSizeEmails.current.offsetWidth, height: refSizeEmails.current.offsetHeight, });
        reSize("TT");
      }
    }
  }, [configs]);

  // tag box adjust for content size
  useEffect(() => {
    if (refSizeTags.current) {
       if ( refSizeTags.current.offsetWidth != dimensionsTags.width || refSizeTags.current.offsetHeight != dimensionsTags.height ) {
        setDimensionsTags({ width: refSizeTags.current.offsetWidth, height: refSizeTags.current.offsetHeight, });
        reSize("TT");
      }
    }
  }, [tags]);

   // issues box adjust for content size
   useEffect(() => {
      if (refSizeIssues.current) {
        if ( refSizeIssues.current.offsetWidth != dimensionsIssues.width || refSizeIssues.current.offsetHeight != dimensionsIssues.height ) {
          setDimensionsIssues({ width: refSizeIssues.current.offsetWidth, height: refSizeIssues.current.offsetHeight, });
          reSize("TT");
        }
      }
   }, [issues]);

  // Workflows box adjust for content size
  useEffect(() => {
    if (refSizeWorkflows.current) {
      if ( refSizeWorkflows.current.offsetWidth != dimensionsWorkflows.width || refSizeWorkflows.current.offsetHeight != dimensionsWorkflows.height ) {
        setDimensionsWorkflows({ width: refSizeWorkflows.current.offsetWidth, height: refSizeWorkflows.current.offsetHeight, });
        reSize("TT");
      }
    }
  }, [workflows]);

  // Departments box adjust for content size
  useEffect(() => {
    if (refSizeDepartments.current) {
     if ( refSizeDepartments.current.offsetWidth != dimensionsDepartments.width || refSizeDepartments.current.offsetHeight != dimensionsDepartments.height ) {
        setDimensionsDepartments({ width: refSizeDepartments.current.offsetWidth, height: refSizeDepartments.current.offsetHeight, });
        reSize("TT");
      }
    }
  }, [dpts]);

  // Canned box adjust for content size (replies)
  useEffect(() => {
    if (refSizeCanned.current) {
      if ( refSizeCanned.current.offsetWidth != dimensionsCanned.width || refSizeCanned.current.offsetHeight != dimensionsCanned.height ) {
        setDimensionsCanned({ width: refSizeCanned.current.offsetWidth, height: refSizeCanned.current.offsetHeight, });
        reSize("TT");
       }
    }
  }, [replies]);

  // filter out subdomain
  const subdomainFilter = configs.filter((config) => config.option === 'subdomain');
  const subdomain = "@" + subdomainFilter[0]?.value;
  
  // filter out emails
  var emailFilter = configs.filter((config) => config.option === 'email');

  // filter out smtp providers
  var smtpFilter = configs.filter((config) => config.option === 'smtp');

  // call "handleUpdateFromComp" from component to refresh data
  function handleUpdateFromComp(componentCode,error) {
    // run fetchConfigs, wait till done, then set emailFilter
    if (componentCode === "ge") { refreshConfigs(() => emailFilter = configs.filter((config) => config.option === 'email'))}
    if (componentCode === "gt") { refreshTags(); }
    if (componentCode === "gw") { refreshWorkflows(); }
    if (componentCode === "smtp") { refreshConfigs(() => smtpFilter = configs.filter((config) => config.option === 'smtp'))}

  }

  
  // When reset a dialog form is needed
  function reset(what) {
    if (what === "email") { setEmail(""); setEnableCustom(false); setEmailCustom(""); handleClose("email");}
    if (what === "tag") { setTag("tag"); setTagColor(pinkc); setTagTxtColor("white"); handleClose("tag");}
    if (what === "dept") { setDpt(""); setDptIcon("AssignmentIcon"); handleClose("dept");}
    if (what === "issue") {setIssue(""); handleClose("issue");}
    if (what === "workflow") {setWorkflow(""); setWorkflowDefault(false); handleClose("workflow");}
    if (what === "reply") {setCreplyName(""); setCreplyMessage(""); handleClose("reply");}
    if (what === "smtp") {
      setSmtpHost("");
      setSmtpPort(587);
      setSmtpConnectString(""); 
      setSmtpEnabled(false);
      setSmtpType("TLS");
      setSmtpCertValidation(false);
      setSmtpUser("");
      setSmtpPass("");
      handleClose("smtp");
    }
  };

   
  // DATA EVENTS  **********************************************************************************************************************************
  
  function onSaveSuccess(what) {
    switch(what) {
      case "dept":
        refreshDepts();
        break;
      case "issue":
        refreshIssues();
        break;
      case "workflow":
        refreshWorkflows();
        break;
      case "tickets":
        // nothing much I guess to do here
        break;
    }
    setAlertInfo(["saveComplete","success","Complete!", "Save Complete!"]);
    setAlertCustom(true);
    reset(what);
  }
  function onSaveError(error) {
    setAlertInfo(["GeneralError","error","Error",error]);
    setAlertCustom(true);
  }

  // SMTP: Host changed
  const handleSmtpHostChange = (event) => { setSmtpHost(event.target.value); };
  // SMTP: Port changed
  const handleSmtpPortChange = (event) => { 
    setSmtpPort(event.target.value); 
    setSmtpConnectString( String(smtpPort) + ":" + String(smtpType) + ":" + String(smtpCertValidation) + ":" + String(smtpUser) + ":" + String(smtpPass) ); 
  };
  // SMTP: Enable changed
  const handleSmtpEnabledChange = (event) => { setSmtpEnabled(event.target.checked); };
  // SMTP: Type changed
  const handleSmtpTypeChange = (event) => { 
    setSmtpType(event.target.value);
    setSmtpConnectString( String(smtpPort) + ":" + String(smtpType) + ":" + String(smtpCertValidation) + ":" + String(smtpUser) + ":" + String(smtpPass) ); 
  };
  // SMTP: Cert Validation changed
  const handleSmtpCertValidationChange = (event) => { 
    setSmtpCertValidation(event.target.checked); 
    setSmtpConnectString( String(smtpPort) + ":" + String(smtpType) + ":" + String(smtpCertValidation) + ":" + String(smtpUser) + ":" + String(smtpPass) );
  };
  // SMTP: User changed
  const handleSmtpUserChange = (event) => { 
    setSmtpUser(event.target.value); 
    setSmtpConnectString( String(smtpPort) + ":" + String(smtpType) + ":" + String(smtpCertValidation) + ":" + String(smtpUser) + ":" + String(smtpPass) );
  };
  // SMTP: Pass changed
  const handleSmtpPassChange = (event) => { 
    setSmtpPass(event.target.value);
    setSmtpConnectString( String(smtpPort) + ":" + String(smtpType) + ":" + String(smtpCertValidation) + ":" + String(smtpUser) + ":" + String(smtpPass) ); 
  };

  // EMAIL: Toggle switch changes: 'config.enabled' field, (enableCustom)
  const handleSwitchChange = (event) => { setEnableCustom(event.target.checked); if (event.target.checked === false){ setEmailCustom("");}};
  // EMAIL: rikrdesk email changes: 'config.value' field (email)
  const handleEmailChange = (event) => { setEmail(event.target.value); };
  // EMAIL: Custom email changes: 'config.extra' field (emailcustom)
  const handleCustomChange = (event) => { setEmailCustom(event.target.value); };

  // TAG: Tag change
  const handleTagChange = (event) => { setTag(event.target.value); };

  // DEPT: input field changes: 'deptartments.name' field (state = dpt)
  const handleDptChange = (event) => { setDpt(event.target.value); };
  // DEPT: icon is clicked in dept add screen 'departments.icon' field (state = dpticon)
  function handleIconClick(icon) { setDptIcon(icon); };
  // DEPT: input field changes: 'deptartments.email' field (state = dpt)
  const handleDptEmailChange = (event) => { setDptEmail(event.target.value); };

  // WORKFLOW: input field changes: 'workflows.name' field (state = workflow)
  const handleWorkflowChange = (event) => { setWorkflow(event.target.value); };
 
  // CANNED REPLIES: input field changes: 'workflows.name' field (state = workflow)
  const handleReplyNameChange = (event) => { setCreplyName(event.target.value); };
  // CANNED REPLIES: input field changes: 'workflows.name' field (state = workflow)
  const handleReplyMessageChange = (event) => { setCreplyMessage(event.target.value); };

  // Issue
  const handleIssueChange = (event) => { setIssue(event.target.value); };



  // RETURN  **********************************************************************************************************************************
  
  return ( 
   <>
    <ResponsiveGridLayoutChild
    layouts={{ lg: layoutChild}}
    autoSize={true}
    isResizable={true}
    draggableHandle={'.DragByThisG'}
    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
    cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
    rowHeight={1}
    isDraggable={true}
    onLayoutChange={() => reSize("TT")}
    onWidthChange={() => reSize("TT")}
    onDragStop={() => reSize("TT")}
    onResizeStop={() => reSize("TT")}
    onBreakpointChange={() => reSize("TT")}
    margin={[20, 10]}
    useCSSTransforms={true}
    >
    
    {/* Registration ********************************************************************************************************************* */}
    <GridItemWrapperChild key="reg" >
      <GridItemContentChild>
        <Card ref={refSizeReg} sx={{ width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisG" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <VpnKeyIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                  <Typography sx={{ paddingLeft: '8px' }}>Registration</Typography>
                </div>
              }
          />
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
            License info coming
          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>
  
    {/* SMTP **************************************************************************************************************************** */}
    <GridItemWrapperChild key="smtp" >
      <GridItemContentChild>
        <Card ref={refSizeSmtp} sx={{ width: '100%', minWidth: 375, borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisG" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ForwardToInboxIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ paddingLeft: '8px' }}>SMTP Providers&nbsp;</Typography>
                <Tooltip title="If no SMTP provider is available, email will be sent via localhost port 25" placement="bottom-start">
                  <InfoIcon sx={{ fontSize: 20, color: "var(--icon-action)"}} />
                </Tooltip>
              </div>
            }
            action={
              <Tooltip title="Add SMTP Provider" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("smtp")} onMouseDown={e => e.stopPropagation()} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
            }
          />
          {/* DIALOG BOX */}
          <Dialog open={openSmtp} onClose={() => handleClose("smtp")}>
            <DialogTitle>Add SMTP Provider</DialogTitle>
            <DialogContent>
              <FormControlLabel control={<Switch checked={smtpEnabled} color="warning" onChange={handleSmtpEnabledChange} />} label="Enabled" />
              <TextField margin="dense" id="smtpHost" label="Host" type="text" value={smtpHost} onChange={handleSmtpHostChange} fullWidth variant="standard" />
              <TextField margin="dense" id="smtpPort" label="Port" type="text" value={smtpPort} onChange={handleSmtpPortChange} fullWidth variant="standard" />
              
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="smtpType">Type</InputLabel>
                  <NativeSelect defaultValue={smtpType} onChange={handleSmtpTypeChange} inputProps={{name: 'smtpType', id: 'smtpType', }}>
                    <option value="TLS">&nbsp;TLS</option>
                    <option value="SSL">&nbsp;SSL</option>
                  </NativeSelect>
              </FormControl>

              <br /><br />
              <FormControlLabel control={<Switch checked={smtpCertValidation} color="warning" onChange={handleSmtpCertValidationChange} />} label="Cert Validation" />
              <TextField margin="dense" id="smtpUser" label="User" type="text" value={smtpUser} onChange={handleSmtpUserChange} fullWidth variant="standard" />
              <TextField margin="dense" id="smtpPass" label="Password" type="password" value={smtpPass} onChange={handleSmtpPassChange} fullWidth variant="standard" />
             </DialogContent>
            <DialogActions>
              <Button onClick={() => reset("smtp")} onMouseDown={e => e.stopPropagation()}>Cancel</Button>
              <Button onClick={() => postSmtp(smtpHost, smtpConnectString, smtpEnabled, setOpenSmtp, refreshConfigs, reset, setAlertInfo, setAlertCustom)} onMouseDown={e => e.stopPropagation()}>Save</Button>
            </DialogActions>
          </Dialog>
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>


            {/* GeneralSMTP Component */}
            <GeneralSMTP rdsmtps={smtpFilter} handleUpdateFromComp={handleUpdateFromComp} />

          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>

    {/* Tags **************************************************************************************************************************** */}
    <GridItemWrapperChild key="tags" >
      <GridItemContentChild>
        <Card ref={refSizeTags} sx={{ width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisG" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LocalOfferIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ paddingLeft: '8px' }}>Tags</Typography>
              </div>
            }
            action={
              <Tooltip title="Add Tag" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("tag")} onMouseDown={e => e.stopPropagation()} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
            }
          />
          
          
          {/* DIALOG BOX */}
          <Dialog open={openTag} onClose={() => handleClose("tag")}>
            <DialogTitle>Add Tag</DialogTitle>
            <DialogContent sx={{ ml: "50px", mr: "20px"}}>
            <Chip label={tag} size="small" sx={{ backgroundColor: tagcolor, color: tagtxtcolor }}/>
              <br /><br />
              <DialogContentText></DialogContentText>
              <TextField autoFocus margin="dense" id="tag" label="Tag name" type="text" fullWidth variant="standard" value={tag} onChange={handleTagChange} />
              <br /><br />
          <Typography variant="caption" sx={{ fontSize: 15}}>
            Edit color
          </Typography>
          <br />
          <>
    <ButtonGroup variant="outlined" sx={{backgroundColor:"black"}}>
      <Button onClick={() => handleColorClick(redc)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:redc, ":hover":{ backgroundColor:redc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {tagcolor === redc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(pinkc)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:pinkc, ":hover":{ backgroundColor:pinkc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === pinkc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(purplec)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:purplec, ":hover":{ backgroundColor:purplec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === purplec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(deepPurplec)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:deepPurplec, ":hover":{ backgroundColor:deepPurplec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === deepPurplec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined" sx={{backgroundColor:"black"}}>
      <Button onClick={() => handleColorClick(indigoc)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:indigoc, ":hover":{ backgroundColor:indigoc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === indigoc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(bluec)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:bluec, ":hover":{ backgroundColor:bluec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === bluec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(lightBluec)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:lightBluec, ":hover":{ backgroundColor:lightBluec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === lightBluec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(cyanc)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:cyanc, ":hover":{ backgroundColor:cyanc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === cyanc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined" sx={{backgroundColor:"black"}}>
      <Button onClick={() => handleColorClick(tealc)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:tealc, ":hover":{ backgroundColor:tealc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === tealc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(greenc)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:greenc, ":hover":{ backgroundColor:greenc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === greenc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(lightGreenc)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:lightGreenc, ":hover":{ backgroundColor:lightGreenc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === lightGreenc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(limec)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:limec, ":hover":{ backgroundColor:limec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === limec && ( <DoneIcon sx={{ color: "black", fontSize: 20 }}/> )} &nbsp;
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined" sx={{backgroundColor:"black"}}>
      <Button onClick={() => handleColorClick(yellowc)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:yellowc, ":hover":{ backgroundColor:yellowc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === yellowc && ( <DoneIcon sx={{ color: "black", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(amberc)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:amberc, ":hover":{ backgroundColor:amberc, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === amberc && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(orangec)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:orangec, ":hover":{ backgroundColor:orangec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === orangec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleColorClick(deepOrangec)} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:deepOrangec, ":hover":{ backgroundColor:deepOrangec, opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagcolor === deepOrangec && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
    </ButtonGroup>

    <br /><br />
    <Typography variant="caption" sx={{ fontSize: 15}}>
      Edit text color
    </Typography>
    <br />

    <ButtonGroup variant="outlined" sx={{backgroundColor:"black"}}>
      <Button onClick={() => handleTxtColorClick("white")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"white", ":hover":{ backgroundColor:"white", opacity:0.8 }, borderRadius:0, border:"1px solid black", width:"25px" }}>
        {tagtxtcolor === "white" && ( <DoneIcon sx={{ color: "white", fontSize: 20 }}/> )} &nbsp;
      </Button>
      <Button onClick={() => handleTxtColorClick("black")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"black", ":hover":{ backgroundColor:"black", opacity:0.8 }, borderRadius:0, border:"1px solid white", width:"25px" }}>
        {tagtxtcolor === "black" && ( <DoneIcon sx={{ color: "black", fontSize: 20 }}/> )} &nbsp;
      </Button>
    </ButtonGroup>

    </>  
            </DialogContent>
            <DialogActions>
              <Button onClick={() => reset("tag")} onMouseDown={e => e.stopPropagation()}>Cancel</Button>
              <Button onClick={() => postTags(tag, tagcolor, tagtxtcolor, setAlertCustom, setAlertInfo, setOpenTag, refreshTags, reset)} onMouseDown={e => e.stopPropagation()}>Save</Button>
            </DialogActions>
          </Dialog>
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

          {/* GeneralTags Component */}  
          <GeneralTags tags={tags} handleUpdateFromComp={handleUpdateFromComp} />

          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>

    {/* Workflows **************************************************************************************************************************** */}
    <GridItemWrapperChild key="workflows" >
    <GridItemContentChild>  
      <Card ref={refSizeWorkflows} sx={{ width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
        <CardHeader className="DragByThisG" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AccountTreeIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ paddingLeft: '8px' }}>Workflows</Typography>
              </div>
            }
            action={
              <Tooltip title="Add Workflow" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("workflow")} onMouseDown={e => e.stopPropagation()} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
          }
        />
        {/* DIALOG BOX */}
        <Dialog open={openWorkflow} onClose={() => handleClose("workflow")}>
          <DialogTitle>Add Workflow</DialogTitle>
            <DialogContent sx={{ ml: "50px", mr: "20px"}}>

              <TextField autoFocus margin="dense" id="editworkflow"
                onChange={handleWorkflowChange}
                helperText="Workkflow Name: Numbers or letters only"
                value={workflow} 
                fullWidth variant="standard"
              />
              <br />

              <FormControlLabel
                control={
                  <Switch
                    checked={workflowDefault}
                    color="warning"
                    onChange={(event, newValue) => {
                      setWorkflowDefault(newValue);
                    }} 
                  />
                }
                label="Make Default"
              />

            </DialogContent>
            <DialogActions>
              <Button onClick={() => reset("workflow")} onMouseDown={e => e.stopPropagation()}>Cancel</Button>
              <Button onClick={() => postWorkflows(workflow, workflowDefault, onSaveSuccess, onSaveError)} onMouseDown={e => e.stopPropagation()}>Save</Button>
            </DialogActions>
        </Dialog>
        <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

            {/* GeneralWorkflows Component */}
            <GeneralWorkflows workflows={workflows} onSaveSuccess={onSaveSuccess} onSaveError={onSaveError} />

        </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>

    {/* Emails **************************************************************************************************************************** */}
    <GridItemWrapperChild key="emails">
      <GridItemContentChild>   
        <Card ref={refSizeEmails} sx={{ width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisG" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MailIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ marginLeft: '8px' }}>Emails</Typography>
              </div>
            }
            action={
              <Tooltip title="Add Email" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("email")} onMouseDown={e => e.stopPropagation()} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
            }
          />
          {/* DIALOG BOX */}
          <Dialog open={openEmail} onClose={() => handleClose("email")}>
            <DialogTitle>Add Email</DialogTitle>
            <DialogContent>
              <DialogContentText></DialogContentText>
              <TextField autoFocus margin="dense" id="email" label={subdomain} type="text" fullWidth variant="standard" value={email} onChange={handleEmailChange} />
              
              {/* ENABLE CUSTOM? */}
              <FormControlLabel control={
                <Switch 
                  defaultChecked={enableCustom}
                  color="warning"
                  onChange={handleSwitchChange} 
                />} 
                label="Enable Custom?" />

              {enableCustom && (
                <TextField margin="dense" id="custom-field" label="Custom Email" type="email" value={emailcustom} onChange={handleCustomChange} fullWidth variant="standard" />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => reset("email")} onMouseDown={e => e.stopPropagation()}>Cancel</Button>
              <Button onClick={() => postEmails(email, enableCustom, emailcustom, setEmailCustom, setAlertCustom, setAlertInfo, subdomain, setOpenEmail, refreshConfigs, reset)} onMouseDown={e => e.stopPropagation()}>Save</Button>
            </DialogActions>
          </Dialog>
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

            {/* GeneralEmails Component */}
            <GeneralEmails rdemails={emailFilter} rdsmtps={smtpFilter} subdomain={subdomain} handleUpdateFromComp={handleUpdateFromComp} />

          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>

    {/* Departments **************************************************************************************************************************** */}
    <GridItemWrapperChild key="departments" >
      <GridItemContentChild>
        <Card ref={refSizeDepartments} 
          sx={{ 
            width: '100%',
            borderRadius: 'var(--box-border-radius)', 
            boxShadow: 4, color: 'var(--headings-text)',
            '& .MuiButton-root': {
              marginBottom: '0px',
            },
            }}>

        <CardHeader className="DragByThisG" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ArtTrackIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ paddingLeft: '8px' }}>Departments</Typography>
              </div>
            }
            action={
              <Tooltip title="Add Department" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("dept")} onMouseDown={e => e.stopPropagation()} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
            }
          />

      {/* DIALOG BOX */}
      <Dialog open={openDept} onClose={() => handleClose("dept")}>
        <DialogTitle>Add Department</DialogTitle>
        <DialogContent sx={{ ml: "50px", mr: "20px"}}>
         
          <TextField
            autoFocus
            margin="dense"
            id="editdpt"
            onChange={handleDptChange}
            helperText="Dept Name: Numbers or letters only"
            value={dpt} 
            fullWidth
            variant="standard"
          />
          <br /><br />

      {/* Email DROPDOWN */}      
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-medium-label">Associated Email</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={dptEmail}
          label="Email"
          sx={{width: "200px"}}
          onChange={handleDptEmailChange}
          >
          <MenuItem value={""}><em>None</em></MenuItem>
          <MenuItem value={"Email1"}>Email1</MenuItem>
          <MenuItem value={"Email2"}>Email2</MenuItem>
        </Select>
      </FormControl>
      <br />
          <Typography variant="caption" sx={{ fontSize: 15}}>
            Edit icon
          </Typography>
          <br />
          <>
    <ButtonGroup variant="outlined">
      <Button onClick={() => handleIconClick("AssignmentIcon")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {dpticon === "AssignmentIcon" 
        ? <AssignmentIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <AssignmentIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("People")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {dpticon === "People" 
        ? <People sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <People sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("EngineeringIcon")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {dpticon === "EngineeringIcon" 
        ? <EngineeringIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <EngineeringIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined">
    <Button onClick={() => handleIconClick("PaymentsIcon")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {dpticon === "PaymentsIcon" 
        ? <PaymentsIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <PaymentsIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("TrendingUpRoundedIcon")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {dpticon === "TrendingUpRoundedIcon" 
        ? <TrendingUpRoundedIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <TrendingUpRoundedIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("MilitaryTechIcon")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {dpticon === "MilitaryTechIcon" 
        ? <MilitaryTechIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <MilitaryTechIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined">
    <Button onClick={() => handleIconClick("AccountTreeIcon")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {dpticon === "AccountTreeIcon" 
        ? <AccountTreeIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <AccountTreeIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("DeckIcon")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {dpticon === "DeckIcon" 
        ? <DeckIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <DeckIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("DesignServicesIcon")} onMouseDown={e => e.stopPropagation()} sx={{backgroundColor:"var(--headings-fill)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {dpticon === "DesignServicesIcon" 
        ? <DesignServicesIcon sx={{ color: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <DesignServicesIcon sx={{ color: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      </ButtonGroup>
      <br /><br />

      {/* DPT CLIENT_VIEW */}
      <FormControlLabel control={
        <Switch 
          checked={client_view_dpt}
          color="warning" 
          onChange={(event, newValue) => {
            setClient_view_dpt(newValue);
          }} 
        />} 
        label="Visible by client?" />

      </>
        </DialogContent>
        <DialogActions>
              <Button onClick={() => reset("dept")} onMouseDown={e => e.stopPropagation()}>Cancel</Button>
              <Button onClick={() => postDepts(dpt, dpticon, client_view_dpt, onSaveSuccess, onSaveError)} onMouseDown={e => e.stopPropagation()}>Save</Button>
              
            </DialogActions>
      </Dialog>
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

          {/* GeneralDepts Component */}
          <GeneralDepts dpts={dpts} onSaveSuccess={onSaveSuccess} onSaveError={onSaveError} />

          </CardContent>
        </Card>
      </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>

        {/* Themes ***************************************************************************************************************************** 
        <GridItemWrapper key="themes" >
        <GridItemContentChild>    
         <Card ref={refSizeThemes} sx={{ mr: 0, width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisG" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <PermMediaIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ paddingLeft: '8px' }}>Themes</Typography>
              </div>
            }
            />
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
            Coming Soon
          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapper> */}


      {/* Issues ***************************************************************************************************************************** */}
    <GridItemWrapperChild key="issues" >
        <GridItemContentChild>    
        <Card ref={refSizeIssues} sx={{ mr: 0, width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisG" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FeedbackIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ paddingLeft: '8px' }}>Issues</Typography>
              </div>
            }
            action={
              <Tooltip title="Add Issue" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("issue")} onMouseDown={e => e.stopPropagation()} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
            }
            />
            {/* DIALOG BOX - ISSUE */}
            <Dialog open={openIssue} onClose={() => handleClose("issue")}>
              <DialogTitle>Add Issue Tag</DialogTitle>
              <DialogContent sx={{ ml: "50px", mr: "20px"}}>
                {/* ISSUE NAME */}
                <TextField autoFocus margin="dense" id="editissue"
                  onChange={handleIssueChange}
                  helperText="Issue Name: Numbers or letters only"
                  value={issue} 
                  fullWidth variant="standard"
                />
                {/* ISSUE CLIENT_VIEW */}
                <FormControlLabel control={
              <Switch 
                checked={client_view_issue}
                color="warning" 
                onChange={(event, newValue) => {
                  setClient_view_issue(newValue);
                }} 
              />} 
              label="Visible by client?" />

              </DialogContent>
              <DialogActions>
                <Button onClick={() => reset("issue")} onMouseDown={e => e.stopPropagation()}>Cancel</Button>
                <Button onClick={() => postIssues(issue, client_view_issue, onSaveSuccess, onSaveError)} onMouseDown={e => e.stopPropagation()}>Save</Button>
              </DialogActions>
            </Dialog>
            <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

              {/* GeneralIssues Component */}
              {issues.length > 0 && (
                <GeneralIssues issues={issues} onSaveSuccess={onSaveSuccess} onSaveError={onSaveError} />
              )}

            </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild> 


      {/* Canned Replies **************************************************************************************************************************** */}
    <GridItemWrapperChild key="canned" >
      <GridItemContentChild>    
        <Card ref={refSizeCanned} sx={{ mr: 0, width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisG" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <QuickreplyIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ paddingLeft: '8px' }}>Canned Replies</Typography>
              </div>
            }
            action={
              <Tooltip title="Add Canned Reply" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("reply")} onMouseDown={e => e.stopPropagation()} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
          }
          />
          {/* DIALOG BOX */}
        <Dialog open={openReply} onClose={() => handleClose("reply")}>
          <DialogTitle>Add Canned Reply</DialogTitle>
            <DialogContent sx={{ ml: "50px", mr: "50px"}}>
              <TextField autoFocus margin="dense" id="editreplyname"
                onChange={handleReplyNameChange}
                helperText="Name: Numbers or letters only"
                value={creplyName} 
                fullWidth variant="standard"
              /><br /><br />
              <TextField margin="dense" id="editreplymessage"
                onChange={handleReplyMessageChange}
                helperText=""
                label="Canned Message"
                value={creplyMessage} 
                fullWidth variant="outlined"
                multiline
                rows={4}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => reset("reply")} onMouseDown={e => e.stopPropagation()}>Cancel</Button>
              <Button onClick={() => postReplies(creplyName, creplyMessage, agentId, setAlertCustom, setAlertInfo, setOpenReply, refreshReplies, reset)} onMouseDown={e => e.stopPropagation()}>Save</Button>
            </DialogActions>
        </Dialog>
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
             {/* GeneralCanned Replies Component */}
             <GeneralReplies replies={replies} handleUpdateFromComp={handleUpdateFromComp} />


          </CardContent>
        </Card>
        </GridItemContentChild >
        <div className="dragarrowDark" >
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>
   
      </ResponsiveGridLayoutChild>
      
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />
    </>

  );
  
}