import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Icons
import MailIcon from '@mui/icons-material/Mail';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../../AlertCustom.js';
// Crud Happiness
import { deleteId } from '../../../services/delete';
import { putEmails } from '../../../services/put';
// HELPERS
import { validateEmail, validateTextEmail} from '../../../helpers/validate';


// EMAIL ROW LOOP ************************************************************************************************************************************************

// array of emails
const EmailRow = ({ emailrow, rdsmtps, onSave, subdomain, handleUpdateFromComp, isLastItem}) => {
  const [open, setOpen] = useState(false);
  const [enableCustom, setEnableCustom] = useState(!!parseInt(emailrow.enabled));
  const [email, setEmail] = useState(emailrow.value);
  const [emailcustom, setEmailCustom] = useState(emailrow.extra);
  const [smtp, setSmtp] = useState(emailrow.extra);

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  
  // ACTIONS ************************************************************************************************************************************************

  //  When reset of states is needed
  const reset = () => {
    setEmail(emailrow.value);
    setEnableCustom(!!parseInt(emailrow.enabled));
    setEmailCustom(emailrow.extra);
    handleClose();
  };

  // open and close state of dialog popup
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  
  // When 'enabled' field (enableCustom) toggle switch changes
  const handleSwitchChange = (event) => { setEnableCustom(event.target.checked); if (event.target.checked === false){ setEmailCustom("");}};
  // When temp rikrdesk email changes: 'value' field (email)
  const handleEmailChange = (event) => { setEmail(event.target.value); };
  // when custom email changes: 'extra' field (emailcustom)
  const handleCustomChange = (event) => { setEmailCustom(event.target.value); };
  // when smtp changes: 'extra' field (emailcustom:smtp#)
  const handleSmtpChange = (event) => { setSmtp(event.target.value); };


  // SAVE FUNCTION: SENDS TO BASE SAVE FUNCTION **********************************************************************************************************************************

  const handleSave = () => {
    var customisago = false;
    // only check custom email if enabled
    if (enableCustom === true) {
      if (validateEmail(emailcustom)) { 
        customisago = true;
      } else { 
        setAlertInfo(["invalidEmail","error","Error","Email entered is invalid"]);
        setAlertCustom(true);
        customisago = false; 
      }
    } else {
      customisago = true;
      setEmailCustom("");
    }

    let vtarray = validateTextEmail(email, subdomain);
    var vtbool = vtarray[0], vtfinalvalue = vtarray[1];

    if (!vtbool) { 
      setAlertInfo(["invalidText","error","Error","Enter only letters and numbers for the first part of your rikr email address. Do NOT enter the " + subdomain + " portion."]);
      setAlertCustom(true); 
    }

    if (
      customisago &&
      vtbool
    ) {
      onSave(emailrow.id, vtfinalvalue, enableCustom, emailcustom, emailrow.extra);
      handleClose();
    }
  };
  

  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>
      <ListItemButton onClick={handleClickOpen} sx={{mt:-1, mb:-1}}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "var(--icon-avatar)", width: 28, height: 28 }}>
              <MailIcon alt={email} sx={{ width: 20, height: 20 }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={email}
            secondary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                Custom: {emailcustom}
              </Typography>
            }
          />
        </ListItem>
      </ListItemButton>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

      {/* DIALOG BOX */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Email</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="rikremail"
            label="Rikr Email Address"
            onChange={handleEmailChange}
            helperText="You can only edit the portion before the @ symbol."
            value={email.split('@')[0]} // Set the value to the portion before '@'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="body2" color="textSecondary">
                    {subdomain} {/* Show the portion after '@' */}
                  </Typography>
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="standard"
          />
          <FormControlLabel
            control={
              <Switch
                checked={enableCustom}
                color="warning"
                onChange={handleSwitchChange}
              />
            }
            label="Enable Custom"
          />
          {enableCustom && (
            <>
            <TextField
              margin="dense"
              id="customemail"
              label="Custom Email"
              helperText="If you would like to use a custom email address for your domain, you may enter it here"
              type="email"
              value={emailcustom}
              onChange={handleCustomChange}
              fullWidth
              variant="standard"
            />
            
            <FormControl sx={{ mt: 3, width: 300 }}>
              <InputLabel id="smtp">Custom SMTP</InputLabel>
              <Select
                labelId="smtp"
                id="smtp"
                value={smtp}
                onChange={handleSmtpChange}
                input={<OutlinedInput label="SMTP" />}
                >
                {rdsmtps.map((smtpline) => (
                  <MenuItem
                    key={smtpline}
                    value={smtpline}
                    >
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={reset}>Cancel</Button>
          <Button onClick={() => deleteId(emailrow.id, "email", "/api/admin/general", setAlertCustom, setAlertInfo, handleClose, reset, handleUpdateFromComp)}>Delete</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Divider */}
      {!isLastItem && <Divider variant="inset" />}
    </>
  );
};


// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function GeneralEmails({ rdemails, rdsmtps, subdomain, handleUpdateFromComp }) {
  
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };


  // BASE SAVE FUNCTION  **********************************************************************************************************************************

  
  const handleSave = (id, value, enabled, extra, setAlertCustom, setAlertInfo, handleUpdateFromComp ) => {
    putEmails(id, value, enabled, extra, setAlertCustom, setAlertInfo);
  };


  // RETURN  **********************************************************************************************************************************
 
  return (
    <>
      <List sx={{ width: '100%', maxWidth: 350, bgcolor: 'background.paper' }}>
        {/* START EMAIL LOOP */}
        {rdemails.map((emailrow, index) => (
          <EmailRow
            key={emailrow.id} 
            emailrow={emailrow}
            rdsmtps={rdsmtps}
            onSave={handleSave}
            subdomain={subdomain}
            handleUpdateFromComp={handleUpdateFromComp}
            isLastItem={index === rdemails.length - 1} // Check if it's the last item
          />
        ))}
        {/* END EMAIL LOOP */}
      </List>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

    
    </>
  );
}