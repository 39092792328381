import {putIssues, putTicket} from "./put.js";
import {patchTicketIssues, patchTicketWorkflows} from "./patch.js";

// Base API url for Admin -> General
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
// Set vars
var newUrl = "";


// DELETE ASKED FIRST
export async function deleteAsked(id, thingName, what, apiPath, goWhere, by, onSaveSuccess, onSaveError) {
  //console.log(id, what, apiPath, goWhere, by);

  // Lets make sure id and transfer id exist
  if (id && goWhere) {
    
    if (goWhere == "notickets") {
      // if no tickets to change, let's go
      doIt();
    
    } else if (goWhere == "archive") {
      // if we are archiving instead
      if ( what == "issue") { putIssues(id, thingName, false, true, onSaveSuccess, onSaveError); }

    } else if (goWhere == "purge") {
      // if we are completely purging
      if ( what == "issue") { deleteIssue(id, onSaveSuccess, onSaveError); }

    } else {
      // Otherwise lets change all tickets over to the new thing
      if (by == "dept") { putTicket(null, what, id, goWhere, by, onSaveSuccess, onSaveError); doIt(); }
      if (by == "issue") { patchTicketIssues(id, goWhere, onSaveSuccess, onSaveError); doIt(); }
      if (by == "workflow") { patchTicketWorkflows(id, goWhere, onSaveSuccess, onSaveError); doIt(); }
    }

  } else {
    onSaveError("request data missing");
  }
  

  // delete function
  function doIt() {
    return new Promise((resolve, reject) => {
      newUrl = url + apiPath + "/" + String(id);
  
      fetch(newUrl, {
        method: "DELETE",
        headers: { "Content-Type": "application/json", },
      })
      .then((response) => {
        if (response.ok) {
          onSaveSuccess(what);
        } else {
          // Request failed
          onSaveError("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        onSaveError(error);
      });
    });
  }


}

// DELETE SOMETHING WITH AN ID
export async function deleteId(id, what, apiPath, setAlertCustom, setAlertInfo, handleClose, reset, handleUpdateFromComp) {

  return new Promise((resolve, reject) => {
    newUrl = url + apiPath + "?id=" + String(id);
    reset(what);

    fetch(newUrl, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", },
    })
    .then((response) => {
      if (response.ok) {
        // Request was successful. Set alert info array
        setAlertInfo(["deleteThing","success","Alert","Item has been deleted"]);
        // tell user we deleted it and close window
        setAlertCustom(true);
        handleClose();
        // give it time to update before we refresh all data
        setTimeout(function(){ 

          if (what === "email") { handleUpdateFromComp("ge"); }
          if (what === "tag") { handleUpdateFromComp("gt");}
          if (what === "dept") { handleUpdateFromComp("gd");}
          if (what === "theme") {handleUpdateFromComp("gth");}
          if (what === "workflow") {handleUpdateFromComp("gw");}
          if (what === "reply") {handleUpdateFromComp("gr");}
          if (what === "agent") {handleUpdateFromComp("agent");}
          if (what === "smtp") {handleUpdateFromComp("smtp");}
        
        }, 1000);
 

      } else {
        // Request failed
        throw new Error("Request failed");
      }
    })
    .catch((error) => {
      // Request error
      console.error("Error during API request:", error);
      setAlertInfo(["GeneralError","error","Error",error]);
      setAlertCustom(true);
    });
  });
  
}

// DELETE an issue
export async function deleteIssue(issue_id, onSaveSuccess, onSaveError) {

  var apiPath = "/api/v1/issues/" + issue_id;

  return new Promise((resolve, reject) => {

    newUrl = url + apiPath;
 
    console.log(newUrl);

    fetch(newUrl, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", },
      body: JSON.stringify({
        issue_id: issue_id,
      }),
    })
    .then((response) => {
      if (response.ok) {
        onSaveSuccess("issue");
      } else {
        // Request failed
        onSaveError("Request failed");
      }
    })
    .catch((error) => {
      // Request error
      console.error("Error during API request:", error);
      onSaveError(error);
    });
  });
  
}

// DELETE AN AGENT
export async function deleteAgent(id, agent_requesting, email, setAlertCustom, setAlertInfo, handleClose, reset, handleUpdateFromComp) {
  var apiPath = "/api/v1/agents";
  return new Promise((resolve, reject) => {
    newUrl = url + apiPath + "?id=" + String(id) + "&agent_requesting=" + String(agent_requesting) + "&email=" + email;
    reset("agent");

    fetch(newUrl, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", },
    })
    .then((response) => {
      if (response.ok) {
        // Request was successful. Set alert info array
        setAlertInfo(["deleteThing","success","Alert","Agent has been deleted"]);
        // tell user we deleted it and close window
        setAlertCustom(true);
        handleClose();
        // give it time to update before we refresh all data
        setTimeout(function(){ 
          handleUpdateFromComp("agents");
        }, 1000);
 

      } else {
        // Request failed
        throw new Error("Request failed");
      }
    })
    .catch((error) => {
      // Request error
      console.error("Error during API request:", error);
      setAlertInfo(["GeneralError","error","Error",error]);
      setAlertCustom(true);
    });
  });
  
}

//REMOVE HUMAN AUTH ON ACCOUNT
export async function removeAuth(account_id, human_id, onSaveSuccess, onSaveError) {
  var apiPath = "/api/v1/accounts/" + account_id + "/authed";

  return new Promise((resolve, reject) => {

    newUrl = url + apiPath;
 
    console.log(newUrl);

    fetch(newUrl, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", },
      body: JSON.stringify({
        human_id: human_id,
      }),
    })
    .then((response) => {
      if (response.ok) {
        onSaveSuccess("authed");
      } else {
        // Request failed
        onSaveError("Request failed");
      }
    })
    .catch((error) => {
      // Request error
      console.error("Error during API request:", error);
      onSaveError(error);
    });
  });
  
}

