// Base API url for Admin -> General
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;

// Set vars
var newUrl = "";

// GET CONFIGS / TAGS *************************************************************************************
export async function getStuff(apiPath) {

  return new Promise((resolve, reject) => {
    newUrl = url + apiPath;
    fetch(newUrl)
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.data);
        resolve([data.data]);
      })
      .catch((error) => {
        console.log('Error fetching data:', error);
        reject(error);
      });
  });
 
}

export async function countTickets(someid, what) {
  var apiPath = "/api/v1/tickets/count";
  newUrl = url + apiPath + "?status=pending_agent&" + what + "=" + someid;
  //console.log(someid,what,newUrl);
  return new Promise((resolve, reject) => {
    
    fetch(newUrl)
      .then((response) => response.json())
      .then((data) => {
        //console.log(what,data.count);
        resolve([data.count]);
      })
      .catch((error) => {
        console.log('Error fetching data:', error);
        reject(error);
      });
  });
 
}