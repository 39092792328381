import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit';


export default function TicketSingleMessages(messages) {
  return (
    <Card sx={{ width: "100%", borderRadius: 'var(--box-border-radius)', boxShadow: 4 }}>
      <CardHeader sx={{background: "var(--headings-fill)", color: "var(--headings-text)"}}
        avatar={
          <Avatar sx={{ bgcolor: "var(--icon-action)", width: 24, height: 24 }} aria-label="requester">
            <Typography sx={{ color: "var(--icon-text)" }}>
              F
            </Typography>
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <EditIcon sx={{color: "var(--headings-text)"}} />
          </IconButton>
        }
        title="Fred Yomama: fred@fred.component"
        subheader="June 06, 2023 22:55"
        subheaderTypographyProps={{ color: 'var(--menu-subtext-visible)' }}
      />

      <CardContent>

        <Typography sx={{ mt: 2, mb: 2, fontSize: 16 }} component="span">
          Can you help me do X, Y and Z with a side of A? I shall be forever happy with your services if you can do these things for me!
        </Typography>
        
        <br />

      </CardContent>

    </Card>
    
  );
}