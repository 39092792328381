// NEEDED THINGS
import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";

// FOR THEME
import {Container, responsiveFontSizes} from '@mui/material';
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

// COMPONENTS
import HeaderAppBar from "../components/HeaderAppBar";
import AdminPortal from '../components/Admin/AdminPortal';
import AdminGeneral from '../components/Admin/AdminGeneral';
import AdminAgents from '../components/Admin/AdminAgents';
import AdminRR from '../components/Admin/AdminRR';
import TicketTable from '../components/TicketTable';
import TicketBoard from '../components/TicketBoard';

// FOR LEFT MENU
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';


// ICONS
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Home from '@mui/icons-material/Home';
import Settings from '@mui/icons-material/Settings';
import People from '@mui/icons-material/People';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PaymentsIcon from '@mui/icons-material/Payments';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AlarmIcon from '@mui/icons-material/Alarm';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeckIcon from '@mui/icons-material/Deck';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import LoopIcon from '@mui/icons-material/Loop';
import ComputerIcon from '@mui/icons-material/Computer';

import Cookies from "js-cookie";

// HELPERS
import { getId } from '../helpers/getId';
// CRUD HAPPINESS
import { getStuff, countTickets } from '../services/get';

// Customer Portal enabled?
const portalEnabled = process.env.REACT_APP_CUSTOMER_PORTAL_ENABLED;

// THEME things *********************************************************************************************

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: -16,
    top: 16,
    border: `1px solid white`,

  },
}));

// GRID things **********************************************************************************************
const GridItemWrapper = styled.div`
  color: var(--headings-text);
  border-radius: var(--box-border-radius);
  box-shadow: 4;
  overflow: hidden;
  `;
const GridItemContent = styled.div`
  padding: 0px;
`;
const ResponsiveGridLayout = WidthProvider(Responsive);

// DEAFULT FUNCTION *****************************************************************************************
export default function HomePage({ setAuth }) {

  const [agentId, setAgentId] = useState(0);
  const [agentProfile, setAgentProfile] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");
  const [selectedAdminPage, setSelectedAdminPage] = useState("Dashboard");
  const [activeLink, setActiveLink] = useState("Dashboard");

  const [openFilters, setOpenFilters] = React.useState(true);
  const [openTasks, setOpenTasks] = React.useState(false);
  const [openCRM, setOpenCRM] = React.useState(false);
  const [openReports, setOpenReports] = React.useState(false);
  const [openAdmin, setOpenAdmin] = React.useState(false);

  // COMPONENT RESIZE STATES *********************************************************************************

  // declare screen size vars
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  // HOME Sizing
  const refMenu = useRef();
  const refTT = useRef();
  const refTB = useRef();
  const refSingle = useRef();
  const [dimensionsMenu, setDimensionsMenu] = useState({ width: 0, height: 0, });
  const [dimensionsTT, setDimensionsTT] = useState({ width: windowSize.width, height: windowSize.height, });
  const [dimensionsTB, setDimensionsTB] = useState({ width: 0, height: 0, });
  const [dimensionsSingle, setDimensionsSingle] = useState({ width: 0, height: 0, });
  

  // HOME Calcs
  var hMenu = dimensionsMenu.height/10;
  var hTT = (dimensionsTT.height/10)+2; //+2 added for shadow drop
  var hTB = dimensionsTB.height/11;
  var hSingle = dimensionsSingle.height/11;
  if (hMenu < 7) { hMenu = 7;}
  if (hTT < 7) { hTT = 7;}
  if (hTB < 7) { hTB = 7;}
  if (hSingle < 7) { hSingle = 7;}

  if (
    selectedAdminPage === "TicketTable" || 
    selectedAdminPage === "My Tickets" || 
    selectedAdminPage === "Portal" ||
    selectedAdminPage === "General" ||
    selectedAdminPage === "Agents" ||
    selectedAdminPage === "Round Robin"
    ) {
      var layoutGuts = [
        { i: "Menu", x: 0, y: 0, w: 2, h: hMenu, minW: 1.5, minH: 7}, //menu
        { i: "TT", x: 2, y: 0, w: 10, h: hTT, minW: 5, minH: 7}, //content
      ];
  }
  if ((!selectedAdminPage && !selectedDept) || selectedAdminPage === 'Dashboard') {
    var layoutGuts = [
      { i: "Menu", x: 0, y: 0, w: 2, h: hMenu, minW: 1.5, minH: 7}, //menu
      { i: "TB", x: 2, y: 0, w: 3, h: hTB, minW: 2, minH: 7}, //my tickets
      { i: "Single", x: 5, y: 0, w: 7, h: hSingle, minW: 1.5, minH: 7},
    ];
  }
  const layout = layoutGuts;
 
  // BUILD URL
  const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
  const appName = process.env.REACT_APP_ACT_NAME;

  // GET DEPARTMENTS
  const [dpts, setDpts] = useState([]);
  function refreshDepts() {
    getStuff("/api/admin/depts")
      .then((data) => { setDpts(data[0]); })
      .catch((error) => { console.error("Error fetching tags:", error); });
  }

  // get ticket counts for menu badges
  const [ticketCountsDept, setTicketCountsDept] = useState({});
  const [ticketCountsAgent, setTicketCountsAgent] = useState(0);
  const refreshTicketCounts = async () => {
    const counts = {...ticketCountsDept}; // Copy existing counts
    for (const dept of dpts) {
      try {
        const count = await countTickets(dept.name, "department");
        counts[dept.name] = count;
      } catch (error) {
        console.error('Error fetching ticket count:', error);
        counts[dept.name] = 'Error';
      }
    }
    setTicketCountsDept(counts);

    const countMine = await countTickets(agentId, "agent_id");
    setTicketCountsAgent(countMine);
    
  };

// GET once on pageload (depts, agentId, agentProfile)
  useEffect(() => { 
    // Get agent email
    const agentEmail = Cookies.get("agent_email");
    refreshDepts();
    getId(agentEmail,1)
      .then((dataid) => {
      setAgentId(dataid);
      getStuff("/api/agent/profile?id=" + dataid)
      .then((data) => {
        setAgentProfile(data[0][0]);
      })
      .catch((error) => { console.error("Error fetching id:", error); });
      })
      .catch((error) => { console.error("Error fetching id:", error); });
  }, []);

  useEffect(() => {
    refreshTicketCounts(); 
    //const interval = setInterval(refreshTicketCounts, 60000); // Refresh every minute
    //return () => clearInterval(interval); 
  }, [dpts]);



 // resize things as content changes
function reSize(what) {
  if (what === "Menu") {var ref = refMenu; var dim = dimensionsMenu;}
  if (what === "TB") {var ref = refTB; var dim = dimensionsTB;}
  if (what === "TT") {var ref = refTT; var dim = dimensionsTT;}
  if (what === "Single") {var ref = refSingle; var dim = dimensionsSingle;}

  if (ref.current) {
    if ( ref.current.offsetWidth != dim.width || ref.current.offsetHeight != dim.height ) {
      if (what === "Menu") { setDimensionsMenu({ width: ref.current.offsetWidth, height: ref.current.offsetHeight }); }
      if (what === "TB") { setDimensionsTB({ width: ref.current.offsetWidth, height: ref.current.offsetHeight, }); }
      if (what === "TT" ) { setDimensionsTT({ width: ref.current.offsetWidth, height: ref.current.offsetHeight, }); }
      if (what === "Single") { setDimensionsSingle({ width: ref.current.offsetWidth, height: ref.current.offsetHeight, }); }
   }
  }
  
}

// resize of content for Menu
useEffect(() => {
  reSize("Menu");
}, [dpts, openFilters, openTasks, openCRM, openReports, openAdmin]);

// resize of content for TT (TicketTable)
useEffect(() => {
  reSize("TT");
}, [selectedDept, selectedAdminPage]);


// FUNCTION for link click in menu  
const handleLinkClickMenu = (page, dept) => {
  setSelectedAdminPage(page);
  
  if (dept) { 
    setActiveLink(dept);
    setSelectedDept(dept);
  } else {
    setActiveLink(page);
    setSelectedDept("");
  }
  console.log(page,dept);

};

// RETURN  **********************************************************************************************************************************
 
return(
    <Container maxWidth={false} disableGutters sx={{backgroundColor: "var(--bg-fill)", height: "100vh", marginRight: '10px'}}>

    {/* TOP BAR */}
    { agentProfile && (
    <HeaderAppBar agentProfile={agentProfile} />
    )}

    <ResponsiveGridLayout
      layouts={{ lg: layout }}
      autoSize={true}
      isResizable={true}
      draggableHandle={'.DragByThis'}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
      rowHeight={1}
      isDraggable={true}
      margin={[10, 10]}
      useCSSTransforms={true}
      width={windowSize.width}
    >

      {/* MENU */}
      <GridItemWrapper key="Menu" style={{cursor: 'default', backgroundColor: "var(--headings-fill)"}}>
          <GridItemContent ref={refMenu} sx={{marginRight: '0px'}}>

            <ListItemButton className="DragByThis" component="a" sx={{ height: 70, py: 0, cursor: 'grab'}}>
              {/* TICKET ICON */}
              <ListItemIcon>
                  <ConfirmationNumberIcon sx={{ fontSize: 20, color: "var(--ticket-icon)" }}/>
              </ListItemIcon>
              <ListItemText sx={{ my: 0, ml: -3 }}
                  primary = {appName}
                  primaryTypographyProps={{ fontSize: 19, fontWeight: 'medium', variant: 'body2' }}/>
            </ListItemButton>

            <Divider sx={{ backgroundColor: "var(--menu-divider)" }} />

            {/* TICKET BOARD DASHBOARD SECTION ********************************************************************************************************************** */}
            <ListItem component="div" disablePadding >
                <a href="#" onClick={() => handleLinkClickMenu('Dashboard',null)} style={{ textDecoration: 'none' }}>
                    <ListItemButton key='Dashboard' sx={{ 
                        height: 50,
                        py: 0,
                        minHeight: 32,
                        color: activeLink === 'Dashboard' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>
                        {/* House Icon */}
                        <ListItemIcon sx={{ color: 'inherit' }} ><Home /></ListItemIcon>
                        {/* DashBoard */}
                        <ListItemText primary="Dashboard"
                            sx={{ color: activeLink === 'Dashboard' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)',}}
                            primaryTypographyProps={{ fontSize: 16.5, fontWeight: 'medium', variant: 'body2', }}/>
                    </ListItemButton>
                </a>

                {/* Setting gear 
                <Tooltip title="Filter Settings">
                    <IconButton
                        size="large" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()} sx={{
                        '& svg': { color: 'var(--headings-text)', transition: '0.2s', transform: 'translateX(0) rotate(0)', },
                        '&:hover, &:focus': { bgcolor: 'unset', '& svg:first-of-type': { transform: 'translateX(-4px) rotate(-20deg)', },
                        '& svg:last-of-type': { right: 0, opacity: 1, },
                        },
                        '&:after': { content: '""', position: 'absolute', height: '80%', display: 'block', left: 0, width: '1px', bgcolor: 'divider', },
                        }}>
                        <Settings />
                        <ArrowRight sx={{ position: 'absolute', right: 4, opacity: 0 }} />
                    </IconButton>
                </Tooltip>*/}
            </ListItem>
   
            <Divider sx={{ backgroundColor: "var(--menu-divider)" }} />

            {/* MENU DEPARTMENTS *********************************************************************************************************************************** */}
            <Box sx={{ bgcolor: openFilters ? 'var(--menu-open)' : null, pb: openFilters ? 2 : 0, }}>

                <ListItemButton alignItems="flex-start" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()} onClick={() => setOpenFilters(!openFilters)}
                    sx={{ px: 3, pt: 2.5, pb: openFilters ? 0 : 2.5, '&:hover, &:focus': { '& svg': { opacity: openFilters ? 1 : 0 } }, }}>
    
                    <ListItemText primary="Filters" primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium', lineHeight: '18px', mb: '2px', }}
                        secondary="Support, Billing, Engineering, Sales"
                        secondaryTypographyProps={{ noWrap: true, fontSize: 12, lineHeight: '16px', color: openFilters ? 'var(--menu-subtext-hidden)' : 'var(--menu-subtext-visible)', }} sx={{ my: 0 }}/>
  
                    <KeyboardArrowDown sx={{ mr: -1, opacity: 0, transform: openFilters ? 'rotate(-180deg)' : 'rotate(0)', transition: '0.2s', }}/>
                </ListItemButton>

                {/* My tickets */}
                <a key="My Tickets" href="#" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('My Tickets',null)} style={{ textDecoration: 'none' }}>
                  <ListItemButton key="My Tickets" 
                        sx={{ py: 0, minHeight: 32, color: activeLink === "My Tickets" ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>
                    <ListItemIcon sx={{ color: 'inherit' }}>
                        <SupportAgentIcon />
                    </ListItemIcon>
                    <StyledBadge badgeContent={ticketCountsAgent} color='secondary'>
                      <ListItemText primary="My Tickets" primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }}/>   
                    </StyledBadge>    
                </ListItemButton>
                </a>

                {/* Dept Loop */}
                {openFilters &&
                dpts.map((deptrow) => (
                    <a key={deptrow.id} href="#" onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('TicketTable',deptrow.name)} style={{ textDecoration: 'none' }}>

                    <ListItemButton key={deptrow.id} 
                        sx={{ py: 0, minHeight: 32, color: activeLink === deptrow.name ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                        <ListItemIcon sx={{ color: 'inherit' }}>
                            {deptrow.icon === "AssignmentIcon" && <AssignmentIcon /> }
                            {deptrow.icon === "People" && <People /> }
                            {deptrow.icon === "EngineeringIcon" && <EngineeringIcon /> }
                            {deptrow.icon === "PaymentsIcon" && <PaymentsIcon /> }
                            {deptrow.icon === "TrendingUpRoundedIcon" && <TrendingUpRoundedIcon /> }
                            {deptrow.icon === "MilitaryTechIcon" && <MilitaryTechIcon /> }
                            {deptrow.icon === "AccountTreeIcon" && <AccountTreeIcon /> }
                            {deptrow.icon === "DeckIcon" && <DeckIcon />}
                            {deptrow.icon === "DesignServicesIcon" && <DesignServicesIcon /> }
                        </ListItemIcon>
                        
                        <StyledBadge badgeContent={ticketCountsDept[deptrow.name]} color='primary'>
                          <ListItemText primary={deptrow.name} primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }}/>
                        </StyledBadge>
                    </ListItemButton>
                    </a>
                ))}
               <br />
            </Box>
           

            <Divider sx={{ backgroundColor: "var(--menu-divider)" }} />

            {/* MENU TASKS *********************************************************************************************************************************** */}
            <Box sx={{ bgcolor: openTasks ? 'var(--menu-open)' : null, pb: openTasks ? 2 : 0, }}>
              
              {/* MENU Tasks Header */}
                <ListItemButton onMouseDown={e => e.stopPropagation()} alignItems="flex-start" onClick={() => setOpenTasks(!openTasks)}
                sx={{ px: 3, pt: 2.5, pb: openTasks ? 0 : 2.5, '&:hover, &:focus': { '& svg': { opacity: openTasks ? 1 : 0 } }, }}>

                      <ListItemText primary="Tasks" primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium', lineHeight: '18px', mb: '2px', }}
                  
                      secondary="By Date/Time, By Dept, By Workflow, By Agent"
                      secondaryTypographyProps={{ noWrap: true, fontSize: 12, lineHeight: '16px', color: openTasks ? 'var(--menu-subtext-hidden)' : 'var(--menu-subtext-visible)', }}
                      sx={{ my: 0 }} />

                      <KeyboardArrowDown 
                      sx={{ mr: -1, opacity: 0, transform: openTasks ? 'rotate(-180deg)' : 'rotate(0)', transition: '0.2s', }}/>
                </ListItemButton>
              
              {openTasks && (
              <>
              {/* MENU By Date/Time */}
                <a key='By Date/Time' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('By Date/Time')} style={{ textDecoration: 'none' }}> 
                <ListItemButton key='By Date/Time' 
                    sx={{ py: 0, minHeight: 32, color: activeLink === 'By Date/Time' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                    <ListItemIcon sx={{ color: 'inherit' }}><AlarmIcon /></ListItemIcon>

                    <ListItemText primary='By Date/Time' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                </ListItemButton>
               </a> 

              {/* MENU By Dept */}
                <a key='By Dept' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('By Dept')} style={{ textDecoration: 'none' }}> 
                <ListItemButton key='By Dept' 
                    sx={{ py: 0, minHeight: 32, color: activeLink === 'By Dept' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                    <ListItemIcon sx={{ color: 'inherit' }}> <ArtTrackIcon /></ListItemIcon>

                    <ListItemText primary='By Dept' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                </ListItemButton>
              </a> 

              {/* MENU By Workflow */}
                <a key='By Workflow' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('By Workflow')} style={{ textDecoration: 'none' }}> 
                <ListItemButton key='By Workflow' 
                    sx={{ py: 0, minHeight: 32, color: activeLink === 'By Workflow' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                    <ListItemIcon sx={{ color: 'inherit' }}><AccountTreeIcon /></ListItemIcon>

                    <ListItemText primary='By Workflow' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                </ListItemButton>
               </a> 

                {/* MENU By Client */}
                <a key='By Client' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('By Client')} style={{ textDecoration: 'none' }}> 
                <ListItemButton key='By Client' 
                    sx={{ py: 0, minHeight: 32, color: activeLink === 'By Client' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                    <ListItemIcon sx={{ color: 'inherit' }}><People /></ListItemIcon>

                    <ListItemText primary='By Client' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                </ListItemButton>
               </a> 

              {/* MENU By Agent */}
                <a key='By Agent' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('By Agent')} style={{ textDecoration: 'none' }}> 
                <ListItemButton key='By Agent' 
                    sx={{ py: 0, minHeight: 32, color: activeLink === 'By Agent' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                    <ListItemIcon sx={{ color: 'inherit' }}><SupportAgentIcon /></ListItemIcon>

                    <ListItemText primary='By Agent' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                </ListItemButton>
               </a> 
              </>
              )}
            </Box>

            <Divider sx={{ backgroundColor: "var(--menu-divider)" }} />

            {/* MENU CRM *********************************************************************************************************************************** */}
            <Box sx={{ bgcolor: openCRM ? 'var(--menu-open)' : null, pb: openCRM ? 2 : 0, }}>
           
                <ListItemButton alignItems="flex-start" onMouseDown={e => e.stopPropagation()} onClick={() => setOpenCRM(!openCRM)}
                sx={{ px: 3, pt: 2.5, pb: openCRM ? 0 : 2.5, '&:hover, &:focus': { '& svg': { opacity: openCRM ? 1 : 0 } }, }}>
    
                  <ListItemText primary="Clients" primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium', lineHeight: '18px', mb: '2px', }}
                  // CRM sub-text
                  secondary="Clients, Accounts"
                  secondaryTypographyProps={{ noWrap: true, fontSize: 12, lineHeight: '16px', color: openCRM ? 'var(--menu-subtext-hidden)' : 'var(--menu-subtext-visible)', }} sx={{ my: 0 }}/>
  
                  <KeyboardArrowDown sx={{ mr: -1, opacity: 0, transform: openCRM ? 'rotate(-180deg)' : 'rotate(0)', transition: '0.2s', }}/>
                </ListItemButton>
              
            </Box>

            <Divider sx={{ backgroundColor: "var(--menu-divider)" }} />

            {/* MENU REPORTS *********************************************************************************************************************************** */}
            <Box sx={{ bgcolor: openReports ? 'var(--menu-open)' : null, pb: openReports ? 2 : 0, }}>
              
                <ListItemButton alignItems="flex-start" onMouseDown={e => e.stopPropagation()} onClick={() => setOpenReports(!openReports)}
                sx={{ px: 3, pt: 2.5, pb: openReports ? 0 : 2.5, '&:hover, &:focus': { '& svg': { opacity: openReports ? 1 : 0 } }, }}>
    
                  <ListItemText primary="Reports" primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium', lineHeight: '18px', mb: '2px', }}
                  // CRM sub-text
                  secondary="Tickets, Agents, Clients, Companies/Groups"
                  secondaryTypographyProps={{ noWrap: true, fontSize: 12, lineHeight: '16px', color: openReports ? 'var(--menu-subtext-hidden)' : 'var(--menu-subtext-visible)', }} sx={{ my: 0 }}/>
  
                  <KeyboardArrowDown sx={{ mr: -1, opacity: 0, transform: openReports ? 'rotate(-180deg)' : 'rotate(0)', transition: '0.2s', }}/>
                </ListItemButton>
              
            </Box>

            <Divider sx={{ backgroundColor: "var(--menu-divider)" }} />

            {/* MENU ADMIN *********************************************************************************************************************************** */}
            <Box sx={{ bgcolor: openAdmin ? 'var(--menu-open)' : null, pb: openAdmin ? 2 : 0, }}>
         
                <ListItemButton alignItems="flex-start" onMouseDown={e => e.stopPropagation()} onClick={() => setOpenAdmin(!openAdmin)}
                    sx={{ px: 3, pt: 2.5, pb: openAdmin ? 0 : 2.5, '&:hover, &:focus': { '& svg': { opacity: openAdmin ? 1 : 0 } }, }}>

                    <ListItemText primary="Admin" primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium', lineHeight: '18px', mb: '2px', }}
                        secondary="General, Emails, Departments, Queues"
                        secondaryTypographyProps={{ noWrap: true, fontSize: 12, lineHeight: '16px', color: openAdmin ? 'var(--menu-subtext-hidden)' : 'var(--menu-subtext-visible)', }} sx={{ my: 0 }}/>

                    <KeyboardArrowDown sx={{ mr: -1, opacity: 0, transform: openAdmin ? 'rotate(-180deg)' : 'rotate(0)', transition: '0.2s', }}/>
                </ListItemButton>

                {openAdmin && (
                <>
                {/* MENU ADMIN GENERAL */}
                <a key='General' href="#" onClick={() => handleLinkClickMenu('General',null)} style={{ textDecoration: 'none' }}>
                    <ListItemButton key='General' 
                        sx={{ py: 0, minHeight: 32, color: activeLink === 'General' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                        <ListItemIcon sx={{ color: 'inherit' }}><FactCheckIcon /></ListItemIcon>

                        <ListItemText primary='General' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                    </ListItemButton>
                </a>

                {/* MENU PORTAL */}
                <a key='Portal' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('Portal',null)} style={{ textDecoration: 'none' }}>
                    <ListItemButton key='Portal'
                        sx={{ py: 0, minHeight: 32, color: activeLink === 'Portal' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                        <ListItemIcon sx={{ color: 'inherit' }}><ComputerIcon /></ListItemIcon>

                        <ListItemText primary='Portal' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                    </ListItemButton>
                </a>

                {/* MENU ADMIN AGENTS */}
                <a key='Agents' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('Agents',null)} style={{ textDecoration: 'none' }}> 
                    <ListItemButton key='Agents' 
                        sx={{ py: 0, minHeight: 32, color: activeLink === 'Agents' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                        <ListItemIcon sx={{ color: 'inherit' }}><SupportAgentIcon /></ListItemIcon>

                        <ListItemText primary='Agents' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                    </ListItemButton>
                </a>

                {/* MENU ADMIN Round Robin */}
                <a key='Round Robin' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('Round Robin',null)} style={{ textDecoration: 'none' }}> 
                    <ListItemButton key='Round Robin' 
                        sx={{ py: 0, minHeight: 32, color: activeLink === 'Round Robin' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                        <ListItemIcon sx={{ color: 'inherit' }}><LoopIcon /></ListItemIcon>

                        <ListItemText primary='Round Robin' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                    </ListItemButton>
                </a>

                {/* MENU ADMIN QUEUES/FILTERS */}
                <a key='Queues/Filters' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('Queues/Filters',null)} style={{ textDecoration: 'none' }}> 
                    <ListItemButton key='Queues/Filters' 
                        sx={{ py: 0, minHeight: 32, color: activeLink === 'Queues/Filters' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                        <ListItemIcon sx={{ color: 'inherit' }}><FilterAltIcon /></ListItemIcon>

                        <ListItemText primary='Queues/Filters' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                    </ListItemButton>
                </a>

                {/* AUTOMATIONS */}
                <a key='Automations' href="#" onMouseDown={e => e.stopPropagation()} onClick={() => handleLinkClickMenu('Automations',null)} style={{ textDecoration: 'none' }}> 
                    <ListItemButton key='Automations' 
                        sx={{ py: 0, minHeight: 32, color: activeLink === 'Automations' ? 'var(--menu-link-active)' : 'var(--menu-link-inactive)' }}>

                        <ListItemIcon sx={{ color: 'inherit' }}><SmartToyIcon /></ListItemIcon>

                        <ListItemText primary='Automations' primaryTypographyProps={{ fontSize: 16, fontWeight: 'medium' }} />
                    </ListItemButton>
                </a>
                <br /><br />
                </>
                )}
                
            </Box>

          </GridItemContent>

          <div className="dragarrow">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
          </div>

        </GridItemWrapper>


      {/* TICKET BOARD */}  
      {selectedAdminPage === 'Dashboard' && (
          <GridItemWrapper key="TB">
            <GridItemContent ref={refTB}>
              {/* DEFAULT */} 
              <TicketBoard 
                apiUrl={url+'/api/v1/tickets?agent_id='+ agentId +'&status=pending_agent'} 
                agentId={agentId} 
                selectedDept="" 
                reSize={reSize}
              />
            </GridItemContent>
            <div className="dragarrowDark">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
                <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
              </svg>
            </div>
          </GridItemWrapper>
      )}


      {/* TICKET TABLE */}  
      {selectedAdminPage === "My Tickets" && (
        <GridItemWrapper key="TT">
          <GridItemContent ref={refTT}>
            <TicketTable 
              apiUrl={url+'/api/v1/tickets'} 
              agentId={agentId} 
              selectedDept=""
              reSize={reSize}
          />
          </GridItemContent>
          <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
           </div>
        </GridItemWrapper>
      )}  


      {/* TICKET TABLE */}  
      {selectedAdminPage === "TicketTable" && (
        <GridItemWrapper key="TT">
          <GridItemContent ref={refTT}>
            <TicketTable 
              apiUrl={url+'/api/v1/tickets'} 
              agentId={agentId} 
              selectedDept={selectedDept}
              reSize={reSize}
          />
          </GridItemContent>
          <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
           </div>
        </GridItemWrapper>
      )}

    
      {/* TICKET SINGLE */}  
      {selectedAdminPage === 'Dashboard' && (
        <GridItemWrapper key="Single">
          <GridItemContent ref={refSingle}>

              num3


      
          </GridItemContent>
        </GridItemWrapper>
      )}

     {/* Admin GENERAL */}  
     {selectedAdminPage === "General" && (
        <GridItemWrapper key="TT">
          <GridItemContent ref={refTT}>
          <AdminGeneral 
            reSize={reSize} 
          />
          </GridItemContent>
          <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
           </div>
        </GridItemWrapper>
      )}

    {/* Admin PORTAL */}   
    {selectedAdminPage === "Portal" && (
        <GridItemWrapper key="TT">
          <GridItemContent ref={refTT}>
            <AdminPortal 
              dpts={dpts} 
              reSize={reSize}
            />
          </GridItemContent>
          <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
           </div>
        </GridItemWrapper>
      )}

    {/* ADMIN -> AGENTS */}
    {selectedAdminPage === "Agents" && (
        <GridItemWrapper key="TT">
          <GridItemContent ref={refTT}>
            <AdminAgents 
              dpts={dpts} 
              reSize={reSize}
            />
          </GridItemContent>
          <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
           </div>
        </GridItemWrapper>
      )}

      {/* ADMIN -> RR */}
      {selectedAdminPage === "Round Robin" && (
        <GridItemWrapper key="TT">
          <GridItemContent ref={refTT}>
          <AdminRR 
            dpts={dpts}
            reSize={reSize}
          />
          </GridItemContent>
          <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
           </div>
        </GridItemWrapper>
      )}
     
    </ResponsiveGridLayout>
    
  </Container>
 
 );
}           