import React, { useState, useEffect, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Cookies from "js-cookie";
//Cards
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LoopIcon from '@mui/icons-material/Loop';
import TodayIcon from '@mui/icons-material/Today';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
// Components
import AlertCustom from '../AlertCustom.js';
import Agents from './AdminAgents/Agents';
import AgentsRR from './AdminAgents/AgentsRR';
// CRUD HAPPINESS
import { getStuff } from '../../services/get';
import { postEmails, postTags, postDepts, postWorkflows, postReplies } from '../../services/post';
import {getId} from '../../helpers/getId';


// COLOR PICKER DATA *****************************************************************************************************************************************

// Checkmark and Colors imported
import DoneIcon from '@mui/icons-material/Done';
import { 
  red, pink, purple, deepPurple, 
  indigo, blue, lightBlue, cyan, 
  teal, green, lightGreen, lime, 
  yellow, amber, orange, deepOrange 
} from '@mui/material/colors';

// Color Vars
const redc = red[400];
const pinkc = pink[400];
const purplec = purple[400];
const deepPurplec = deepPurple[400];
const indigoc = indigo[400];
const bluec = blue[400];
const lightBluec = lightBlue[400];
const cyanc = cyan[400];
const tealc = teal[400];
const greenc = green[400];
const lightGreenc = lightGreen[400];
const limec = lime[400];
const yellowc = yellow[400];
const amberc = amber[400];
const orangec = orange[400];
const deepOrangec = deepOrange[400];

// grid things ************************************************************************************************************************

const GridItemWrapperChild = styled.div`
  color: var(--headings-text);
  border-radius: var(--box-border-radius);
  box-shadow: 4;
  overflow: hidden;
`;
const GridItemContentChild = styled.div`
  padding: 0px;
`;
const ResponsiveGridLayoutChild = WidthProvider(Responsive);

// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function AdminRR({dpts, reSize}) {
  
  // get size of boxes
  const refSizeByQueue = useRef();
  const refSizeByAgent = useRef();
  const refSizeByDay = useRef();
  const refSizeVaca = useRef();
  const [dimensionsByQueue, setDimensionsByQueue] = useState({ width: 0, height: 0, });
  const [dimensionsByAgent, setDimensionsByAgent] = useState({ width: 0, height: 0, });
  const [dimensionsByDay, setDimensionsByDay] = useState({ width: 0, height: 0, });
  const [dimensionsVaca, setDimensionsVaca] = useState({ width: 0, height: 0, });

   // grid calcs for content
   var hByQueue = dimensionsByQueue.height/11;
   var hByAgent = dimensionsByAgent.height/11;
   var hByDay = dimensionsByDay.height/11;
   var hVaca = dimensionsVaca.height/11;
  if (hByQueue < 11) { hByQueue = 12;} else { hByQueue = hByQueue+2.3;}
  if (hByAgent < 11) { hByAgent = 12;} else { hByAgent = hByAgent+2.3;}
  if (hByDay < 11) { hByDay = 12;} else { hByDay = hByDay+2.3;}
  if (hVaca < 11) { hVaca = 12;} else { hVaca = hVaca+2.3;}

  const layoutChild = [
    { i: "queue", x: 0, y: 0, w: 6, minW: 3, h: hByQueue, minH: 12},
    { i: "agent", x: 6, y: 0, w: 6, h: hByAgent, minH: 12 },
  
    { i: "day", x: 0, y: 1, w: 6, minW: 3, h: hByDay, minH: 12 },
    { i: "vaca", x: 6, y: 1, w: 6, h: hVaca, minH: 12 },
  ];

  const layoutChildmd = [
    { i: "queue", x: 0, y: 0, w: 5, minW: 3, h: hByQueue, minH: 12},
    { i: "agent", x: 5, y: 0, w: 5, h: hByAgent, minH: 12 },
  
    { i: "day", x: 0, y: 1, w: 5, minW: 3, h: hByDay, minH: 12 },
    { i: "vaca", x: 5, y: 1, w: 5, h: hVaca, minH: 12 },
  ];

  const layoutChildsm = [
    { i: "queue", x: 0, y: 0, w: 5, minW: 3, h: hByQueue, minH: 12},
    { i: "agent", x: 0, y: 1, w: 5, h: hByAgent, minH: 12 },
    { i: "day", x: 0, y: 2, w: 5, minW: 3, h: hByDay, minH: 12 },
    { i: "vaca", x: 0, y: 3, w: 5, h: hVaca, minH: 12 },
  ];

  // Get agent email and id
  const agentEmail = Cookies.get("agent_email");
  const [agentId, setAgentId] = useState(null);

  // Vars for agent form add
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [timezone, setTimeZone] = useState(null);

  // Vars for RR schedules add
  const [rrAgent, setRRAgent] = useState(null);
  const [rrQueue, setRRQueue] = useState(null);
  const [rrSticky, setRRSticky] = useState(false);
  const [rrStartDay, setRRStartDay] = useState(null);
  const [rrStartTime, setRRStartTime] = useState(null);
  const [rrLength, setRRLength] = useState(null);
  const [rrEnable, setRREnable] = useState(false);

  // Vars for Vaca schedules add
  const [vacaAgent, setVacaAgent] = useState(null);
  const [vacaStartDay, setVacaStartDay] = useState(null);
  const [vacaStartTime, setVacaStartTime] = useState(null);
  const [vacaLength, setVacaLength] = useState(null);
 
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  // Vars for Dialog Popups
  const [openAgent, setOpenAgent] = useState(false);
  const [openRR, setOpenRR] = useState(false);
  const [openVaca, setOpenVaca] = useState(false);

  // ACTIONS ************************************************************************************************************************************************

  // Handle open in Dialog popup clicked
  function handleClickOpen(what){ 
    if (what === "agent") {setOpenAgent(true); }
    if (what === "rr") {setOpenRR(true); }
    if (what === "vaca") {setOpenVaca(true); }
  };
  
  // When form is closed or cancelled
  function handleClose(what){
    if (what === "agent") {setOpenAgent(false); }
    if (what === "rr") {setOpenRR(false); }
    if (what === "vaca") {setOpenVaca(false); }
  };
  

  // GET OR UPDATE DATA  **********************************************************************************************************************************
  
  // Arrays
  const [agents, setAgents] = useState([]);
  const [rrArray, setRRArray] = useState([]);
  const [vacaArray, setVacaArray] = useState([]);
   
  function refreshAgents() {
    getStuff("/api/agent/rud")
      .then((data) => { setAgents(data[0]); })
      .catch((error) => { console.error("Error fetching agents:", error); });
  }

  function refreshRR() {
    getStuff("/api/rr")
      .then((data) => { setRRArray(data[0]); })
      .catch((error) => { console.error("Error fetching RR:", error); });
  }

  function refreshVaca() {
    getStuff("/api/vaca")
      .then((data) => { 
        setVacaArray(data[0]); 
        setTimeout(function(){
          reSize("TT");
        },1000);
      })
      .catch((error) => { console.error("Error fetching Vaca:", error); });
  }


  // GET once on pageload
  useEffect(() => { refreshAgents(); refreshRR(); refreshVaca(); getId(agentEmail,1).then((dataid) => {setAgentId(dataid);}) }, []);

  useEffect(() => {
    if (refSizeByQueue.current) {
      if ( refSizeByQueue.current.offsetWidth != dimensionsByQueue.width || refSizeByQueue.current.offsetHeight != dimensionsByQueue.height ) {
        setDimensionsByQueue({ width: refSizeByQueue.current.offsetWidth, height: refSizeByQueue.current.offsetHeight, });
        reSize("TT");
      }
    }
    if (refSizeByAgent.current) {
      if ( refSizeByAgent.current.offsetWidth != dimensionsByAgent.width || refSizeByAgent.current.offsetHeight != dimensionsByAgent.height ) {
        setDimensionsByAgent({ width: refSizeByAgent.current.offsetWidth, height: refSizeByAgent.current.offsetHeight, });
        reSize("TT");
      }
    }
    if (refSizeByDay.current) {
      if ( refSizeByDay.current.offsetWidth != dimensionsByDay.width || refSizeByDay.current.offsetHeight != dimensionsByDay.height ) {
        setDimensionsByDay({ width: refSizeByDay.current.offsetWidth, height: refSizeByDay.current.offsetHeight, });
        reSize("TT");
      }
    }
  }, [rrArray]);

  useEffect(() => {
    if (refSizeVaca.current) {
      if ( refSizeVaca.current.offsetWidth != dimensionsVaca.width || refSizeVaca.current.offsetHeight != dimensionsVaca.height ) {
        setDimensionsVaca({ width: refSizeVaca.current.offsetWidth, height: refSizeVaca.current.offsetHeight, });
        reSize("TT");
      }
    }
  }, [vacaArray]);

  // call "handleUpdateFromComp" from component to refresh data
  function handleUpdateFromComp(componentCode) {
    if (componentCode === "agents") {  refreshAgents(); }
    if (componentCode === "rr") {  refreshRR(); }
    if (componentCode === "vaca") { refreshVaca(); }
  }

  // When reset a dialog form is needed
  function reset(what) {
    if (what === "agent") { setEmail(null); setPassword(null); setFirstName(null); setLastName(null); setDisplayName(null); setPhone(null); setTimeZone(null); handleClose("agent");}
    if (what === "rr") { setRRAgent(null); setRRQueue(null); setRRSticky(false); setRRStartDay(null); setRRStartTime(null); setRRLength(null); setRREnable(false); handleClose("rr");}
    if (what === "vaca") { setVacaAgent(null); setVacaStartDay(null); setVacaStartTime(null); setVacaLength(null); handleClose("vaca");}
  };

   
  // DATA EVENTS  **********************************************************************************************************************************
  
  // AGENT: email changes: 'humans_staff.email' (email)
  const handleEmailChange = (event) => { setEmail(event.target.value); };
  // AGENT: password changes: 'humans_staff.email' (password)
  const handlePasswordChange = (event) => { setPassword(event.target.value); };
  // AGENT: firstName changes: 'humans_staff.email' (firstName)
  const handleFirstNameChange = (event) => { setFirstName(event.target.value); };
  // AGENT: lastName changes: 'humans_staff.email' (lastName)
  const handleLastNameChange = (event) => { setLastName(event.target.value); };
  // AGENT: displayName changes: 'humans_staff.email' (displayName)
  const handleDisplayNameChange = (event) => { setDisplayName(event.target.value); };
  // AGENT: phone changes: 'humans_staff.email' (phone)
  const handlePhoneChange = (event) => { setPhone(event.target.value); };
  // AGENT: timezone changes: 'humans_staff.email' (timezone)
  const handleTimeZoneChange = (event) => { setTimeZone(event.target.value); };

  // RR: agent changes: 'round_robin.human_id' field (rrAgent)
  const handleRRAgentChange = (event) => { setRRAgent(event.target.value); };
  // RR: queue changes: 'round_robin.filter_id' field (rrQueue)
  const handlesetRRQueueChange = (event) => { setRRQueue(event.target.value); };
  // RR: start day changes: 'round_robin.start_day' field (rrStartDay)
  const handleRRStartDayChange = (event) => { setRRStartDay(event.target.value); };
  // RR: start time changes: 'round_robin.start_time' field (rrStartTime)
  const handleRRStartTimeChange = (event) => { setRRStartTime(event.target.value); };
  // RR: length: 'round_robin.length' field (rrLength)
  const handleRRLengthChange = (event) => { setRRLength(event.target.value); };
  // RR: Toggle switch changes: 'round_robin.enabled' field, (rrEnable)
  const handleSwitchRR = (event) => { setRREnable(event.target.checked); if (event.target.checked === false){ setRRAgent(null); setRRQueue(null); setRRSticky(false); setRRStartDay(null); setRRStartTime(null); }};
  // RR: Sticky Toggle switch changes: 'round_robin.sticky' field, (rrSticky)
  const handleSwitchSticky = (event) => { setRRSticky(event.target.checked); if (event.target.checked === false){ setRRAgent(null); setRRQueue(null); setRREnable(false); setRRStartDay(null); setRRStartTime(null); }};

  // Vaca: agent changes: 'round_robin.human_id' field (vacaAgent)
  const handleVacaAgentChange = (event) => { setVacaAgent(event.target.value); };
  // Vaca: start day changes: 'round_robin.vaca_start_day' field (vacaStartDay)
  const handleVacaStartDayChange = (event) => { setVacaStartDay(event.target.value); };
  // Vaca: start time: 'round_robin.vaca_start_time' field (vacaStartTime)
  const handleVacaStartTimeChange = (event) => { setVacaStartTime(event.target.value); };
  // Vaca: length: 'round_robin.vaca_length' field (vacaLength)
  const handleVacaLengthChange = (event) => { setVacaLength(event.target.value); };



  // RETURN  **********************************************************************************************************************************
  
  return ( 

    <ResponsiveGridLayoutChild
    layouts={{ lg: layoutChild, md: layoutChildmd, sm: layoutChildsm}}
    autoSize={true}
    isResizable={true}
    draggableHandle={'.DragByThisR'}
    breakpoints={{ lg: 1200, md: 996, sm: 480, xs: 240, xxs: 0 }}
    cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
    rowHeight={1}
    isDraggable={true}
    onLayoutChange={() => reSize("TT")}
    onWidthChange={() => reSize("TT")}
    onDragStop={() => reSize("TT")}
    onResizeStop={() => reSize("TT")}
    onBreakpointChange={() => reSize("TT")}
    margin={[20, 10]}
    useCSSTransforms={true}
    >


      {/* ALERT ****************************************************************************************************************************** */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />


      {/* RRByAgent **************************************************************************************************************************** */}
      <GridItemWrapperChild key="agent" >
      <GridItemContentChild>
        <Card ref={refSizeByAgent} sx={{ mb: 2, width: '100%', minWidth: 375, borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisR" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SupportAgentIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ marginLeft: '8px' }}>Round Robin Schedule by Agent</Typography>
              </div>
            }
            action={
              <Tooltip title="Add Agent" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("agent")} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
            }
          />
          {/* DIALOG BOX */}
          <Dialog open={openAgent} onClose={() => handleClose("agent")}>
            <DialogTitle>Add Agent</DialogTitle>
            <DialogContent>
              <DialogContentText>whut</DialogContentText>
              const [rrAgent, setRRAgent] = useState(null);
              const [rrQueue, setRRQueue] = useState(null);
              const [rrSticky, setRRSticky] = useState(false);
              const [rrStartDay, setRRStartDay] = useState(null);
              const [rrStartTime, setRRStartTime] = useState(null);
              const [rrLength, setRRLength] = useState(null);
              const [rrEnable, setRREnable] = useState(false);
            </DialogContent>
            <DialogActions>
              <Button onClick={() => reset("agent")}>Cancel</Button>
              <Button onClick={() => postEmails(setAlertCustom, setAlertInfo, setOpenAgent, refreshAgents, reset)}>Save</Button>
            </DialogActions>
          </Dialog>
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
            {/* Agents Component */}
            <Agents agents={agents} handleUpdateFromComp={handleUpdateFromComp} />

          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>


      {/* RR by Queue**************************************************************************************************************************** */}
      <GridItemWrapperChild key="queue" >
      <GridItemContentChild>
        <Card ref={refSizeByQueue} sx={{ mr: 0, width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisR" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
                        title={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LoopIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                            <Typography sx={{ marginLeft: '8px' }}>Round Robin Schedule by Queue</Typography>
                          </div>
                        }
            action={
              <Tooltip title="Add RR schedule" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("rr")} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
            }
          />
          
          
          {/* DIALOG BOX */}
          <Dialog open={openRR} onClose={() => handleClose("rr")}>
            <DialogTitle>Add RR Schedule</DialogTitle>
            <DialogContent sx={{ ml: "50px", mr: "20px"}}>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => reset("rr")}>Cancel</Button>
              <Button onClick={() => postTags(setAlertCustom, setAlertInfo, setOpenRR, refreshRR, reset)}>Save</Button>
            </DialogActions>
          </Dialog>
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

          {/* AgentsRR Component */}  
          <AgentsRR rrArray={rrArray} handleUpdateFromComp={handleUpdateFromComp} />

          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>


      {/* RR by Day **************************************************************************************************************************** */}
      <GridItemWrapperChild key="day" >
      <GridItemContentChild>
       <Card ref={refSizeByDay} sx={{ mb: 2, width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
        <CardHeader className="DragByThisR" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
                        title={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TodayIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                            <Typography sx={{ marginLeft: '8px' }}>Round Robin Schedule by Day</Typography>
                          </div>
                        }
          />

          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

          {/* RRByDay Component */}
          {/* <RRByDay rrArray={rrArray} handleUpdateFromComp={handleUpdateFromComp} /> */}

          </CardContent>
        </Card>

      </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>


      <GridItemWrapperChild key="vaca" >
      <GridItemContentChild>
        {/* Vaca **************************************************************************************************************************** */}
        <Card ref={refSizeVaca} sx={{ mb: 2, width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
        <CardHeader className="DragByThisR" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
                        title={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PauseCircleOutlineOutlinedIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                            <Typography sx={{ marginLeft: '8px' }}>Vacation Schedule</Typography>
                          </div>
                        }
            action={
              <Tooltip title="Add Vacation Schedule" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("vaca")} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
            }
          />

      {/* DIALOG BOX */}
      <Dialog open={openVaca} onClose={() => handleClose("vaca")}>
        <DialogTitle>Add Vaca</DialogTitle>
        <DialogContent sx={{ ml: "50px", mr: "20px"}}>
    
        </DialogContent>
        <DialogActions>
              <Button onClick={() => reset("vaca")}>Cancel</Button>
              <Button onClick={() => postEmails(setAlertCustom, setAlertInfo, setOpenVaca, refreshVaca, reset)}>Save</Button>
        </DialogActions>
      </Dialog>
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

          {/* AgentVaca Component */}
          {/* <AgentsVaca vacaArray={vacaArray} handleUpdateFromComp={handleUpdateFromComp} /> */}

          </CardContent>
        </Card>

        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>


      </ResponsiveGridLayoutChild>

    

  );
  
}