import React, { useState, useEffect, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Cookies from "js-cookie";
//Cards
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// Components
import AlertCustom from '../AlertCustom.js';
import Agents from './AdminAgents/Agents';
import ArchivedAgents from './AdminAgents/ArchivedAgents';
// CRUD HAPPINESS
import { getStuff } from '../../services/get';
import { postAgent } from '../../services/post';
import { enableAgent } from '../../services/put';
import {getId} from '../../helpers/getId';
// Helpers
import moment from 'moment-timezone';
import { getDisplayName } from '../../helpers/getDisplayName';


const timezoneArray = moment.tz.names();

// grid things ************************************************************************************************************************

const GridItemWrapperChild = styled.div`
  color: var(--headings-text);
  border-radius: var(--box-border-radius);
  box-shadow: 4;
  overflow: hidden;
`;
const GridItemContentChild = styled.div`
  padding: 0px;
`;
const ResponsiveGridLayoutChild = WidthProvider(Responsive);

// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function AdminAgents({dpts, reSize}) {

  // get size of boxes
  const refSizeAgents = useRef();
  const refSizeArch = useRef();
  const refSizeAct = useRef();
  const [dimensionsAgents, setDimensionsAgents] = useState({ width: 0, height: 0, });
  const [dimensionsArch, setDimensionsArch] = useState({ width: 0, height: 0, });
  const [dimensionsAct, setDimensionsAct] = useState({ width: 0, height: 0, });

  // grid calcs for content
  var hAgents = dimensionsAgents.height/11;
  var hArch = dimensionsArch.height/11;
  var hAct = dimensionsAct.height/11;
  // 2.3 added for drop shadow display
  if (hAgents < 11) { hAgents = 12;} else { hAgents = hAgents+2.3;}
  if (hArch < 11) { hArch = 12;} else { hArch = hArch+2.3;}
  if (hAct < 11) { hAct = 12;} else { hAct = hAct+2.3;}
  
  const layoutChild = [
    { i: "agents", x: 0, y: 0, w: 6, minW: 2, h: hAgents, minH: 12 },
    { i: "act", x: 6, y: 0, w: 6, minW: 2, h: hAct, minH: 12 },
    { i: "arch", x: 0, y: 1, w: 6, minW: 2, h: hArch, minH: 12 }
  ];


  // Get agent email and id
  const agentEmail = Cookies.get("agent_email");
  const [agentId, setAgentId] = useState(0);

  // Vars for agent form add
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [timezone, setTimeZone] = useState("");
  
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  // Vars for Dialog Popups
  const [openAgent, setOpenAgent] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openArchivedAgents, setOpenArchivedAgents] = useState(false);
  const [openAskAThing, setOpenAskAThing] = useState(false);

  // Vars for EmailExists Data Dialog (thing_asking, date_disabled, disabler, role)
  const [askAThingInfo, setAskAThingInfo] = useState(["","","",""]);
  
 // A damn space
 const space = " ";

  // ACTIONS ************************************************************************************************************************************************

  // Handle open in Dialog popup clicked
  function handleClickOpen(what){ 
    if (what === "agent") {setOpenAgent(true); }
    if (what === "activity") {setOpenActivity(true); }
    if (what === "archivedagents") {setOpenArchivedAgents(true); }
  };
  
  // When form is closed or cancelled
  function handleClose(what){
    if (what === "agent") {setOpenAgent(false); }
    if (what === "activity") {setOpenActivity(false); }
    if (what === "archivedagents") {setOpenArchivedAgents(false); }
    if (what === "askathing") {setOpenAskAThing(false); }
  };
  

  // GET OR UPDATE DATA  **********************************************************************************************************************************
  
  // Arrays
  const [agents, setAgents] = useState([]);
  const [archivedAgents, setArchivedAgents] = useState([]);
  const [activity, setActivity] = useState([]);
   
  function refreshAgents() {
    getStuff("/api/agent/rud")
      .then((data) => { 
        setAgents(data[0].filter(agent => agent.disabled === 0));
        setArchivedAgents(data[0].filter(agent => agent.disabled === 1));
        setTimeout(function(){
          reSize("TT");
        },1000);
      })
      .catch((error) => { console.error("Error fetching agents:", error); });
  }

  function refreshActivity() {
    //getStuff("/api/agent/activity")
      //.then((data) => { setActivity(data[0]); })
      //.catch((error) => { console.error("Error fetching Activity:", error); });
  }


  // GET once on pageload
  useEffect(() => { refreshAgents(); refreshActivity(); getId(agentEmail,1).then((dataid) => {setAgentId(dataid);}) }, []);

  useEffect(() => {
    if (refSizeAgents.current) {
      if ( refSizeAgents.current.offsetWidth != dimensionsAgents.width || refSizeAgents.current.offsetHeight != dimensionsAgents.height ) {
        setDimensionsAgents({ width: refSizeAgents.current.offsetWidth, height: refSizeAgents.current.offsetHeight, });
        reSize("TT");
      }
    }
  }, [agents]);

  useEffect(() => {
    if (refSizeArch.current) {
      if ( refSizeArch.current.offsetWidth != dimensionsArch.width || refSizeArch.current.offsetHeight != dimensionsArch.height ) {
        setDimensionsArch({ width: refSizeArch.current.offsetWidth, height: refSizeArch.current.offsetHeight, });
        reSize("TT");
      }
    }
  }, [archivedAgents]);

  useEffect(() => {
    if (refSizeAct.current) {
      if ( refSizeAct.current.offsetWidth != dimensionsAct.width || refSizeAct.current.offsetHeight != dimensionsAct.height ) {
        setDimensionsAct({ width: refSizeAct.current.offsetWidth, height: refSizeAct.current.offsetHeight, });
        reSize("TT");
      }
    }
  }, [activity]);
  
  // call "handleUpdateFromComp" from component to refresh data
  function handleUpdateFromComp(componentCode) {
    if (componentCode === "agents") {  refreshAgents(); }
    if (componentCode === "activity") { refreshActivity(); }
  }

  // When reset a dialog form is needed
  function reset(what) {
    if (what === "agent") { setEmail(""); setPassword(""); setFirstName(""); setLastName(""); setDisplayName(""); setRole(""); setPhone(""); setTimeZone(""); handleClose("agent");handleClose("askathing");}
  };

 
  const [displayNameDisabler, setDisplayNameDisabler] = useState("");
  const renderDisplayName = (agent) => {
      // get display name of disabler
      getDisplayName(null,agent)
      .then((dataid) => {
           setDisplayNameDisabler(dataid);
      })
      return displayNameDisabler;
  };

  const renderRole = (role) => {
    switch(Number(role)) {
        case 5: return "Tickets: View Only";
        case 4: return "Tickets: Edit";
        case 3: return "Admin: View Only";
        case 2: return "Admin: Edit";
        case 1: return "Full Admin";
        default: return "";
    }
  };

   
  // DATA EVENTS  **********************************************************************************************************************************
  
  // AGENT: email changes: 'humans.email' (email)
  const handleEmailChange = (event) => { setEmail(event.target.value); };
  // AGENT: password changes: 'humans.email' (password)
  const handlePasswordChange = (event) => { setPassword(event.target.value); };
  // AGENT: firstName changes: 'humans.email' (firstName)
  const handleFirstNameChange = (event) => { setFirstName(event.target.value); };
  // AGENT: lastName changes: 'humans.email' (lastName)
  const handleLastNameChange = (event) => { setLastName(event.target.value); };
  // AGENT: displayName changes: 'humans.email' (displayName)
  const handleDisplayNameChange = (event) => { setDisplayName(event.target.value); };
  // AGENT: role changes: 'humans.role' (role)
  const handleRoleChange = (event) => { setRole(event.target.value); };
  // AGENT: phone changes: 'humans.email' (phone)
  const handlePhoneChange = (event) => { setPhone(event.target.value); };
  // AGENT: timezone changes: 'humans.email' (timezone)
  const handleTimeZoneChange = (event) => { setTimeZone(event.target.value); };
  // EMAIL: Custom pass instead of generated
  const handleSwitchGeneratedPass = (event) => { setGeneratedPass(event.target.checked); };


  const [generatedPass, setGeneratedPass] = useState(false);
  //console.log(askAThingInfo);
  //console.log(askAThingInfo[0]);
  // RETURN  **********************************************************************************************************************************
  
  return ( 

    <ResponsiveGridLayoutChild
    layouts={{ lg: layoutChild }}
    autoSize={true}
    isResizable={true}
    draggableHandle={'.DragByThisA'}
    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
    cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
    rowHeight={1}
    isDraggable={true}
    onLayoutChange={() => reSize("TT")}
    onWidthChange={() => reSize("TT")}
    onDragStop={() => reSize("TT")}
    onResizeStop={() => reSize("TT")}
    onBreakpointChange={() => reSize("TT")}
    margin={[20, 10]}
    useCSSTransforms={true}
    >


      {/* ALERT ****************************************************************************************************************************** */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />


      <GridItemWrapperChild key="agents" >
      <GridItemContentChild>

        {/* Agents **************************************************************************************************************************** */}
        <Card ref={refSizeAgents} sx={{ width: '100%', minWidth: 375, borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisA" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SupportAgentIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ marginLeft: '8px' }}>Agents</Typography>
              </div>
            }
            action={
              <Tooltip title="Add Agent" placement="bottom-start">
                <IconButton onClick={() => handleClickOpen("agent")} sx={{ p: 0 }} >
                  <AddCircleIcon sx={{ fontSize: 35, color: "var(--icon-action)"}} />
                </IconButton>
              </Tooltip>
            }
          />
          {/* DIALOG BOX FOR NEW AGENT */}
          <Dialog open={openAgent} onClose={() => handleClose("agent")}>
            <DialogTitle>Add Agent</DialogTitle>
            <DialogContent>
              <DialogContentText></DialogContentText>

              <TextField autoFocus margin="dense"
                id="email"
                label="Agent Email Address"
                onChange={handleEmailChange}
                value={email} 
                fullWidth variant="standard"
              />

            
            {!generatedPass && (
            <>
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              variant="standard"
            />
            <FormControlLabel control={<Checkbox color="default" checked={generatedPass} onChange={handleSwitchGeneratedPass} />} label="Send generated password" />
            </>
          )}
          {generatedPass && (
            <FormControlLabel control={<Checkbox color="success" checked={generatedPass} onChange={handleSwitchGeneratedPass} />} label="Send generated password" />
          )}
            <br />
            <TextField
              margin="dense"
              id="firstName"
              label="First Name"
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
              sx={{width: "50%"}}
              variant="filled"
            />

            <TextField
              margin="dense"
              id="lastName"
              label="Last Name"
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
              sx={{width: "50%"}}
              variant="filled"
            />

            <br /><br />
            <TextField
              margin="dense"
              id="displayName"
              label="Display Name"
              type="text"
              helperText="Only letters, numbers and hyphens allowed"
              value={displayName}
              onChange={handleDisplayNameChange}
              fullWidth
              variant="outlined"
            />

            <FormControl sx={{ mt: 3, width: 300 }}>
              <InputLabel id="role">Role</InputLabel>
              <Select
                labelId="role"
                id="role"
                value={role}
                onChange={handleRoleChange}
                input={<OutlinedInput label="Role" />}
                >
                  <MenuItem key={"5"} value={"5"}>Tickets: View Only</MenuItem>
                  <MenuItem key={"4"} value={"4"}>Tickets: Edit</MenuItem>
                  <MenuItem key={"3"} value={"3"}>Admin: View Only</MenuItem>
                  <MenuItem key={"2"} value={"2"}>Admin: Edit</MenuItem>
                  <MenuItem key={"1"} value={"1"}>Full Admin</MenuItem>
                  
              </Select>
            </FormControl>
            <br /><br />

            <TextField
              margin="dense"
              id="phone"
              label="Phone"
              type="phone"
              value={phone}
              onChange={handlePhoneChange}
              fullWidth
              variant="filled"
            />

            <FormControl sx={{ mt: 3, width: 300 }}>
              <InputLabel id="timezone">Timezone</InputLabel>
              <Select
                labelId="timezone"
                id="timezone"
                value={timezone}
                onChange={handleTimeZoneChange}
                input={<OutlinedInput label="Timezone" />}
                >
                {timezoneArray.map((tz) => (
                  <MenuItem
                    key={tz}
                    value={tz}
                    >
                    {tz}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => reset("agent")}>Cancel</Button>
              <Button onClick={() => postAgent(email, password, generatedPass, firstName, lastName, displayName, role, phone, timezone, setAlertCustom, setAlertInfo, setOpenAskAThing, setAskAThingInfo, setOpenAgent, refreshAgents, reset)}>Save</Button>
            </DialogActions>
          </Dialog>

          {/* DIALOG BOX IF THAT EMAIL ALREADY EXISTS*/}
          <Dialog open={openAskAThing} onClose={() => handleClose("askathing")}>

              {askAThingInfo[0]==="customer-convert" && (
                <>
                <DialogTitle>Convert Customer to an Agent?</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        The email {email} belongs to a customer. <br /><br />
                        Would you like to convert this user to an Agent?                   
                    </Typography>
                </DialogContent>
                </>
              )}
              {askAThingInfo[0]==="customer-convert-enable" && (
                 <>
                 <DialogTitle>Convert Customer to an Agent and Enable?</DialogTitle>
                 <DialogContent>
                    <Typography variant="body1">
                        The email {email} belongs to a customer account that was archived on 
                        <Typography sx={{ color: 'red', display: 'inline'}} variant="subtitle">
                          {moment(askAThingInfo[1]).format('MMMM Do YYYY, h:mm:ss a')} UTC
                        </Typography> 
                        by {renderDisplayName(askAThingInfo[2])}<br /><br />
                        Would you like to convert this user to an Agent and enable login?
                    </Typography>
                  </DialogContent>
                  </>
              )}
              {askAThingInfo[0]==="agent-enable" && (
                  <>
                  <DialogTitle>Enable Archived Agent?</DialogTitle>
                  <DialogContent>
                    <Typography variant="body1">
                        The email {email} belongs to an agent, role of "{renderRole(askAThingInfo[3])}", 
                        archived on:{space}
                        <Typography sx={{ color: 'red', display: 'inline'}} variant="subtitle">
                          {moment(askAThingInfo[1]).format('MMMM Do YYYY, h:mm:ss a')} UTC{space}
                        </Typography>  
                        by {renderDisplayName(askAThingInfo[2])}<br /><br />
                        Would you like to reenable this user with the role of "{renderRole(role)}" you selected?
                    </Typography>  
                  </DialogContent>
                  </>
              )}
              <DialogActions>
                  <Button onClick={() => reset("agent")}>Cancel</Button>
                  <Button onClick={() => enableAgent(email, role, setAlertCustom, setAlertInfo, handleClose, handleUpdateFromComp)}>Continue</Button>
              </DialogActions>  
          </Dialog>

          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>
            {/* Agents Component */}
            <Agents agents={agents} agentId={agentId} handleUpdateFromComp={handleUpdateFromComp} />

          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>

      <GridItemWrapperChild key="arch" >
      <GridItemContentChild>        
        {/* Archived Agents **************************************************************************************************************************** */}
        <Card ref={refSizeArch} sx={{ mr: 0, width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
          <CardHeader className="DragByThisA" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SupportAgentIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ marginLeft: '8px' }}>Archived Agents</Typography>
              </div>
            }
          />
          
          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

          {/* AgentArchived Component */} 
          <ArchivedAgents agents={archivedAgents} agentId={agentId} handleUpdateFromComp={handleUpdateFromComp} />

          </CardContent>
        </Card>
        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>

      <GridItemWrapperChild key="act" >
      <GridItemContentChild>


        {/* Activity **************************************************************************************************************************** */}
        <Card ref={refSizeAct} sx={{ width: '100%', borderRadius: 'var(--box-border-radius)', boxShadow: 4, color: 'var(--headings-text)'}}>
        <CardHeader className="DragByThisA" sx={{ background: "var(--headings-fill)" }} titleTypographyProps={{ fontSize: 16 }}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SupportAgentIcon sx={{ fontSize: 35, color: "var(--headings-icon)"}} />
                <Typography sx={{ marginLeft: '8px' }}>Agent Activity</Typography>
              </div>
            }
        />

          <CardContent sx={{ background: "var(--box-bg)", color: "var(--box-text)" }}>

            {/* Activity Component */} 
            {/* <AgentsActivity activity={activity} handleUpdateFromComp={handleUpdateFromComp} /> */}
           

          </CardContent>
        </Card>

        </GridItemContentChild>
        <div className="dragarrowDark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrows-expand-vertical" viewBox="0 0 16 16">
              <path d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2ZM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
            </svg>
         </div>
      </GridItemWrapperChild>

      </ResponsiveGridLayoutChild>

    

  );
  
}