import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Cookies from "js-cookie";
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../../AlertCustom.js';
// ICONS
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// Crud Happiness
import { enableAgent } from '../../../services/put';
// Helpers
import moment from 'moment';
import { getDisplayName } from '../../../helpers/getDisplayName';



// Agent ROW LOOP ************************************************************************************************************************************************

// array of agents
const AgentRow = ({ agentrow, agentId, onSave, handleUpdateFromComp, isLastItem}) => {
  const [open, setOpen] = useState(false);
  // if agentrow.icon exists (not null or undefined), then use it, otherwise use an empty string
  const profilePic = (agentrow.icon ? agentrow.icon : "");

  const [avatarUrl, setAvatarUrl] = useState("");
  useEffect(() => {
    if (profilePic === "true") {
        const newAvatarUrl = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_FRONTEND + "/" + process.env.REACT_APP_AVATARS + "/avatars/avatar" + String(agentrow.id) + ".png?rando=1";
        setAvatarUrl(newAvatarUrl);
    }
}, [profilePic, agentrow]);
  
  
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  
  // ACTIONS ************************************************************************************************************************************************

  // Get agent email and id
  const agentEmail = Cookies.get("agent_email");

  // open and close state of dialog popup
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };

  // get display name of disabler
  const [disabler, setDisabler] = useState("");
     getDisplayName(null,agentrow.disabler)
    .then((dataid) => {
      setDisabler(dataid);
    })

  // get role name
  const renderRole = (role) => {
    switch(role) {
        case 5: return " Tickets: View Only";
        case 4: return " Tickets: Edit";
        case 3: return " Admin: View Only";
        case 2: return " Admin: Edit";
        case 1: return " Full Admin";
        default: return "";
    }
  };
  

  // SAVE FUNCTION: SENDS TO BASE SAVE FUNCTION **********************************************************************************************************************************

  const handleSave = () => {

    onSave(agentrow.email, agentrow.role, setAlertCustom, setAlertInfo, handleClose, handleUpdateFromComp);
    handleClose();

  };

  
  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>
      <ListItemButton onClick={handleClickOpen} sx={{width: '100%', mt:-1, mb:-2}}> 
        <ListItem >
          <ListItemAvatar>
            <Avatar 
              sx={{ bgcolor: "var(--icon-avatar)", width: 36, height: 36 }} 
              alt={agentrow.display_name} 
              src={avatarUrl}
            />
          </ListItemAvatar>
          <ListItemText sx={{width: '100%'}}
            primary={agentrow.email}
            secondary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                {agentrow.first_name} {agentrow.last_name} ({agentrow.display_name}) {agentrow.tz} {agentrow.phone}
              </Typography>
            }
          />
        </ListItem>
      </ListItemButton>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

      {/* DIALOG BOX FOR VIEWING AGENTS *********************************************** */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>View Agent</DialogTitle>
        <DialogContent>
        <ListItem >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "var(--icon-avatar)", width: 64, height: 64, marginRight: '16px'}} alt={agentrow.display_name} src={avatarUrl} />
          </ListItemAvatar>
          <ListItemText sx={{width: '100%'}}
            primary={agentrow.email}
            secondary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                {agentrow.first_name} {agentrow.last_name} ({agentrow.display_name}) <br /> 
                Timezone: {agentrow.tz}, Phone: {agentrow.phone}
              </Typography>
            }
          />
        </ListItem>
        <Box>
            <Typography variant="h6" gutterBottom>
                Archive Date: <br />
                <Typography sx={{ color: 'red', display: 'inline'}} variant="subtitle">
                    {moment(agentrow.date_disabled).format('MMMM Do YYYY, h:mm:ss a')} UTC
                </Typography>
            </Typography>

            <Typography variant="subtitle1">
                by: {disabler}
            </Typography>

            <Typography variant="body1">
                Role: {renderRole(agentrow.role)}<br />
            </Typography>
        </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Reactivate</Button>
        </DialogActions>
      </Dialog>

      {/* Divider */}
      {!isLastItem && <Divider variant="inset" />}
    </>
  );
};


// DEFAULT FUNCTION ****************************************************************************************************************************************

export default function Agents({ agents, agentId, handleUpdateFromComp }) {
  
  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };


  // BASE SAVE FUNCTION  **********************************************************************************************************************************

    const handleSave = (email, role, setAlertCustom, setAlertInfo, handleClose, handleUpdateFromComp) => {
      enableAgent(email, role, setAlertCustom, setAlertInfo, handleClose, handleUpdateFromComp);
  };


  // RETURN  **********************************************************************************************************************************
 
  return (
    <>
      <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
        {/* START Agent LOOP */}
        {agents &&
          agents.map((agentrow, index) => (
          <AgentRow
            key={agentrow.id} 
            agentrow={agentrow}
            agentId={agentId} 
            onSave={handleSave}
            handleUpdateFromComp={handleUpdateFromComp}
            isLastItem={index === agents.length - 1} // Check if it's the last item
          />
        ))}
        {!agents &&
          <Typography variant="body">
            No agents yet?
          </Typography>
        }
        {/* END Agent LOOP */}
      </List>

      {/* ALERT */}
      <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

    
    </>
  );
}