import React, { useState, useEffect, useRef, memo } from 'react';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// icons
import CloseIcon from '@mui/icons-material/Close';
//List stuff
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
//Icons
import ErrorIcon from '@mui/icons-material/Error';
//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// Alert Component
import AlertCustom from '../../AlertCustom.js';
// Crud Happiness
import { deleteAsked } from '../../../services/delete';
import { putIssues } from '../../../services/put';
// HELPERS
import { validateClean} from '../../../helpers/validate';
import {getCountTickets} from '../../../helpers/getCount';

// DEFAULT FUNCTION ****************************************************************************************************************************************

const GeneralIssues = ({ issues, onSaveSuccess, onSaveError }) => {

  // filter between active and archived issues
  var issuesActive = issues.filter((i) => Boolean(i.archive) == false);
  //var issuesArchived = issues.filter((i) => Boolean(i.archive) == true);

  const [openDialogId, setOpenDialogId] = useState(null); // null means no dialog open
  const [issue, setIssue] = useState("");
  const [issueId, setIssueId] = useState("");
  const [client_view, setClient_view] = useState(false);

  // Alerts
  const [alertCustom, setAlertCustom] = useState(false);
  const [alertInfo, setAlertInfo] = useState(["","info","",""]);
  const handleCloseAlert = () => { setAlertCustom(false); };

  // Delete issue constants
  const [openDelConfirm, setOpenDelConfirm] = useState(false);
  const handleCloseDelConfirm = () => { setOpenDelConfirm(false); };
  const [countIdForDel, setCountIdForDel] = useState("");
  const [goWhere, setGoWhere] = useState("");
  const [archive, setArchive] = useState("");

  
  // ACTIONS ************************************************************************************************************************************************

  // count renders
  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current = renderCount.current + 1;
  });

   // set states of particular issue when opened
   const handleClickOpen = (irow) => { 
    setIssueId(irow.id);
    setIssue(irow.name);
    setClient_view(Boolean(irow.client_view));
    setOpenDialogId(irow.id);
    setGoWhere("");
    setArchive("");
  };
  const handleClose = () => { setOpenDialogId(null); };

  const isLastItem = (index,length) => {
    if (index === length - 1) { 
      return true;
    } else {
      return false;
    }
  }
  

  // SAVE FUNCTION: **********************************************************************************************************************************

  const handleSave = () => {
    if (
      validateClean(issue)
    ) {
      // we mark archive as false because we only do that on delete
      putIssues(issueId, issue, client_view, false, onSaveSuccess, onSaveError)
      handleClose();
    } else {
      setAlertInfo(["invalidText","error","Error","Enter only letters or numbers"]);
      setAlertCustom(true);
    }
  };
  
  // DEL FUNCTION ********************************************************************************

  const handleDelete = (id) => {
    // ask if we should remove issue or archive
    getCountTickets(issueId,"issue")
    .then((ticketCount) => {
      setCountIdForDel(ticketCount);
    })
    .catch((error) => { console.error("Error fetching count:", error); });
    setOpenDialogId(null);
    setOpenDelConfirm(true);
  }

  const handleConfirmDelClick = () => {
    if (countIdForDel == 0) {
      deleteAsked(issueId, issue, "issue", "/api/v1/issues", "notickets", "id", onSaveSuccess, onSaveError);
      setOpenDelConfirm(false);
    } else if (countIdForDel > 0 && archive) {
        console.log("archive",archive);
        if (archive == "yes" ) {
          deleteAsked(issueId, issue, "issue", "/api/v1/issues", "archive", "id", onSaveSuccess, onSaveError);
        }
        if (archive == "purge") {
          deleteAsked(issueId, issue, "issue", "/api/v1/issues", "purge", "id", onSaveSuccess, onSaveError);
        }
        if (archive == "replace") {
          deleteAsked(issueId, issue, "issue", "/api/v1/issues", goWhere, "issue", onSaveSuccess, onSaveError);
        }
      setOpenDelConfirm(false);
    } else if (countIdForDel > 0 && !archive) {
      setOpenDelConfirm(false);
      setOpenDialogId(issueId);
      setAlertInfo(["invalidText","error","Error","Archive decision required"]);
      setAlertCustom(true);
    }
  }

  // RETURN IN LOOP  **********************************************************************************************************************************
 
  return (
    <>

    {/* START ISSUE LOOP */}
    {issuesActive.map((irow, index) => (
      <React.Fragment key={irow.id}>

      <ListItemButton onClick={() => handleClickOpen(irow)} onMouseDown={e => e.stopPropagation()} sx={{mt:0, mb:0}}>
        <ListItem key={irow.id}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "var(--icon-avatar)", width: 28, height: 28 }}>
              <ErrorIcon alt={issue} sx={{ width: 20, height: 20 }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={irow.name}
          />
        </ListItem>
      </ListItemButton>

      {/* DIALOG BOX - ISSUE EDIT */}
      <Dialog open={openDialogId === irow.id} onClose={handleClose} TransitionComponent={Zoom} sx={{ '& .MuiDialog-paper': { minWidth: 400 } }}>
        <DialogTitle>Edit Issue tag</DialogTitle>
        
        {/* ALERT */}
        <AlertCustom alertCustom={alertCustom} handleCloseAlert={handleCloseAlert} alertInfo={alertInfo} />

        <IconButton
          aria-label="close"
          onClick={()=>handleClose()}
          onMouseDown={e => e.stopPropagation()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          {/* ISSUE NAME */}
          <TextField
            autoFocus
            margin="dense"
            id="issue"
            label="Issue"
            onChange={(event) => {
              setIssue(event.target.value);
            }}
            helperText="Text: Numbers or letters only"
            value={issue}
            fullWidth
            variant="standard"
          />
          <br /><br />

          {/* ISSUE CLIENT_VIEW */}
          <FormControlLabel control={
              <Switch 
                checked={client_view} 
                color="warning" 
                onChange={(event, newValue) => {
                  setClient_view(newValue);
                }} 
              />} 
              label="Visible by client?" />

        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleClose()} onMouseDown={e => e.stopPropagation()}>Cancel</Button>
            <Button onClick={() => handleDelete(irow.id,false)} onMouseDown={e => e.stopPropagation()}>Delete</Button>
            <Button onClick={() => handleSave()} onMouseDown={e => e.stopPropagation()}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Divider */}
      {!isLastItem(index,issuesActive.length) && <Divider variant="inset" />}
      </React.Fragment>
    ))}
    {/* END ISSUE LOOP */}

    {/* DIALOG BOX FOR ISSUE DELETE */}
    <Dialog open={openDelConfirm} onClose={handleCloseDelConfirm} TransitionComponent={Zoom} sx={{ '& .MuiDialog-paper': { minWidth: 500 } }}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={()=>handleCloseDelConfirm()}
          onMouseDown={e => e.stopPropagation()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ ml: "50px", mr: "20px"}}>

          {countIdForDel > 0 && (
            <>
            <Typography variant="caption" sx={{ fontSize: 15, color: 'black'}}>
              There are currently {countIdForDel} ticket(s) marked with the {issue} issue. 
              You have the following choices:<br />
            </Typography>
            <Typography variant="caption" sx={{ fontSize: 14, color: 'var(--ticket-tab-fill)'}}>
              1. You may archive the issue. It will still display on past tickets, but it will no longer be an option for new tickets.<br />
              2. You may replace this issue with another issue for all tickets.<br />
              3. You may purge the issue entirely thus removing it from all marked tickets.
            </Typography>
          
            <br /><br />
            <FormGroup>
              <InputLabel id="archive-select-label">Removal method</InputLabel>
              <Select onMouseDown={e => e.stopPropagation()}
                labelId="archive-select-label"
                id="archive-select"
                value={archive}
                onChange={(event) => {
                  setArchive(event.target.value);
                }}
                >
                <MenuItem value="yes">Archive</MenuItem>
                <MenuItem value="replace">Replace</MenuItem>
                <MenuItem value="purge">Purge entirely</MenuItem>
              </Select >
              <br />
              {archive == "yes" && (
                <Typography variant="caption" sx={{ fontSize: 15, color: 'black'}}>
                  Please confirm that the {issue} issue should be archived.<br />
                </Typography>
              )}
              {archive == "replace" && (
                <>
                <Typography variant="caption" sx={{ fontSize: 15, color: 'black'}}>
                  Please choose the replacement issue for the {issue} issue<br />
                </Typography>
                <InputLabel id="issue-select-label">Issue</InputLabel>
                <Select onMouseDown={e => e.stopPropagation()}
                  labelId="issue-select-label"
                  id="issue-select"
                  value={goWhere}
                  onChange={(event) => {
                    setGoWhere(event.target.value);
                  }}
                  >
                  {issuesActive.map((issue) => (
                    issue.id !== issueId && (
                    <MenuItem key={issue.id} value={issue.id}>{issue.name}</MenuItem>
                    )
                  ))}
                </Select>
                <br />
                <Typography variant="caption" sx={{ fontSize: 15, color: 'red'}}>
                  WARNING: THIS CAN NOT BE UNDONE!
                </Typography>
                </>
              )}
              {archive == "purge" && (
                <>
                <Typography variant="caption" sx={{ fontSize: 15, color: 'black'}}>
                  This will completely purge the {issue} issue from RikrDesk. Please confirm that you would like to continue.<br/>
                </Typography>
                <Typography variant="caption" sx={{ fontSize: 15, color: 'red'}}>
                  WARNING: THIS CAN NOT BE UNDONE!
                </Typography>
                </>
              )}
            </FormGroup>
            </>
          )}
          {countIdForDel == 0 && (
            <>
            <Typography variant="caption" sx={{ fontSize: 15, color: 'black'}}>
              There are currently 0 tickets marked with the {issue} issue. 
              Please confirm you would like to delete this issue. 
            </Typography>
            <br /><br />
            <Typography variant="caption" sx={{ fontSize: 15, color: 'red'}}>
                WARNING: THIS CAN NOT BE UNDONE!
            </Typography>      
            <br /><br />
            </>
          )} 
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDelConfirm()} onMouseDown={e => e.stopPropagation()}>Cancel</Button>
          <Button onClick={() => handleConfirmDelClick()} onMouseDown={e => e.stopPropagation()}>Confirm</Button>
        </DialogActions>
    </Dialog>
    {/* RENDER COUNT TEST */}
    {/* {renderCount.current} */}
    </>
  );
}

export default memo(GeneralIssues);